import { Col, Row, Spin } from 'tera-dls';
import BankCard from './containers/BankCard';
import PaymentContent from './containers/PaymentContent';
import Transaction from './containers/Transaction';

const Payment = () => {
  return (
    <Spin spinning={false}>
      <div className="tera-page-form !gap-0">
        <div className="page-header-sticky bg-[#F3F3F9]">
          <div className="page-header-v2">
            <div className="text-gray-700 font-semibold text-base uppercase">
              Nạp tiền
            </div>
          </div>
        </div>
        <div className=" page-content-v2 !px-0 h-full mx-2.5 pb-5 !gap-y-0">
          <Row className="grid grid-cols-7">
            <Col className="col-span-5 flex flex-col gap-[16px]">
              <PaymentContent />
              <Transaction />
            </Col>
            <Col className="col-span-2">
              <BankCard />
            </Col>
          </Row>
        </div>
      </div>
    </Spin>
  );
};

export default Payment;
