import { observer } from 'mobx-react-lite';
import { LOGIN_URL } from 'pages/Auth/Login/url';
import { REGISTER_URL } from 'pages/Auth/Register/url';
import { useNavigate } from 'react-router-dom';
import { Button, UserCircleSolid } from 'tera-dls';

const User = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div className="flex items-center gap-1">
      <UserCircleSolid className="w-6 h-6" />
      <Button
        className="bg-transparent hover:bg-transparent p-0 cursor-pointer"
        onClick={() => navigate(LOGIN_URL.index.path)}
      >
        <span className="text-black font-bold">Đăng nhập</span>
      </Button>
      <span>/</span>
      <Button
        className="bg-transparent hover:bg-transparent p-0 cursor-pointer"
        onClick={() => navigate(REGISTER_URL.index.path)}
      >
        <span className="text-black font-bold">Đăng ký</span>
      </Button>
    </div>
  );
};

export default observer(User);
