import { IRouteProps } from '_common/interface/router';
import { PAYMENT_CART_URL } from './url';
import Payment from '.';

export const PaymentCartRouter: IRouteProps[] = [
  {
    key: PAYMENT_CART_URL.cart.key,
    path: PAYMENT_CART_URL.cart.shortenUrl,
    component: <Payment />,
  },
];
