export const UNAUTH_DOMAIN_URL = {
  index: {
    key: '',
    path: '/welcome/service/domain',
    shortenUrl: 'domain',
  },
  add: {
    key: '',
    path: '/welcome/service/domain/add',
    shortenUrl: 'domain/add',
  },
  paymentInformation: {
    key: '',
    path: '/welcome/service/domain/payment-information',
    shortenUrl: 'domain/payment-information',
  },
  payment: {
    key: '',
    path: '/welcome/service/domain/payment',
    shortenUrl: 'domain/payment',
  },
};
