import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { PlusCircleOutlined } from 'tera-dls';
import ModalCreate from '../ModalCreate';
import Slider from './Slider';
// import { ReactComponent as EmptyImage } from 'styles/images/wallet/empty-bank-card.svg';
import EmptyImage from 'styles/images/wallet/empty_card.png';
import Empty from '../Empty';
import CardList from '../CardList';
import PaymentMethodApi from 'pages/InvoiceManagement/Withdrawal/api/payment';
// import { PERMISSION_KEY } from 'pages/Wallet/constants/permission';
// import NoPermission from '_common/component/NoPermission';
// import { usePermission } from '_common/hooks/usePermission';

function BankCard() {
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isOpenList, setIsOpenList] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const listCardBank = useQuery(
    ['get-card-bank-list'],
    () => {
      const params = {
        get_at_portal: 1,
        object_type: 1,
        limit: 10,
      };
      return PaymentMethodApi.getCardBankList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );
  listCardBank;
  return (
    <div>
      <div className="flex justify-between items-center">
        <h3 className="uppercase text-sm text-gray-700">Thẻ ngân hàng</h3>
        <div className="flex items-center gap-x-1">
          <PlusCircleOutlined
            className="w-5 h-5 text-green-500 cursor-pointer"
            onClick={() => setIsCreate(true)}
          />
          <span
            className="text-blue-400 hover:underline cursor-pointer"
            onClick={() => setIsOpenList(true)}
          >
            Xem tất cả
          </span>
        </div>
      </div>

      {true ? (
        <Slider
          data={[
            {
              id: 157,
              card_type_id: 1,
              available_balance: 0,
              transaction_count: 0,
              total_deposit: 0,
              total_withdrawal: 0,
              cardholder: '1',
              account_number: '2',
              created_at: '2024-08-26T01:34:40.000000Z',
              updated_at: '2024-08-26T01:34:40.000000Z',
              deleted_at: null,
              account_phone: null,
              created_by: 10244,
              image_url_bank: null,
              e_wallet_image_url: null,
              is_card_system: 1,
              description: null,
              card_type: {
                id: 1,
                card_name: 'BIDV',
                description: 'Đầu tư và Phát triển Việt Nam',
                status: 1,
                created_at: '2024-03-20T10:06:55.000000Z',
                updated_at: '2024-03-29T02:52:35.000000Z',
                deleted_at: null,
                object_type: 1,
                created_by: null,
                image_bank:
                  'https://portal-api.teravn.com/assets/upload/import/1711446727_image-46.png',
                acqId: 970418,
              },
            },
            {
              id: 156,
              card_type_id: 1,
              available_balance: 0,
              transaction_count: 0,
              total_deposit: 0,
              total_withdrawal: 0,
              cardholder: '1',
              account_number: '2',
              created_at: '2024-08-21T06:25:05.000000Z',
              updated_at: '2024-08-21T06:25:05.000000Z',
              deleted_at: null,
              account_phone: null,
              created_by: 10244,
              image_url_bank: null,
              e_wallet_image_url: null,
              is_card_system: 1,
              description: null,
              card_type: {
                id: 1,
                card_name: 'BIDV',
                description: 'Đầu tư và Phát triển Việt Nam',
                status: 1,
                created_at: '2024-03-20T10:06:55.000000Z',
                updated_at: '2024-03-29T02:52:35.000000Z',
                deleted_at: null,
                object_type: 1,
                created_by: null,
                image_bank:
                  'https://portal-api.teravn.com/assets/upload/import/1711446727_image-46.png',
                acqId: 970418,
              },
            },
          ]}
        />
      ) : (
        <div className="py-5">
          <Empty
            image={<img src={EmptyImage} />}
            sub={
              <>
                Nhấn{' '}
                <span>
                  <PlusCircleOutlined
                    className="w-5 h-5 text-green-400 cursor-pointer"
                    onClick={() => setIsCreate(true)}
                  />
                </span>{' '}
                để thêm thẻ
              </>
            }
          />
        </div>
      )}

      {isOpenList && (
        <CardList open={isOpenList} onCancel={() => setIsOpenList(false)} />
      )}

      {isCreate && (
        <ModalCreate
          id={null}
          onClose={() => setIsCreate(false)}
          open={isCreate}
          onRefetch={() =>
            queryClient.invalidateQueries(['get-card-bank-list'])
          }
        />
      )}
    </div>
  );
}

export default BankCard;
