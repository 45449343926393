import { observer } from 'mobx-react-lite';
import { PAYMENT_CART_URL } from 'pages/Payment/url';
import { useNavigate } from 'react-router-dom';
import { CreditCardSolid, formatCurrency } from 'tera-dls';

const UserBalance = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <a className="flex items-center gap-1 cursor-pointer">
      <CreditCardSolid
        className="w-6 h-6"
        color="#3F83F8"
        onClick={() => navigate(PAYMENT_CART_URL.cart.path)}
      />
      <span>Số dư:</span>
      <span className="font-bold">{formatCurrency(60000)}</span>
    </a>
  );
};

export default observer(UserBalance);
