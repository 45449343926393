import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import ModalSummary from 'pages/ServiceManagement/Domain/modals/ModalSummary';
import { useState } from 'react';
import { formatCurrency } from 'tera-dls';

interface IFormSummaryProps {
  rightButton?: React.ReactNode;
}

const FormSummary = ({ rightButton }: IFormSummaryProps): JSX.Element => {
  const [isOpenModalSummary, setIsOpenModalSummary] = useState<boolean>(false);

  return (
    <div className="py-2 mt-auto drop-shadow sticky -left-0 -bottom-0 -right-0">
      <div className="bg-white p-4 rounded-[4px] flex justify-between items-center">
        <span>2 Tên miền</span>
        <div className="flex items-center gap-3">
          <IconText
            prefix={<ZingIcon.IconInformation />}
            className="text-blue-500 cursor-pointer"
            onClick={() => setIsOpenModalSummary(true)}
          >
            Chi tiết
          </IconText>
          <span className="text-[20px] text-[#0E9F6E]">
            {formatCurrency(30000)}
          </span>
          {rightButton}
        </div>
      </div>
      {isOpenModalSummary && (
        <ModalSummary
          open={isOpenModalSummary}
          close={() => setIsOpenModalSummary(false)}
        />
      )}
    </div>
  );
};

export default FormSummary;
