import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import { useState } from 'react';
import {
  Button,
  CreditCardOutlined,
  DropdownItem,
  formatCurrency,
  FunnelOutlined,
  Tag,
} from 'tera-dls';
import InvoiceDetailFilter from './Filter';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { useNavigate } from 'react-router-dom';
import Searching from './Searching';
import { INVOICE_URL } from '../../url';

const InvoiceDetail = () => {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [params, setParams] = useState<any>();
  const navigate = useNavigate();

  const generateDropDownItems = (record: any): DropdownItem[] => {
    record;
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => {
          navigate(`${INVOICE_URL.detail.path}/1`);
        },
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Số hóa đơn',
      dataIndex: 'invoice_no',
      width: 200,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 200,
      render: (val) =>
        val === 'success' ? (
          <Tag color="green03">Thành công</Tag>
        ) : (
          <Tag color="red03">Chưa thanh toán</Tag>
        ),
    },
    {
      title: 'Tổng',
      dataIndex: 'total',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_date',
      width: 150,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expired_date',
      width: 150,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: 'Ngày thanh toán',
      dataIndex: 'recharge_date',
      width: 150,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  const handleSearch = ({ keyword }) => {
    setParams((prev) => ({ ...prev, keyword, page: 1 }));
  };

  return (
    <>
      <div className="flex justify-end flex-col [box-shadow:0px_1px_5px_0px_#0000001A,0px_-1px_5px_0px_#0000001A] rounded-md py-2 gap-2">
        <div className="flex flex-col px-2 justify-end items-end gap-2">
          <span>
            <Button className="bg-blue-500 font-light">
              <CreditCardOutlined className="size-4" />
              Thanh toán tất cả
            </Button>
          </span>
          <div className="flex gap-2.5">
            <Searching onSearch={handleSearch} />
            <Button
              type="alternative"
              className="rounded-xsm py-1 px-2  bg-blue-50"
              onClick={() => setOpenFilter(true)}
            >
              <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0 size-4" />
            </Button>
          </div>
        </div>
        <TableTera
          columns={columns}
          className="center-table"
          wrapperClassName="shadow-none"
          pagination={{}}
          data={[
            {
              id: 1,
              invoice_no: 'CTZS6892 7LVFCA8SB',
              status: 'success',
              total: 350000,
            },
            {
              id: 2,
              invoice_no: 'CTZS6892 7LVFCA8SB',
              status: 'failed',
              total: 350000,
            },
          ]}
        />
      </div>
      {openFilter && (
        <InvoiceDetailFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValues={{ ...params }}
          onFilter={(val) =>
            setParams((prev = {}) => ({ ...prev, ...val, page: 1 }))
          }
        />
      )}
    </>
  );
};

export default InvoiceDetail;
