const DoubleCheckedIcon = (props) => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 6.86875L4.143 10.4688L12 1.46875M17 1.53175L8.428 10.5317L8 9.96875"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default DoubleCheckedIcon;
