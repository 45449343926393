import { messageWarning } from '_common/constants/message';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useForm } from 'react-hook-form';
import { Modal, Spin } from 'tera-dls';
import { deleteOptions, locationOptions, osOptions } from '../constants';

interface IModalChangeIPProps {
  open: boolean;
  close: () => void;
}

const ModalChangeIP = ({ open, close }: IModalChangeIPProps): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { location: 1, delete_option: 1 },
  });
  const confirm = useConfirm();

  const handleCloseConfirm = () => {
    confirm.warning({
      title: 'Thoát bản ghi',
      content: (
        <>
          <p>{messageWarning.WARNING_EXIT_1}</p>
          <p>{messageWarning.WARNING_EXIT_2}</p>
        </>
      ),
      onOk: () => close(),
    });
  };

  const details = [
    {
      label: 'Địa chỉ IP',
      value: '103.250.78.65',
    },
    {
      label: 'Ngày tạo',
      value: 'dd/mm/yyyy',
    },
    {
      label: 'Ngày hết hạn',
      value: <span className="text-red-500">dd/mm/yyyy</span>,
    },
    {
      label: 'Vị trí',
      value: 'Việt Nam / FPT - Hồ Chí Minh',
    },
    {
      label: 'Hệ điều hành',
      value: 'Ubuntu 18.04',
    },
    {
      label: 'Ghi chú',
      value: 'Note',
    },
  ];

  return (
    <Modal
      title={
        <div>
          Đổi IP dịch vụ <span className="text-green-500">ZS6892_011391</span>
        </div>
      }
      open={open}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={close}
      onCancel={handleCloseConfirm}
      centered={true}
      contentClassName="md:w-[500px] h-full"
      bodyClassName="!pb-0"
    >
      <Spin spinning={false}>
        <div className="py-2 flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            {details.map((detail, index) => (
              <div key={index} className="flex">
                <span className="w-[50%] text-[#6B7280]">{detail.label}</span>
                <span className="w-[50%]">{detail.value}</span>
              </div>
            ))}
          </div>
          <FormTera form={form}>
            <FormTeraItem label="Vị trí" name="location" className="mb-0">
              <Select options={locationOptions} />
            </FormTeraItem>
            <FormTeraItem
              label="Cài lại hệ điều hành"
              name="os"
              className="mb-0"
            >
              <Select options={osOptions} />
            </FormTeraItem>
            <FormTeraItem
              label="Xóa dữ liệu"
              name="delete_option"
              className="mb-0"
            >
              <Select options={deleteOptions} />
            </FormTeraItem>
          </FormTera>
        </div>
      </Spin>
    </Modal>
  );
};

export default ModalChangeIP;
