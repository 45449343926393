import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { useStores } from '_common/hooks';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { Spin } from 'tera-dls';
import PaymentMethodApi from '../../api/payment';
import Item from './Item';

const typeRecharge = [
  {
    title: 'Chuyển khoản',
    value: 1,
  },
  // {
  //   title: 'Momo',
  //   value: 2,
  // },
];

function SelectPaymentMethod(props, ref) {
  const {
    transactionStore: {
      account,
      method,
      type,
      setAccount,
      setMethod,
      nextStep,
    },
  } = useStores();
  const limit = 15;
  const latestRef = useRef(null);
  const queryClient = useQueryClient();

  const listPaymentMethod = useInfiniteQuery({
    queryKey: ['get-payment-method-list'],
    staleTime: 30000,
    cacheTime: 30000,
    enabled: !!method,
    queryFn: ({ pageParam }) => {
      const params = {
        object_type: method,
        get_at_portal: type === 'withdrawal' ? 1 : undefined,
        limit,
        page: pageParam || 1,
      };
      return PaymentMethodApi.getCardBankList({ params });
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.current_page < lastPage?.last_page
        ? allPages?.length + 1
        : undefined;
    },
  });

  const value = useMemo(() => {
    return listPaymentMethod.data?.pages?.reduce((acc, page) => {
      return _.unionBy(acc, page?.data ?? [], 'id');
    }, []);
  }, [listPaymentMethod.data]);
  const value1 = [
    {
      id: 156,
      card_type_id: 1,
      available_balance: 0,
      transaction_count: 0,
      total_deposit: 0,
      total_withdrawal: 0,
      cardholder: '1',
      account_number: '2',
      created_at: '2024-08-21T06:25:05.000000Z',
      updated_at: '2024-08-21T06:25:05.000000Z',
      deleted_at: null,
      account_phone: null,
      created_by: 10244,
      image_url_bank: null,
      e_wallet_image_url: null,
      is_card_system: 1,
      description: null,
      card_type: {
        id: 1,
        card_name: 'BIDV',
        description: 'Đầu tư và Phát triển Việt Nam',
        status: 1,
        created_at: '2024-03-20T10:06:55.000000Z',
        updated_at: '2024-03-29T02:52:35.000000Z',
        deleted_at: null,
        object_type: 1,
        created_by: null,
        image_bank:
          'https://portal-api.teravn.com/assets/upload/import/1711446727_image-46.png',
        acqId: 970418,
      },
    },
  ];
  const handleObserver = (entries: any) => {
    if (
      entries[0].isIntersecting &&
      listPaymentMethod.hasNextPage &&
      !listPaymentMethod.isFetching &&
      !listPaymentMethod.isFetchingNextPage
    ) {
      listPaymentMethod.fetchNextPage();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.5,
    });

    if (observer && latestRef.current) {
      observer.observe(latestRef.current);
    }

    return () => {
      if (latestRef.current) {
        observer.disconnect();
      }
    };
  }, [value, handleObserver]);

  useImperativeHandle(ref, () => {
    return { nextStep: () => nextStep() };
  });

  useEffect(() => {
    if (method && type) {
      queryClient.setQueryData(['get-payment-method-list'], (oldData: any) => {
        return {
          pages: [oldData?.pages[0]],
          pageParams: [1],
        };
      });
      listPaymentMethod?.refetch();
    }
  }, [method, type]);

  useEffect(() => {
    setMethod(1);
  }, []);

  return (
    <div className="flex flex-col gap-y-2.5">
      {typeRecharge.map((type) => {
        return (
          <div>
            <Spin spinning={listPaymentMethod.isLoading && !!method}>
              {method == type.value && (
                <div className="grid grid-cols-3 gap-2.5 overflow-y-auto p-2 max-h-[330px]">
                  {value1?.length > 0 ? (
                    value1?.map((item, index) => (
                      <Item
                        ref={
                          index === value1?.length - 1 ? latestRef : undefined
                        }
                        type={method}
                        number={item?.account_phone || item?.account_number}
                        image={item?.card_type?.image_bank}
                        active={account?.id === item?.id}
                        bankName={item?.card_type?.card_name}
                        fullName={item?.cardholder}
                        onClick={() =>
                          setAccount({
                            cardholder: item?.cardholder,
                            id: item?.id,
                            number: item?.account_phone || item?.account_number,
                            cardName: item?.card_type?.card_name,
                            object_type: item?.card_type?.object_type,
                            image_bank: item?.card_type?.image_bank,
                          })
                        }
                      />
                    ))
                  ) : (
                    <div className="col-span-3">
                      <NoData />
                    </div>
                  )}
                </div>
              )}
            </Spin>
          </div>
        );
      })}
    </div>
  );
}

export default observer(forwardRef(SelectPaymentMethod));
