import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import { FormTeraItem } from '_common/dof/FormTera';
import { Fragment } from 'react/jsx-runtime';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, formatCurrency } from 'tera-dls';
import { businessServicePackages } from '../../../constants';
import FormDomainRegistration from './FormDomainRegistration';

const FormAddBusiness = ({ form }): JSX.Element => {
  const details = [
    { label: 'Số lượng email', value: '1GB NVMe' },
    { label: 'Dung lượng', value: '2 GB' },
  ];

  const formSections = [
    {
      name: 'Chọn gói dịch vụ',
      content: (
        <Swiper
          spaceBetween={25}
          slidesPerView={3}
          className="!w-full !h-auto !p-4 !pt-0"
        >
          {businessServicePackages.map((service) => (
            <SwiperSlide
              key={service.id}
              className={`min-w-[400px] max-w-[400px] !h-auto bg-white p-6 py-8 rounded-[8px] hover:drop-shadow-xl flex flex-col cursor-pointer ${
                form.watch('service_package') === service.id &&
                'text-blue-800 !bg-[#EBF5FF] drop-shadow-xl'
              }`}
              onClick={() => form.setValue('service_package', service.id)}
            >
              <span className="text-[30px] font-bold leading-9">
                {service.name}
              </span>
              <div className="pb-8 mt-[4rem] border-b-[1px] border-[#606999] border-dashed flex gap-2">
                <span className="text-[25px] font-bold">
                  {formatCurrency(313000)}
                </span>
                / 01 năm
              </div>
              <div className="mt-[2rem] flex flex-col items-center gap-4">
                {details.map((detail, index) => (
                  <div key={index} className="w-full flex gap-3">
                    <span className="flex-1 text-gray-500 text-right">
                      {detail.label}
                    </span>
                    <span className="flex-1 font-[500]">{detail.value}</span>
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ),
    },
    {
      name: 'Email Accounts',
      content: (
        <FormTeraItem
          label="Số tài khoản email"
          name="email_accounts"
          className="p-4 bg-white rounded-[6px] drop-shadow mb-0"
          rules={[
            {
              required: messageValidate.emptyText,
            },
          ]}
        >
          <InputNumber />
        </FormTeraItem>
      ),
    },
    {
      name: 'Tên miền',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
          <FormDomainRegistration />
        </div>
      ),
    },
    {
      name: 'Sử dụng mã khuyến mãi',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
          <span className="text-[16px] font-[600]">Mã khuyến mãi</span>
          <div className="flex items-center gap-2">
            <FormTeraItem name="promo_code" className="flex-1 mb-0">
              <Input placeholder="Vui lòng nhập" />
            </FormTeraItem>
            <Button htmlType="button" className="bg-blue-500">
              Áp dụng
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="p-2 flex flex-col gap-10">
      {formSections.map((section, index) => (
        <Fragment key={index}>
          <div className="flex items-center gap-2">
            <span className="w-[30px] h-[30px] text-blue-500 font-[500] border-[1px] border-blue-500 rounded-[30px] flex justify-center items-center">
              {index + 1}
            </span>
            <span className="text-[20px] font-[500]">{section.name}</span>
          </div>
          {section.content}
        </Fragment>
      ))}
    </div>
  );
};

export default FormAddBusiness;
