import CardDetail from '_common/component/CardDetail';
import { Row } from 'tera-dls';
import Item from './Item';

const Transaction = () => {
  return (
    <CardDetail title="Giao dịch nạp tiền gần đây">
      <Row className="grid-cols-2 xl:grid-cols-4 gap-[30px]">
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
      </Row>
    </CardDetail>
  );
};

export default Transaction;
