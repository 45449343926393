import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import { Fragment } from 'react/jsx-runtime';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Button, formatCurrency } from 'tera-dls';
import {
  comodoSSLOptions,
  comodoSSLWildcardOptions,
  dedicatedIPOptions,
  servicePackages,
} from '../constants';
import FormDomainRegistration from './FormDomainRegistration';

const FormAdd = ({ form }): JSX.Element => {
  const details = [
    { label: 'Dung lượng', value: '1GB NVMe' },
    { label: 'Website', value: '1' },
    { label: 'Hiệu suất', value: 'Tiêu chuẩn' },
    { label: 'Băng thông', value: 'Không giới hạn' },
    { label: 'SSL', value: 'Miễn phí' },
    { label: 'Email', value: '1' },
    { label: 'Tên miền miễn phí', value: '0' },
    { label: 'MySQL', value: 'Không giới hạn' },
    { label: 'Managed WordPress', value: 'Có' },
    { label: 'Free 1-click', value: 'WordPress Installation' },
    { label: 'WordPress Acceleration', value: 'LiteSpeed' },
    { label: 'WordPress', value: 'Autoupdates' },
    { label: 'WP-CLI and SSH', value: 'Có' },
    { label: 'WordPress', value: 'Staging Tool' },
    { label: 'Object Cache', value: 'For WordPress' },
    { label: 'Malware', value: 'Scanner' },
    { label: 'Uptime Guarantee', value: '99,9%' },
    { label: 'Customer Support', value: '24/7' },
    { label: 'PHP X-Ray', value: 'Có' },
    { label: 'AccelerateWP', value: 'Có' },
    { label: 'CPU', value: 'Full 1 Core' },
    { label: 'Ram', value: '1GB' },
    { label: 'Max FIles (Inodes)', value: '150,000' },
    { label: 'Max File Size', value: '10M' },
  ];

  const formSections = [
    {
      name: 'Chọn gói dịch vụ',
      content: (
        <Swiper
          spaceBetween={25}
          slidesPerView={3}
          className="!w-full !h-auto !p-4 !pt-0"
        >
          {servicePackages.map((service) => (
            <SwiperSlide
              key={service.id}
              className={`min-w-[400px] max-w-[400px] !h-auto bg-white p-6 py-8 rounded-[8px] hover:drop-shadow-xl flex flex-col cursor-pointer ${
                form.watch('service_package') === service.id &&
                'text-blue-800 !bg-[#EBF5FF] drop-shadow-xl'
              }`}
              onClick={() => form.setValue('service_package', service.id)}
            >
              <span className="text-[30px] font-bold leading-9">
                {service.name}
              </span>
              <div className="pb-8 mt-[4rem] border-b-[1px] border-[#606999] border-dashed flex gap-2">
                <span className="text-[25px] font-bold">
                  {formatCurrency(313000)}
                </span>
                / 01 năm
              </div>
              <div className="mt-[2rem] flex flex-col items-center gap-4">
                {details.map((detail, index) => (
                  <div key={index} className="w-full flex gap-3">
                    <span className="flex-1 text-gray-500 text-right">
                      {detail.label}
                    </span>
                    <span className="flex-1 font-[500]">{detail.value}</span>
                  </div>
                ))}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ),
    },
    {
      name: 'Dịch vụ bổ sung',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-2">
          <FormTeraItem name="additional_service" className="mb-0">
            <CheckBox>Hosting Dedicated IP</CheckBox>
          </FormTeraItem>
          {form.watch('additional_service') && (
            <FormTeraItem name="dedicated_ip_option" className="w-[300px] mb-0">
              <Select options={dedicatedIPOptions} />
            </FormTeraItem>
          )}
        </div>
      ),
    },
    {
      name: 'Sub-product',
      content: (
        <Fragment>
          <div className="p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-2">
            <FormTeraItem name="sub_product_comodo" className="mb-0">
              <CheckBox>Comodo SSL - Comodo PositiveSSL</CheckBox>
            </FormTeraItem>
            {form.watch('sub_product_comodo') && (
              <FormTeraItem
                name="sub_product_comodo_option"
                className="w-[300px] mb-0"
              >
                <Select options={comodoSSLOptions} />
              </FormTeraItem>
            )}
          </div>
          <div className="p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-2">
            <FormTeraItem name="sub_product_comodo_wildcard" className="mb-0">
              <CheckBox>Comodo SSL - Comodo PositiveSSL Wildcard</CheckBox>
            </FormTeraItem>
            {form.watch('sub_product_comodo_wildcard') && (
              <FormTeraItem
                name="sub_product_comodo_wildcard_option"
                className="w-[300px] mb-0"
              >
                <Select options={comodoSSLWildcardOptions} />
              </FormTeraItem>
            )}
          </div>
        </Fragment>
      ),
    },
    {
      name: 'Tên miền',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
          <FormDomainRegistration />
        </div>
      ),
    },
    {
      name: 'Sử dụng mã khuyến mãi',
      content: (
        <div className="p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
          <span className="text-[16px] font-[600]">Mã khuyến mãi</span>
          <div className="flex items-center gap-2">
            <FormTeraItem name="promo_code" className="flex-1 mb-0">
              <Input placeholder="Vui lòng nhập" />
            </FormTeraItem>
            <Button htmlType="button" className="bg-blue-500">
              Áp dụng
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="p-2 flex flex-col gap-10">
      {formSections.map((section, index) => (
        <Fragment key={index}>
          <div className="flex items-center gap-2">
            <span className="w-[30px] h-[30px] text-blue-500 font-[500] border-[1px] border-blue-500 rounded-[30px] flex justify-center items-center">
              {index + 1}
            </span>
            <span className="text-[20px] font-[500]">{section.name}</span>
          </div>
          {section.content}
        </Fragment>
      ))}
    </div>
  );
};

export default FormAdd;
