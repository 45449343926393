import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import FlagVN from 'styles/images/Icons/FlagVN.svg';
import { Button, ChevronDoubleRightSolid, Tag, formatCurrency } from 'tera-dls';
import { PresetColors } from 'tera-dls/lib/components/Tag/style';
import { VPS_STATUS, VPS_STATUS_COLOR } from '../../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { CLOUD_VPS_URL } from '../../../url';

const CurrentVPS = (): JSX.Element => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <div className="flex-1 bg-white p-4 rounded-[10px] drop-shadow flex flex-col">
      <div className="flex flex-col gap-4">
        <span className="text-blue-500 font-bold">
          Thông tin VPS đang sử dụng
        </span>
        <div className="px-3 flex flex-col gap-5">
          <IconText prefix={<ZingIcon.IconInformation color="#0e9f6e" />}>
            <span className="font-[500]">Thông tin chung:</span>
          </IconText>
          <div className="pb-4 border-b-[1px] flex">
            <IconText prefix={<ZingIcon.IconLocation />} className="w-[50%]">
              Vị trí:
              <span className="font-[500]">103.250.78.65</span>
            </IconText>
            <IconText prefix={<ZingIcon.IconSquareBox />} className="w-[50%]">
              Tên:
              <span className="font-[500]">103.250.78.65</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconEarth />}
              suffix={
                <img
                  src={FlagVN}
                  alt="name_flag"
                  className="w-4 h-4 rounded-[1rem] object-cover"
                />
              }
              className="w-[50%]"
            >
              Khu vực:
              <span className="font-[500]">Việt Nam</span>
            </IconText>
            <IconText prefix={<ZingIcon.IconWindows />} className="w-[50%]">
              Hệ điều hành:
              <span className="font-[500]">Windows Server 2012</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconCalendarCheck />}
              className="w-[50%]"
            >
              Ngày tạo:
              <span className="font-[500]">dd/mm/yyyy</span>
            </IconText>
            <IconText
              prefix={<ZingIcon.IconCalendarEmpty />}
              className="w-[50%]"
            >
              Ngày hết hạn:
              <span className="font-[500]">dd/mm/yyyy</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText prefix={<ZingIcon.IconCompass />} className="w-[50%]">
              Trạng thái:
              <span className="font-[500]">
                <Tag
                  color={VPS_STATUS_COLOR[1] as PresetColors}
                  className="font-[500]"
                >
                  {VPS_STATUS[1]}
                </Tag>
              </span>
            </IconText>
            <IconText
              prefix={<ZingIcon.IconArrowCache color="#3f83f8" />}
              className="w-[50%]"
            >
              Số lần đổi IP:
              <span className="text-green-500 font-[500]">0/15</span>
            </IconText>
          </div>
        </div>
        <div className="px-3 flex flex-col gap-5">
          <IconText prefix={<ZingIcon.IconVPS color="#0E9F6E" />}>
            <span className="font-[500]">Cấu hình:</span>
          </IconText>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconRam color="#3f83f8" />}
              className="w-[50%]"
            >
              RAM:
              <span className="font-[500]">16</span>
              GB
            </IconText>
            <IconText
              prefix={<ZingIcon.IconCPU color="#3f83f8" />}
              className="w-[50%]"
            >
              vCPU:
              <span className="font-[500]">8</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconCPUStorage color="#3f83f8" />}
              className="w-[50%]"
            >
              SSD:
              <span className="font-[500]">90</span>
              GB
            </IconText>
            <IconText
              prefix={<ZingIcon.IconNetwork color="#3f83f8" />}
              className="w-[50%]"
            >
              Mbps:
              <span className="font-[500]">800</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconWifiVertical color="#3f83f8" />}
              className="w-[50%]"
            >
              Wifi:
              <span className="font-[500]">Unlimited</span>
            </IconText>
            <IconText
              prefix={<ZingIcon.IconDollarCard color="#3f83f8" />}
              className="w-[50%]"
            >
              Đơn giá:
              <span className="text-[#e02424] font-[500]">
                {formatCurrency(1075000)}
              </span>
              / tháng
            </IconText>
          </div>
          <Button
            className="w-[191px] bg-purple-500"
            prefix={<ChevronDoubleRightSolid />}
            onClick={() => navigate(`${CLOUD_VPS_URL.detail.path}/${id}`)}
          >
            Thông tin chi tiết
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CurrentVPS;
