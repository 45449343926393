import Input from '_common/dof/Control/Input';
import { FormTeraItem } from '_common/dof/FormTera';
import { Button } from 'tera-dls';

const PromoCode = (): JSX.Element => {
  return (
    <div className="bg-[#FAFAF9] p-4 rounded-[6px] drop-shadow flex flex-col gap-8">
      <span className="text-[16px] font-[600]">Mã khuyến mãi</span>
      <div className="flex items-center gap-2">
        <FormTeraItem name="promo_code" className="flex-1 mb-0">
          <Input placeholder="Vui lòng nhập" />
        </FormTeraItem>
        <Button htmlType="button" className="bg-blue-500">
          Áp dụng
        </Button>
      </div>
    </div>
  );
};

export default PromoCode;
