import TableTera from '_common/dof/TableTera';
import { SERVICE_REGISTRATION_URL } from 'pages/ServiceManagement/ServiceRegistration/url';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, formatCurrency, Tag, TrashSolid } from 'tera-dls';

const InformationOrder = (): JSX.Element => {
  const navigate = useNavigate();

  const columns: ColumnsType<any> = [
    {
      title: 'Tên miền',
      dataIndex: 'domain',
      width: 350,
      render: (domain) => (
        <div className="flex items-start gap-2">
          <Tag color="green03" className="font-[500] overflow-visible">
            Đăng ký mới
          </Tag>
          <div className="flex flex-col">
            <span>{domain}</span>
            <span className="text-blue-500 cursor-pointer">
              Hiệu chỉnh cấu hình
            </span>
          </div>
        </div>
      ),
    },
    {
      title: 'Phí cài đặt',
      dataIndex: 'installation_fee',
      render: (installation_fee) => formatCurrency(installation_fee),
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      render: (price, record) => {
        return record.discount > 0 ? (
          <div className="flex gap-3">
            <span>{formatCurrency(record.discount)}</span>
            <del className="text-[#6B7280]">{formatCurrency(price)}</del>
          </div>
        ) : (
          <span>{formatCurrency(price)}</span>
        );
      },
    },
    {
      width: 40,
      fixed: 'right',
      render: () => (
        <TrashSolid
          color="#e05252"
          className="w-[20px] h-[20px] cursor-pointer"
          onClick={() => navigate(SERVICE_REGISTRATION_URL.list.path)}
        />
      ),
    },
  ];

  return (
    <div className="pb-8 border-b-[2px] border-dashed flex flex-col gap-4">
      <span className="text-blue-500">Thông tin đơn hàng</span>
      <TableTera
        className="[&_.tera-table-cell]:!align-middle"
        columns={columns}
        data={[
          {
            id: 1,
            domain: 'Business Email - Email Pro A (tenmien.com)',
            installation_fee: 0,
            price: 650000,
            discount: 350000,
          },
        ]}
        summary={() => (
          <>
            <tr className="tera-table-row">
              <td className="tera-table-cell !text-right" colSpan={2}>
                Tạm tính
              </td>
              <td className="tera-table-cell flex flex-col">
                {formatCurrency(480000)}
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell !text-right" colSpan={2}>
                Thanh toán theo định kỳ
              </td>
              <td className="tera-table-cell flex flex-col">
                <span>{formatCurrency(480000)} / 01 năm</span>
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell !text-right" colSpan={2}>
                Thanh toán hôm nay
              </td>
              <td className="tera-table-cell">
                <span className="text-[20px] text-[#0E9F6E]">
                  {formatCurrency(480000)}
                </span>
              </td>
            </tr>
          </>
        )}
      />
    </div>
  );
};

export default InformationOrder;
