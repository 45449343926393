import HeaderViewList from '_common/component/HeaderViewList';
import { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowsRightLeftSolid,
  Badge,
  Button,
  PlusOutlined,
  TabItemType,
  Tabs,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import Filter from './components/Filter';
import Search from './components/Search';
import Table from './components/Table';
import { TABS_STATUS_ITEM, TABS_STATUS_TEXT, mockDataList } from './constants';
import ModalTransferMultipleDomain from './modals/ModalTransferMultipleDomain';
import { DOMAIN_URL } from './url';

const DomainPage = (): JSX.Element => {
  const [isOpenModalTransfer, setIsOpenModalTransfer] =
    useState<boolean>(false);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateFilter = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${DOMAIN_URL.list.path}${paramString}`);
  };

  const handleChangeTab = (key: string) => {
    handleUpdateFilter({ status: key, page: 1 });
  };

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = TABS_STATUS_ITEM.map((item) => {
      return {
        key: item,
        label: (
          <div className="flex items-center gap-x-2">
            {TABS_STATUS_TEXT[item]} <Badge count={10} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, []);

  const handleSearch = (value) => {
    handleUpdateFilter({ keyword: value?.keyword, page: 1 });
  };

  const handleFilter = (values) => {
    handleUpdateFilter({ ...values, page: 1 });
  };

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách tên miền"
        actionRight={
          <div className="flex gap-2">
            <Button
              prefix={<ArrowsRightLeftSolid />}
              className="bg-green-500"
              onClick={() => setIsOpenModalTransfer(true)}
            >
              Transfer nhiều tên miền
            </Button>
            <Button
              prefix={<PlusOutlined />}
              className="bg-blue-500"
              onClick={() => navigate(`${DOMAIN_URL.search.path}`)}
            >
              Thêm mới
            </Button>
          </div>
        }
        onClickFilter={() => setIsOpenFilter(true)}
        middleContent={
          <Tabs
            onChange={handleChangeTab}
            items={tabItems}
            activeKey={queryParams?.status || 'all'}
            className="mb-0"
          />
        }
        actionLeftRender={<Search onSearch={handleSearch} />}
        filterCount={{
          filter: queryParams,
          params: ['date', 'vehicle_from', 'vehicle_to'],
        }}
      >
        <Table rowKey={'id'} data={mockDataList} queryParams={queryParams} />
      </HeaderViewList>
      {isOpenModalTransfer && (
        <ModalTransferMultipleDomain
          open={isOpenModalTransfer}
          close={() => setIsOpenModalTransfer(false)}
        />
      )}
      {isOpenFilter && (
        <Filter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
};

export default DomainPage;
