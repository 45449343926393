import CheckBox from '_common/dof/Control/CheckBox';
import { FormTeraItem } from '_common/dof/FormTera';
import { UNAUTH_SERVICE_REGISTRATION_URL } from 'pages/UnAuth/ServiceManagement/ServiceRegistration/url';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  CheckSolid,
  formatCurrency,
  ShoppingCartSolid,
} from 'tera-dls';
import { UNAUTH_CLOUD_SERVER_URL } from '../../../url';

const Payment = (): JSX.Element => {
  const navigate = useNavigate();

  const details = [
    {
      label: 'Tổng giá trị sản phẩm',
      value: (
        <span className="text-[#C27803] font-[500]">
          {formatCurrency(1800000)}
        </span>
      ),
    },
    {
      label: 'Phí khởi tạo',
      value: <span className="font-[500]">{formatCurrency(2000000)}</span>,
    },
    {
      label: 'Giảm giá',
      value: <span className="font-[500]">10%</span>,
    },
    {
      label: 'VAT (8%)',
      value: <span className="font-[500]">{formatCurrency(1800)}</span>,
    },
    {
      label: 'Tổng thanh toán',
      value: (
        <span className="text-[#046C4E] font-[500]">
          {formatCurrency(2600000)}
        </span>
      ),
    },
  ];

  return (
    <div className="bg-[#FAFAF9] p-4 rounded-[6px] drop-shadow flex flex-col gap-8">
      <span className="text-[16px] font-[600]">Thanh toán</span>
      <div className="flex flex-col gap-2">
        <div className="w-full pb-8 border-b-[1px] flex flex-col gap-8">
          {details.map((detail, index) => (
            <div key={index} className="flex justify-between items-center">
              <span>{detail.label}</span>
              <span>{detail.value}</span>
            </div>
          ))}
        </div>
        <div className="pt-6 flex flex-col gap-4">
          <FormTeraItem name="is_ok" className="mb-0">
            <CheckBox>
              <span className="text-gray-500">Tôi đã đọc và chấp nhận</span>
              <span className="text-blue-500 ml-1">
                Điều khoản sử dụng dịch
              </span>
            </CheckBox>
          </FormTeraItem>
          <Button
            prefix={<ShoppingCartSolid />}
            className="text-blue-500 hover:text-white bg-white border-[1px] border-blue-500"
            onClick={() => navigate(UNAUTH_SERVICE_REGISTRATION_URL.list.path)}
          >
            Đăng ký thêm dịch vụ
          </Button>
          <Button
            prefix={<CheckSolid />}
            className="bg-blue-500"
            onClick={() => navigate(UNAUTH_CLOUD_SERVER_URL.payment.path)}
          >
            Hoàn thành
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
