import CardDetail from '_common/component/CardDetail';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import NumberToWord from '_common/component/NumberToWord';
import { useState } from 'react';
import napas from 'styles/images/napas.png';
import {
  Button,
  Col,
  CreditCardOutlined,
  formatCurrency,
  InputNumber,
  Radio,
  Row,
} from 'tera-dls';

const PaymentContent = () => {
  const [money, setMoney] = useState<any>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>('1');

  return (
    <CardDetail title="Nạp tiền vào tài khoản" className="">
      <div className="flex gap-[30px]">
        <div>
          <p className="mb-[16px] font-medium">Auto payment 247</p>
          <img src={napas} className="h-[250px]" />
        </div>
        <div>
          <Row className="flex flex-col gap-[16px]">
            <Col className="col-span-1 flex flex-col gap-[16px]">
              <p className="font-medium">Nhập số tiền</p>
              <div className="flex flex-col gap-[30px]">
                <div className="flex flex-col gap-2.5">
                  <div className="flex gap-2 flex-wrap">
                    {[
                      50000, 100000, 200000, 500000, 1000000, 10000000,
                      20000000,
                    ].map((value) => (
                      <span
                        className="p-[4px] border border-dotted rounded text-blue-500 cursor-pointer"
                        onClick={() => setMoney(value)}
                      >
                        {formatCurrency(value)}
                      </span>
                    ))}
                  </div>
                  <InputNumber
                    value={money}
                    onChange={(e) => setMoney(e)}
                    className="font-medium text-right text-[14px] pr-[28px]"
                    suffix={<span className="font-medium">đ</span>}
                    suffixProps={{
                      className: 'leading-[15px]',
                    }}
                    max={MAXIMUM_CURRENCY}
                    allowStepHandler={false}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </div>
                <div>
                  <p className="font-medium pb-2.5">Quý khách lưu ý</p>
                  <ul className="list-decimal pl-8 text-[#000000] flex flex-col gap-[6px]">
                    <li>Số tiền tối thiểu 50.000 ₫</li>
                    <li>Số tiền tối đa 500.000.000 ₫</li>
                    <li>
                      Thanh toán sẽ tự động xác nhận trong khoảng 1 phút, nếu
                      quá thời hạn trên vui lòng liên hệ CSKH để được hỗ trợ.
                    </li>
                  </ul>
                </div>
                <p className="text-[#000000]">
                  Bằng chữ:{' '}
                  <span className="text-blue-500">
                    <NumberToWord number={money} isUpperCaseFirstLetter />.
                  </span>
                </p>
              </div>
            </Col>
            <Col className="col-span-1 flex flex-col gap-[16px]">
              <div className="text-[#374151]">
                <Radio.Group
                  name="payment_method"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <Radio value={'1'}>ACB - Ngân hàng TMCP Á Châu</Radio>
                  <Radio value={'2'}>
                    VCB - Ngân hàng TMCP Ngoại Thương Việt Nam
                  </Radio>
                  <Radio value={'3'}>
                    Thanh toán QRPAY/Thẻ ATM/Credit Card
                  </Radio>
                </Radio.Group>
              </div>
              <div>
                <Button className="font-light flex gap-1 bg-gray-500 hover:bg-gray-600">
                  <CreditCardOutlined className="size-4" />
                  <span>Thanh toán</span>
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </CardDetail>
  );
};

export default PaymentContent;
