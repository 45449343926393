import { IRouteProps } from '_common/interface/router';
import CloudServerPage from '.';
import AddCloudServerPage from './pages/Add';
import DetailCloudServerPage from './pages/Detail';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import { CLOUD_SERVER_URL } from './url';

export const CloudServerRouter: IRouteProps[] = [
  {
    key: CLOUD_SERVER_URL.list.key,
    path: CLOUD_SERVER_URL.list.shortenUrl,
    component: <CloudServerPage />,
  },
  {
    key: CLOUD_SERVER_URL.add.key,
    path: CLOUD_SERVER_URL.add.shortenUrl,
    component: <AddCloudServerPage />,
  },
  {
    key: CLOUD_SERVER_URL.paymentInformation.key,
    path: CLOUD_SERVER_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: CLOUD_SERVER_URL.payment.key,
    path: CLOUD_SERVER_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
  {
    key: CLOUD_SERVER_URL.detail.key,
    path: CLOUD_SERVER_URL.detail.shortenUrl,
    component: <DetailCloudServerPage />,
  },
];
