import CardDetail from '_common/component/CardDetail';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import NumberToWord from '_common/component/NumberToWord';
import { useState } from 'react';
import {
  Button,
  Col,
  CreditCardOutlined,
  formatCurrency,
  InputNumber,
  Radio,
  Row,
} from 'tera-dls';

const Recharge = () => {
  const [money, setMoney] = useState<any>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>('1');

  return (
    <CardDetail className="text-sm">
      <p className="text-gray-500 mb-[30px]">
        Nạp tiền để sử dụng trong tương lai, cho các đơn đặt hàng tiếp theo hoặc
        tự động gia hạn các hoá đơn.
      </p>
      <Row className="grid-cols-2 gap-[30px]">
        <Col className="col-span-1 flex flex-col gap-[16px]">
          <p className="text-gray-700">Nhập số tiền</p>
          <div className="flex flex-col gap-[30px]">
            <div className="flex flex-col gap-2.5">
              <div className="flex gap-2">
                {[
                  50000, 100000, 200000, 500000, 1000000, 10000000, 20000000,
                ].map((value) => (
                  <span
                    className="p-[4px] border border-dotted rounded text-blue-500 cursor-pointer"
                    onClick={() => setMoney(value)}
                  >
                    {formatCurrency(value)}
                  </span>
                ))}
              </div>
              <InputNumber
                value={money}
                onChange={(e) => setMoney(e)}
                className="font-medium text-right text-[14px] pr-[28px]"
                suffix={<span className="font-medium">đ</span>}
                suffixProps={{
                  className: 'leading-[15px]',
                }}
                max={MAXIMUM_CURRENCY}
                allowStepHandler={false}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
              />
            </div>
            <div>
              <p className="font-medium pb-2.5">Quý khách lưu ý</p>
              <ul className="list-decimal	pl-10 text-[#000000] flex flex-col gap-[6px]">
                <li>Số tiền tối thiểu 50.000 ₫</li>
                <li>Số tiền tối đa 500.000.000 ₫</li>
                <li>
                  Thanh toán sẽ tự động xác nhận trong khoảng 1 phút, nếu quá
                  thời hạn trên vui lòng liên hệ CSKH để được hỗ trợ.
                </li>
              </ul>
            </div>
            <p className="text-[#000000]">
              Bằng chữ:{' '}
              <span className="text-blue-500">
                <NumberToWord number={money} isUpperCaseFirstLetter />.
              </span>
            </p>
          </div>
          <div>
            <Button className="font-light flex gap-1 bg-green-500 hover:bg-green-600">
              <CreditCardOutlined className="size-4" />
              <span>Thanh toán</span>
            </Button>
          </div>
        </Col>
        <Col className="col-span-1 flex flex-col gap-[16px]">
          <p className="font-medium">Hình thức thanh toán</p>
          <div className="text-[#374151]">
            <Radio.Group
              name="payment_method"
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <Radio value={'1'}>ACB - Ngân hàng TMCP Á Châu</Radio>
              <Radio value={'2'}>
                VCB - Ngân hàng TMCP Ngoại Thương Việt Nam
              </Radio>
              <Radio value={'3'}>Thanh toán QRPAY/Thẻ ATM/Credit Card</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>
    </CardDetail>
  );
};

export default Recharge;
