import { IRouteProps } from '_common/interface/router';
import SSLPage from '.';
import AddSSLPage from './pages/Add';
import DetailSSLPage from './pages/Detail';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import { SSL_URL } from './url';

export const SSLRouter: IRouteProps[] = [
  {
    key: SSL_URL.list.key,
    path: SSL_URL.list.shortenUrl,
    component: <SSLPage />,
  },
  {
    key: SSL_URL.add.key,
    path: SSL_URL.add.shortenUrl,
    component: <AddSSLPage />,
  },
  {
    key: SSL_URL.paymentInformation.key,
    path: SSL_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: SSL_URL.payment.key,
    path: SSL_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
  {
    key: SSL_URL.detail.key,
    path: SSL_URL.detail.shortenUrl,
    component: <DetailSSLPage />,
  },
];
