export const UNAUTH_CLOUD_VPS_URL = {
  index: {
    key: '',
    path: '/welcome/service/cloud-vps',
    shortenUrl: 'cloud-vps',
  },
  add: {
    key: '',
    path: '/welcome/service/cloud-vps/add',
    shortenUrl: 'cloud-vps/add',
  },
  paymentInformation: {
    key: '',
    path: '/welcome/service/cloud-vps/payment-information',
    shortenUrl: 'cloud-vps/payment-information',
  },
  payment: {
    key: '',
    path: '/welcome/service/cloud-vps/payment',
    shortenUrl: 'cloud-vps/payment',
  },
};
