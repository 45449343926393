import { observer } from 'mobx-react-lite';
import { SHOPPING_CART_URL } from 'pages/ShoppingCart/url';
import { useNavigate } from 'react-router-dom';
import { Badge, Popover, ShoppingCartSolid, formatCurrency } from 'tera-dls';

const UserShoppingCart = (): JSX.Element => {
  const navigate = useNavigate();

  const Content = (): JSX.Element => {
    return (
      <div className="">
        <div className="p-2 px-4 border-b-[1px]">Giỏ hàng</div>
        <div className="w-[300px] max-h-[200px] overflow-y-scroll">
          {Array(10)
            .fill(null)
            .map((_, index) => {
              return (
                <div
                  key={index}
                  className="flex justify-between items-center p-2 px-3 border-b-[1px]"
                >
                  <span>Tên dich vụ</span>
                  <span className="text-[#0E9F6E] font-bold">
                    {formatCurrency(313000)}
                  </span>
                </div>
              );
            })}
        </div>
        <a
          className="block text-blue-600 p-2 px-4 cursor-pointer"
          onClick={() => navigate(SHOPPING_CART_URL.cart.path)}
        >
          Đến trang giỏ hàng &gt;&gt;
        </a>
      </div>
    );
  };

  return (
    <Popover
      trigger="click"
      content={<Content />}
      placement="bottom-end"
      showArrow={false}
      className="border p-0"
    >
      <div className="cursor-pointer relative">
        <ShoppingCartSolid className="w-6 h-6" />
        <Badge
          showZero
          count={10}
          className="text-white text-[8px] bg-[#F98080] px-1 py-0 absolute -top-1 -right-1"
        />
      </div>
    </Popover>
  );
};

export default observer(UserShoppingCart);
