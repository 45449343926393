import { useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  getQueryParams,
  Spin,
} from 'tera-dls';

import { INVOICE_URL } from '../../url';
import InvoiceContent from './InvoiceContent';

const InvoiceTemplate = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  // const confirm = useConfirm();

  // const handlePayment = (id: number, name: string) => {
  //   confirm.warning({
  //     title: 'Xác nhận thanh toán',
  //     content: (
  //       <div className="break-word">
  //         <p>Bạn có chắc muốn thanh toán hóa đơn</p>
  //         <p>
  //           <span className="font-bold">{name}</span> này không?
  //         </p>
  //       </div>
  //     ),
  //     onOk: () => {
  //       id;
  //     },
  //   });
  // };

  return (
    <Spin spinning={false}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <ArrowSmallLeftSolid className="size-6" />
              </div>
              <Breadcrumb
                separator={'/'}
                items={[
                  {
                    title: (
                      <a className="cursor-pointer text-blue-400 italic text-sm">
                        Danh sách hóa đơn
                      </a>
                    ),
                    onClick: () => navigate(INVOICE_URL.list.path),
                  },
                  {
                    title: (
                      <a className="font-medium hover:text-gray-700 cursor-default tracking-[0.2px] text-sm">
                        Chi tiết hóa đơn
                      </a>
                    ),
                  },
                ]}
              />
            </div>
            <div className="page-header-v2__function shrink-0">
              {/* <Button onClick={() => handlePayment(1, '#HD123')}>
                Xác nhận thanh toán
              </Button> */}
            </div>
          </div>
        </div>
        <div className="page-content-v2 !gap-[16px] rounded-md !px-[16px] h-full mx-2.5 !mb-2.5">
          <InvoiceContent isDetail={queryParams?.type == 'detail'} />
        </div>
      </div>
    </Spin>
  );
};

export default InvoiceTemplate;
