export const UNAUTH_SSL_URL = {
  index: {
    key: '',
    path: '/welcome/service/ssl',
    shortenUrl: 'ssl',
  },
  paymentInformation: {
    key: '',
    path: '/welcome/service/ssl/payment-information',
    shortenUrl: 'ssl/payment-information',
  },
  payment: {
    key: '',
    path: '/welcome/service/ssl/payment',
    shortenUrl: 'ssl/payment',
  },
};
