import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, DocumentTextSolid } from 'tera-dls';

interface IWorkNoteValues {
  note: string;
}

const WorkNote = (): JSX.Element => {
  const form = useForm({ mode: 'onChange' });

  useEffect(() => {
    const initData: IWorkNoteValues = {
      note: '',
    };
    form.reset(initData);
  }, []);

  const handleSaveWorkNote = (values: IWorkNoteValues) => console.log(values);

  return (
    <FormTera
      form={form}
      onSubmit={handleSaveWorkNote}
      className="w-full h-full bg-white mt-[2rem] drop-shadow rounded-[4px] flex flex-col justify-between"
    >
      <div className="bg-[#EBF5FF] p-4 flex items-center gap-2">
        <DocumentTextSolid color="#3F83F8" className="w-4 h-4" />
        <span>Ghi chú công việc</span>
      </div>
      <div className="p-4">
        <FormTeraItem name="note" className="w-full mb-0">
          <TextArea
            placeholder="Vui lòng nhập"
            className="w-full h-[130px] border rounded-[3px] resize-none"
          />
        </FormTeraItem>
      </div>
      <div className="bg-[#EBF5FF] p-4">
        <Button className="bg-[#3F83F8]">
          <span className="text-[15px]">Lưu thay đổi</span>
        </Button>
      </div>
    </FormTera>
  );
};

export default WorkNote;
