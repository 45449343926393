import CardDetail from '_common/component/CardDetail';
import CheckBox from '_common/dof/Control/CheckBox';
import CheckBoxGroup from '_common/dof/Control/CheckboxGroup';
import Input from '_common/dof/Control/Input';
import Radio from '_common/dof/Control/Radio';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Checkbox, Row, Spin } from 'tera-dls';

const SetupSecurityPage = (): JSX.Element => {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    values;
  };

  useEffect(() => {
    form.reset({
      line: 25,
      time: 1,
      bank: 1,
      credit: 1,
      inform_email: ['2', '4'],
      merge_invoices: '1',
      nameservers: '1',
    });
  }, []);

  return (
    <Spin spinning={false}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div className="text-gray-700 font-semibold text-base uppercase">
                Bảo mật
              </div>
            </div>
            <div className="page-header-v2__function shrink-0">
              {/* <Button onClick={form.handleSubmit(handleSubmitForm)}>Lưu</Button> */}
              <Button
                onClick={form.handleSubmit(handleSubmitForm)}
                className="font-light text-[12px] bg-blue-500"
              >
                Lưu
              </Button>
            </div>
          </div>
        </div>
        <div className="page-content-v2 bg-white shadow-xsm rounded-md !px-[16px] h-full mx-2.5 !mb-2.5 !gap-y-0">
          <FormTera form={form}>
            <div className="flex flex-col gap-[16px] px-2">
              <CardDetail className="pb-0 !shadow-none">
                <p className="text-gray-500 mb-[10px]">
                  Hệ thống tiếp thị liên kết toàn diện
                </p>
                <div className="flex flex-col divide-y-[2px] divide-dotted">
                  <CardDetail
                    className="text-sm !shadow-none pl-0"
                    title="Thông tin chung"
                  >
                    <Row className="grid-cols-1 sm:grid-cols-2 xmd:grid-cols-3">
                      <FormTeraItem
                        label="Số dòng hiển thị mỗi trang"
                        name="line"
                      >
                        <Select
                          allowClear={false}
                          options={[
                            { value: 25, label: '25' },
                            { value: 50, label: '50' },
                            { value: 100, label: '100' },
                            { value: 200, label: '200' },
                          ]}
                        />
                      </FormTeraItem>
                      <FormTeraItem label="Múi giờ" name="time">
                        <Select
                          allowClear={false}
                          options={[
                            { value: 1, label: '(GMT + 07:00) Hanoi, TPHCM' },
                          ]}
                        />
                      </FormTeraItem>
                    </Row>
                  </CardDetail>
                  <CardDetail
                    className="text-sm !shadow-none pl-0"
                    title="Thanh toán"
                  >
                    <div className="border-l-[2px] mb-4 border-blue-500 px-2.5">
                      <FormTeraItem
                        label="Phương thức thanh toán mặc định"
                        name="bank"
                      >
                        <Select
                          allowClear={false}
                          options={[
                            { value: 1, label: 'ACB - Ngân hàng TMCP Á Châu' },
                          ]}
                        />
                      </FormTeraItem>
                      <FormTeraItem label="" name="is_all_service">
                        <CheckBox labelClassName="font-normal text-[14px]">
                          Áp dụng cho tất cả các dịch vụ
                        </CheckBox>
                      </FormTeraItem>
                    </div>
                    <FormTeraItem label="Tự động sử dụng Credit" name="credit">
                      <Select
                        allowClear={false}
                        options={[
                          {
                            value: 1,
                            label:
                              'Có, áp dụng tín dụng có sẵn cho các đơn đặt hàng mới và hóa đơn định kỳ ngay lập tức',
                          },
                          {
                            value: 2,
                            label:
                              'Không, không tự động áp dụng tín dụng có sẵn.     ',
                          },
                        ]}
                      />
                    </FormTeraItem>
                    <FormTeraItem label="Merge Invoices" name="merge_invoices">
                      <Radio
                        listOption={[
                          {
                            value: 1,
                            label:
                              'Có, Tự động gọp các hoá đơn được tạo cùng ngày',
                          },
                          {
                            value: 2,
                            label:
                              'Có, Tự động gọp các hoá đơn có cùng ngày đến hạn',
                          },
                          {
                            value: 3,
                            label: 'Không, Tạo hoá đơn riêng cho từng dịch vụ',
                          },
                        ]}
                      />
                    </FormTeraItem>
                  </CardDetail>
                  <CardDetail
                    className="text-sm !shadow-none pl-0"
                    title="Thông báo"
                  >
                    <FormTeraItem label="Thông báo email" name="inform_email">
                      <CheckBoxGroup
                        children={[
                          <Checkbox value="1" labelClassName="font-normal">
                            Thông báo cho tôi khi ai đó đăng nhập tài khoản
                          </Checkbox>,
                          <Checkbox value="2" labelClassName="font-normal">
                            Nhận thông báo email liên quan đến thanh toán
                          </Checkbox>,
                          <Checkbox value="3" labelClassName="font-normal">
                            Nhận thông báo email liên quan đến hỗ trợ
                          </Checkbox>,
                          <Checkbox value="4" labelClassName="font-normal">
                            Nhận thông báo email liên quan đến dịch vụ
                          </Checkbox>,
                          <Checkbox value="5" labelClassName="font-normal">
                            Nhận thông báo email liên quan đến tên miền
                          </Checkbox>,
                        ]}
                      />
                    </FormTeraItem>
                  </CardDetail>
                  <CardDetail
                    className="text-sm !shadow-none pl-0"
                    title="Domains"
                  >
                    <FormTeraItem label="Nameservers" name="nameservers">
                      <Radio
                        listOption={[
                          {
                            value: 1,
                            label:
                              'Sử dụng nameserver mặc định của chúng tôi khi đăng ký tên miền mới',
                          },
                          {
                            value: 2,
                            label: 'Sử dụng Nameserver riêng người dùng',
                          },
                        ]}
                      />
                    </FormTeraItem>
                    {form.watch('nameservers') == 2 && (
                      <div className="w-full sm:w-[60%] xmd:w-[50%]">
                        <FormTeraItem label="NS1" name="ns1">
                          <Input placeholder="Vui lòng nhập" />
                        </FormTeraItem>
                        <FormTeraItem label="NS2" name="ns2">
                          <Input placeholder="Vui lòng nhập" />
                        </FormTeraItem>
                        <FormTeraItem label="NS3" name="ns3">
                          <Input placeholder="Vui lòng nhập" />
                        </FormTeraItem>
                        <FormTeraItem label="NS4" name="ns4">
                          <Input placeholder="Vui lòng nhập" />
                        </FormTeraItem>
                      </div>
                    )}
                  </CardDetail>
                </div>
              </CardDetail>
            </div>
          </FormTera>
        </div>
      </div>
    </Spin>
  );
};

export default SetupSecurityPage;
