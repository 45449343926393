import CardDetail from '_common/component/CardDetail';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import NumberToWord from '_common/component/NumberToWord';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import PaymentImage from 'styles/images/payment-img.png';
import {
  Button,
  Col,
  formatCurrency,
  InputNumber,
  Modal,
  Radio,
  Row,
} from 'tera-dls';

const RechargeModel = (props) => {
  const { open, onClose } = props;
  const confirm = useConfirm();
  const [step, setStep] = useState<number>(1);
  const [money, setMoney] = useState<any>(0);
  const [paymentMethod, setPaymentMethod] = useState<string>('1');

  const handleCancel = () => {
    confirm.warning({
      title: 'Thoát ',
      content: (
        <>
          <p>Bạn có chắc muốn thoát thiết lập này không?</p>
          <p>Sau khi thoát dữ liệu sẽ không được lưu cấu hình.</p>
        </>
      ),
      onOk: () => {
        onClose();
      },
    });
  };
  return (
    <Modal
      open={open}
      title={'Nạp tiền'}
      closeIcon={false}
      okText="Tiếp tục"
      cancelText="Hủy"
      className="!w-[90%] xmd:!w-[80%]"
      maskClosable={false}
      onCancel={handleCancel}
      footer={
        <div className="flex gap-2.5">
          {step === 1 ? (
            <Button
              type="light"
              className="px-5 text-blue-500 border-blue-500 border"
              onClick={handleCancel}
            >
              Hủy
            </Button>
          ) : (
            <Button
              type="light"
              className="px-5 text-blue-500 border-blue-500 border"
              onClick={() => {
                setStep(1);
              }}
            >
              Quay lại
            </Button>
          )}

          {step === 1 ? (
            <Button className="px-5" onClick={() => setStep(2)}>
              Tiếp tục
            </Button>
          ) : (
            <Button
              className="px-5"
              onClick={() => {
                onClose();
              }}
            >
              Đóng
            </Button>
          )}
        </div>
      }
    >
      {step === 2 ? (
        <div className="flex flex-col gap-[16px]">
          <p className="text-blue-500">
            Để nạp tiền vào tài khoản, Quý khách có thể lựa chọn một trong hai
            cách sau
          </p>
          <div className="flex flex-col gap-[16px]">
            <p className="font-medium text-gray-700">Cách 1</p>
            <div className="flex gap-[30px] items-center">
              <div className="max-w-[260px]">
                <img src="https://s3-alpha-sig.figma.com/img/fc7d/cd16/7657fd74e2c6de0342f8d61074fe305c?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Cuk8EM~v~mCD14PiTw7DjaRZYSoC1cGvaGHVVwL6kvNQRfHGpXvf8cwUa7zVIn~zXPxggblsDV6Oe7SFlZmwqvN0RWy0arc6eEcliDM8HKj-Afge1jYZbDUjnIb2f7GAPeR8xgdNc8oiXdcF-bVrgJ1AvUD7yTRzhQ6LTbujedETST35Q1Wi~B04tAbeX4HXc0gTQqvI6NvhZQeM2JllghfBrJ3AC5HBFaW2L0o7gb3UmTIxXyu-RHqf0Wc68X5XbR9agQuOzUI31YBD-lh8CporOewiN5oTShesDQqoYNe0kZkGDh3XjA8oFYpAb9QkbqzBoN8Wces48UOqJSZFZQ__" />
              </div>
              <div>
                <p>
                  Tự động điền số tiền, nội dung chuyển khoản chính xác nhất và
                  tự động xác nhận ngay chuyển tiền thành công.
                </p>
                <p>
                  Quét mã QR này và chuyển tiền trong ứng dụng Mobile Banking.
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-[16px]">
            <p className="font-medium text-gray-700">Cách 2</p>
            <p>
              Quý khách thực hiện chuyển khoản đến các ngân hàng được liệt kê
              dưới đây với số tiền và nội dung chuyển khoản như sau
            </p>
            <div>
              <img src={PaymentImage} />
            </div>
          </div>
        </div>
      ) : (
        <CardDetail className="text-sm">
          <p className="text-gray-500 mb-[30px]">
            Nạp tiền để sử dụng trong tương lai, cho các đơn đặt hàng tiếp theo
            hoặc tự động gia hạn các hoá đơn.
          </p>
          <Row className="grid-cols-2 gap-[30px]">
            <Col className="col-span-1 flex flex-col gap-[16px]">
              <p className="text-gray-700">Nhập số tiền</p>
              <div className="flex flex-col gap-[30px]">
                <div className="flex flex-col gap-2.5">
                  <div className="flex gap-2">
                    {[
                      50000, 100000, 200000, 500000, 1000000, 10000000,
                      20000000,
                    ].map((value) => (
                      <span
                        className="p-[4px] border border-dotted rounded text-blue-500 cursor-pointer"
                        onClick={() => setMoney(value)}
                      >
                        {formatCurrency(value)}
                      </span>
                    ))}
                  </div>
                  <InputNumber
                    value={money}
                    onChange={(value) => setMoney(value)}
                    className="font-medium text-right text-[14px] pr-[28px]"
                    suffix={<span className="font-medium">đ</span>}
                    suffixProps={{
                      className: 'leading-[15px]',
                    }}
                    max={MAXIMUM_CURRENCY}
                    allowStepHandler={false}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </div>
                <div>
                  <p className="font-medium pb-2.5">Quý khách lưu ý</p>
                  <ul className="list-decimal	pl-10 text-[#000000] flex flex-col gap-[6px]">
                    <li>Số tiền tối thiểu 50.000 ₫</li>
                    <li>Số tiền tối đa 500.000.000 ₫</li>
                    <li>
                      Thanh toán sẽ tự động xác nhận trong khoảng 1 phút, nếu
                      quá thời hạn trên vui lòng liên hệ CSKH để được hỗ trợ.
                    </li>
                  </ul>
                </div>
                <p className="text-[#000000]">
                  Bằng chữ:{' '}
                  <span className="text-blue-500">
                    <NumberToWord number={money} isUpperCaseFirstLetter />.
                  </span>
                </p>
              </div>
            </Col>
            <Col className="col-span-1 flex flex-col gap-[16px]">
              <p className="font-medium">Hình thức thanh toán</p>
              <div className="text-[#374151]">
                <Radio.Group
                  name="payment_method"
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                >
                  <Radio value={'1'}>ACB - Ngân hàng TMCP Á Châu</Radio>
                  <Radio value={'2'}>
                    VCB - Ngân hàng TMCP Ngoại Thương Việt Nam
                  </Radio>
                  <Radio value={'3'}>
                    Thanh toán QRPAY/Thẻ ATM/Credit Card
                  </Radio>
                </Radio.Group>
              </div>
            </Col>
          </Row>
        </CardDetail>
      )}
    </Modal>
  );
};

export default RechargeModel;
