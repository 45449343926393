import ZingIcon from '_common/component/ZingIcon';
import useConfirm from '_common/hooks/useConfirm';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import { useNavigate } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  CreditCardSolid,
  Tag,
  formatCurrency,
} from 'tera-dls';
import { CLOUD_SERVER_URL } from '../../url';

const DetailCloudServerPage = (): JSX.Element => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud Server
        </span>
      ),
      onClick: () => navigate(CLOUD_SERVER_URL.list.path),
    },
    {
      title: 'Chi tiết Cloud Server',
    },
  ];

  const details = [
    { label: 'Ngày đăng ký', value: '03/08/2024' },
    {
      label: 'Ngày hết hạn',
      value: (
        <div className="flex gap-2">
          <span className="text-red-500">03/08/2024</span>
          <span>Hoá đơn chưa thanh toán</span>
          <span className="text-blue-500">#TNG616011</span>
        </div>
      ),
    },
    { label: 'Số tiền thanh toán đầu tiên', value: formatCurrency(175000) },
    { label: 'Số tiền thanh toán định kỳ', value: formatCurrency(175000) },
    { label: 'Chu kỳ thanh toán', value: 'Hàng năm' },
    {
      label: 'Tự động gia hạn',
      value: (
        <div className="flex gap-4">
          <Tag color="green03">Bật</Tag>
          <Tag color="gray01">Tắt</Tag>
        </div>
      ),
    },
  ];

  const DetailHeader = (): JSX.Element => {
    return (
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-4">
          <h1 className="text-[20px] font-[600]">Colo A # TNG615950</h1>
          <div className="flex gap-4">
            <Tag color="green03" className="p-2">
              Hoạt động
            </Tag>
            <Tag color="yellow03">Đang chờ</Tag>
            <Tag color="red03">Đã hủy</Tag>
          </div>
        </div>
        <div className="flex gap-4">
          <Button
            className="bg-blue-500"
            prefix={<CreditCardSolid />}
            onClick={() => navigate(`${INVOICE_URL.detail.path}/1`)}
          >
            Thanh toán ngay
          </Button>
          <Button
            className="bg-red-500"
            prefix={<ZingIcon.IconClose />}
            onClick={() =>
              confirm.warning({
                title: 'Xác nhận yêu cầu hủy dịch vụ',
                content: (
                  <>
                    <p>Bạn có chắc muốn xác nhận yêu cầu hủy dịch vụ</p>
                    <p>
                      <span className="font-bold">#TNG615950</span> này không?
                    </p>
                  </>
                ),
              })
            }
          >
            Yêu cầu hủy dịch vụ
          </Button>
        </div>
      </div>
    );
  };

  const DetailContent = (): JSX.Element => {
    return (
      <div className="p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
        <div className="pb-4 border-b-[2px] border-dashed">
          <span className="text-blue-500">Thông tin chung</span>
          <div className="p-4 flex flex-col gap-1">
            {details.map((detail, index) => (
              <div
                key={index}
                className="py-3 border-b-[1px] flex items-center"
              >
                <span className="flex-1 text-gray-500">{detail.label}</span>
                <span className="flex-1">{detail.value}</span>
              </div>
            ))}
          </div>
        </div>
        <span className="text-blue-500 mt-2">Thông tin dịch vụ</span>
        <div className="p-4 flex flex-col gap-1">
          <div className="py-3 flex items-center">
            <span className="flex-1 text-gray-500">Datacenter</span>
            <span className="flex-1">CMC Tân Thuận</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <div className="px-2 flex flex-col gap-4">
        <DetailHeader />
        <DetailContent />
      </div>
    </div>
  );
};

export default DetailCloudServerPage;
