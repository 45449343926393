export const UNAUTH_HOSTING_URL = {
  index: {
    key: '',
    path: '/welcome/service/hosting',
    shortenUrl: 'hosting',
  },
  paymentInformation: {
    key: '',
    path: '/welcome/service/hosting/payment-information',
    shortenUrl: 'hosting/payment-information',
  },
  payment: {
    key: '',
    path: '/welcome/service/hosting/payment',
    shortenUrl: 'hosting/payment',
  },
};
