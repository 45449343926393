import { IRouteProps } from '_common/interface/router';
import InvoicePage from '.';
import { INVOICE_URL } from './url';
import InvoiceTemplate from './containers/InvoiceDetail/InvoiceTemplate';

export const InvoiceRouter: IRouteProps[] = [
  {
    key: INVOICE_URL.list.key,
    path: INVOICE_URL.list.shortenUrl,
    component: <InvoicePage />,
  },
  {
    key: INVOICE_URL.detail.key,
    path: INVOICE_URL.detail.shortenUrl,
    component: <InvoiceTemplate />,
  },
];
