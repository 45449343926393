export const UNAUTH_CLOUD_SERVER_URL = {
  index: {
    key: '',
    path: '/welcome/service/cloud-server',
    shortenUrl: 'cloud-server',
  },
  paymentInformation: {
    key: '',
    path: '/welcome/service/cloud-server/payment-information',
    shortenUrl: 'cloud-server/payment-information',
  },
  payment: {
    key: '',
    path: '/welcome/service/cloud-server/payment',
    shortenUrl: 'cloud-server/payment',
  },
};
