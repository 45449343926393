import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, formatCurrency, Tooltip } from 'tera-dls';
import { billingCycleOptions } from '../../../constants';
import ModalSummary from '../../../modals/ModalSummary';
import { SSL_URL } from '../../../url';

const FormSummary = (): JSX.Element => {
  const [isOpenModalSummary, setIsOpenModalSummary] = useState<boolean>(false);

  const navigate = useNavigate();

  const PaymentTodayTooltip = () => {
    return (
      <div className="flex flex-col">
        <div className="border-b-[1px] pb-2 flex flex-col gap-2">
          Thanh toán hôm nay:
          <span className="font-[500]">{formatCurrency(3653000)}</span>
        </div>
        <div className="pt-2 flex flex-col gap-2">
          Thanh toán theo định kỳ:
          <div className="flex gap-1">
            <span className="font-[500]">{formatCurrency(2489300)}</span> / 01
            năm
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="px-2 mt-auto sticky -bottom-0 z-[1]">
      <div className="p-4 bg-white rounded-[6px] flex justify-between items-center">
        <div className="flex flex-col gap-2">
          <span className="text-[12px] text-gray-500">Chu kỳ thanh toán</span>
          <FormTeraItem
            name="billing_cycle"
            className="mb-0"
            childrenClassName="flex flex-col gap-2"
          >
            <Select options={billingCycleOptions} className="w-[300px]" />
            <div className="flex items-center gap-2">
              <span>Thuế VAT có áp dụng cho đơn hàng này</span>
              <IconText
                prefix={<ZingIcon.IconInformation />}
                className="text-blue-500 gap-1 cursor-pointer"
                onClick={() => setIsOpenModalSummary(true)}
              >
                Chi tiết
              </IconText>
            </div>
          </FormTeraItem>
        </div>
        <div className="flex-1 flex justify-center">
          <div className="flex flex-col gap-2">
            <span className="text-[12px] text-gray-500">
              Thanh toán hôm nay
            </span>
            <IconText
              suffix={
                <Tooltip title={<PaymentTodayTooltip />} placement="top">
                  <ZingIcon.IconQuestionMark />
                </Tooltip>
              }
              className="text-blue-500 gap-1"
            >
              <span className="text-[20px] text-green-500">
                {formatCurrency(30000)}
              </span>
            </IconText>
          </div>
        </div>
        <Button
          className="flex-1 bg-blue-500"
          onClick={() => navigate(SSL_URL.paymentInformation.path)}
        >
          Đăng ký dịch vụ
        </Button>
      </div>
      {isOpenModalSummary && (
        <ModalSummary
          open={isOpenModalSummary}
          close={() => setIsOpenModalSummary(false)}
        />
      )}
    </div>
  );
};

export default FormSummary;
