export const DOMAIN_URL = {
  list: {
    key: '',
    path: '/portal/service/domain/list',
    shortenUrl: 'domain/list',
  },
  search: {
    key: '',
    path: '/portal/service/domain/search',
    shortenUrl: 'domain/search',
  },
  add: {
    key: '',
    path: '/portal/service/domain/add',
    shortenUrl: 'domain/add',
  },
  paymentInformation: {
    key: '',
    path: '/portal/service/domain/payment-information',
    shortenUrl: 'domain/payment-information',
  },
  payment: {
    key: '',
    path: '/portal/service/domain/payment',
    shortenUrl: 'domain/payment',
  },
  detail: {
    key: '',
    path: '/portal/service/domain/detail',
    shortenUrl: 'domain/detail/:id',
  },
};
