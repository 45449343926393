import { IRouteProps } from '_common/interface/router';
import UnAuthCloudServerPage from '.';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import { UNAUTH_CLOUD_SERVER_URL } from './url';

export const UnAuthCloudServerRouter: IRouteProps[] = [
  {
    key: UNAUTH_CLOUD_SERVER_URL.index.key,
    path: UNAUTH_CLOUD_SERVER_URL.index.shortenUrl,
    component: <UnAuthCloudServerPage />,
  },
  {
    key: UNAUTH_CLOUD_SERVER_URL.paymentInformation.key,
    path: UNAUTH_CLOUD_SERVER_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: UNAUTH_CLOUD_SERVER_URL.payment.key,
    path: UNAUTH_CLOUD_SERVER_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
];
