import FormLogin from 'pages/Auth/Login/components/FormLogin';
import FormRegister from 'pages/Auth/Register/components/FormRegister';
import { useState } from 'react';

const InformationCustomer = (): JSX.Element => {
  const [customerType, setCustomerType] = useState<number>(1);

  return (
    <div className="py-8 border-b-[2px] border-dashed flex flex-col items-center gap-4">
      <span className="w-full text-blue-500">Thông tin khách hàng</span>
      <div className="w-full flex flex-wrap gap-2">
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="customer_type"
            checked={customerType === 1}
            onChange={() => setCustomerType(1)}
          />
          <span>Tạo tài khoản</span>
        </label>
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="customer_type"
            checked={customerType === 2}
            onChange={() => setCustomerType(2)}
          />
          <span>Tôi đã có tài khoản</span>
        </label>
      </div>
      {customerType === 1 && (
        <div className="w-full">
          <FormRegister />
        </div>
      )}
      {customerType === 2 && (
        <div className="flex justify-center">
          <FormLogin />
        </div>
      )}
    </div>
  );
};

export default InformationCustomer;
