import PageTitle from '_common/component/PageTitle';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import ImgServiceDomain from 'styles/images/pages/ServiceRegistration/service_domain.png';
import ImgServiceEmailSecurity from 'styles/images/pages/ServiceRegistration/service_email_security.png';
import ImgServiceHosting from 'styles/images/pages/ServiceRegistration/service_hosting.png';
import ImgServiceServer from 'styles/images/pages/ServiceRegistration/service_server.png';
import ImgServiceSSL from 'styles/images/pages/ServiceRegistration/service_ssl.png';
import ImgServiceVPS from 'styles/images/pages/ServiceRegistration/service_vps.png';
import { formatCurrency } from 'tera-dls';
import { CLOUD_SERVER_URL } from '../CloudServer/url';
import { CLOUD_VPS_URL } from '../CloudVPS/url';
import { DOMAIN_URL } from '../Domain/url';
import { EMAIL_SECURITY_URL } from '../EmailSecurity/url';
import { HOSTING_URL } from '../Hosting/url';
import { SSL_URL } from '../SSL/url';

const ServiceRegistrationPage = (): JSX.Element => {
  const navigate = useNavigate();

  const services = [
    {
      img: ImgServiceDomain,
      name: 'Tên miền',
      price: 100000,
      path: DOMAIN_URL.search.path,
    },
    {
      img: ImgServiceHosting,
      name: 'Web Hosting',
      price: 100000,
      path: HOSTING_URL.add.path,
    },
    {
      img: ImgServiceSSL,
      name: 'Chứng chỉ SSL',
      price: 100000,
      path: SSL_URL.add.path,
    },
    {
      img: ImgServiceEmailSecurity,
      name: 'Email và bảo mật',
      price: 100000,
      path: EMAIL_SECURITY_URL.add.path,
    },
    {
      img: ImgServiceServer,
      name: 'Máy chủ',
      price: 100000,
      path: CLOUD_SERVER_URL.add.path,
    },
    {
      img: ImgServiceVPS,
      name: 'NVMe VPS',
      price: 100000,
      path: CLOUD_VPS_URL.add.path,
    },
  ];

  return (
    <Fragment>
      <PageTitle title="Đăng ký dịch vụ" />
      <div className="tera-page min-h-[calc(100vh_-_85px)] flex justify-center items-center">
        <div className="grid grid-cols-3 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="w-[320px] h-[310px] text-[16px] bg-white p-4 rounded-[10px] drop-shadow flex flex-col justify-between items-center cursor-pointer hover:bg-blue-100 hover:text-blue-500 hover:drop-shadow-xl"
              onClick={() => navigate(service.path || '#')}
            >
              <img
                src={service.img}
                alt="Service Image"
                className="w-[230px] h-[200px] object-contain"
              />
              <div className="flex flex-col items-center gap-4">
                <span className="font-[600]">{service.name}</span>
                <div className="flex gap-1">
                  Từ
                  <span className="font-[600]">
                    {formatCurrency(service.price)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default ServiceRegistrationPage;
