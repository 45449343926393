import PageTitle from '_common/component/PageTitle';
import FormTera from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { Fragment } from 'react/jsx-runtime';
import Advertisement from './components/Advertisement';
import InformationCustomer from './components/InformationCustomer';
import InformationOrder from './components/InformationOrder';
import Note from './components/Note';
import Notification from './components/Notification';
import Payment from './components/Payment';
import PaymentMethod from './components/PaymentMethod';
import PromoCode from './components/PromoCode';

const ShoppingCartPage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { payment_method: 1, is_ok: true },
  });

  return (
    <Fragment>
      <PageTitle title="GIỎ HÀNG" />
      <div className="tera-page">
        <FormTera form={form} className="w-full p-4 flex items-start gap-8">
          <div className="w-[70%] p-4 bg-white rounded-[6px] drop-shadow">
            <InformationOrder />
            <Notification />
            <InformationCustomer />
            <Advertisement />
            <Note />
          </div>
          <div className="w-[30%] flex flex-col gap-8">
            <PromoCode />
            <PaymentMethod />
            <Payment />
          </div>
        </FormTera>
      </div>
    </Fragment>
  );
};

export default ShoppingCartPage;
