import BasicLayout from '_common/component/Layout/BasicLayout';
import PageLayout from '_common/component/Layout/PageLayout';
import UnAuthLayout from '_common/component/Layout/UnAuthLayout';
import PageNotPermission from '_common/component/PageNotPermission';
import PageNotfound from '_common/component/PageNotfound';
import PageUnauthorized from '_common/component/PageUnauthorized';
import { IRouteProps } from '_common/interface/router';
import { HistoryRouter } from 'pages/Account/History/router';
import { InformationRouter } from 'pages/Account/Infomation/router';
import { SecurityRouter } from 'pages/Account/Security/router';
import { SetupSecurityRouter } from 'pages/Account/SetupSecurity/router';
import { AffiliatesRouter } from 'pages/Affiliates/Affiliates/router';
import { CommissionRouter } from 'pages/Affiliates/Commission/router';
import { PromoCodeRouter } from 'pages/Affiliates/PromoCode/router';
import CheckAuthPage from 'pages/Auth/CheckAuth';
import { ForgotPasswordRouter } from 'pages/Auth/ForgotPassword/router';
import { LoginRouter } from 'pages/Auth/Login/router';
import { LogoutRouter } from 'pages/Auth/Logout/router';
import { RegisterRouter } from 'pages/Auth/Register/router';
import { ResetPasswordRouter } from 'pages/Auth/ResetPassword/router';
import { DashboardRouter } from 'pages/Dashboard/router';
import { InvoiceRouter } from 'pages/InvoiceManagement/Invoice/router';
import { RechargeRouter } from 'pages/InvoiceManagement/Recharge/router';
import { TransactionRouter } from 'pages/InvoiceManagement/Transaction/router';
import { WithdrawalRouter } from 'pages/InvoiceManagement/Withdrawal/router';
import { CloudServerRouter } from 'pages/ServiceManagement/CloudServer/router';
import { CloudVPSRouter } from 'pages/ServiceManagement/CloudVPS/router';
import { DomainRouter } from 'pages/ServiceManagement/Domain/router';
import { EmailSecurityRouter } from 'pages/ServiceManagement/EmailSecurity/router';
import { HostingRouter } from 'pages/ServiceManagement/Hosting/router';
import { SSLRouter } from 'pages/ServiceManagement/SSL/router';
import { ServiceRegistrationRouter } from 'pages/ServiceManagement/ServiceRegistration/router';
import { ShoppingCartRouter } from 'pages/ShoppingCart/router';
import { UnAuthCloudServerRouter } from 'pages/UnAuth/ServiceManagement/CloudServer/router';
import { UnAuthCloudVPSRouter } from 'pages/UnAuth/ServiceManagement/CloudVPS/router';
import { UnAuthDomainRouter } from 'pages/UnAuth/ServiceManagement/Domain/router';
import { UnAuthEmailSecurityRouter } from 'pages/UnAuth/ServiceManagement/EmailSecurity/router';
import { UnAuthHostingRouter } from 'pages/UnAuth/ServiceManagement/Hosting/router';
import { UnAuthSSLRouter } from 'pages/UnAuth/ServiceManagement/SSL/router';
import { UnAuthServiceRegistrationRouter } from 'pages/UnAuth/ServiceManagement/ServiceRegistration/router';
import { Navigate, Route, Routes } from 'react-router-dom';
import MiddlewareRouter from 'routers/MiddlewareRouter';
import CheckAuth from './CheckAuth';
import { PaymentCartRouter } from 'pages/Payment/router';

// dashboard router
const renderRouter = (objectRouter: IRouteProps[]) => {
  return objectRouter.map((route: IRouteProps) => {
    const { key, component, path, actions, action_type } = route;
    return (
      <Route
        key={key}
        path={path}
        element={
          <PageLayout
            page_key={key}
            actions={actions}
            action_type={action_type}
          >
            {component}
          </PageLayout>
        }
      />
    );
  });
};
renderRouter;

export const Routers = () => {
  return (
    <Routes>
      <Route
        path="welcome"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route index element={<Navigate to="login" />} />
        {renderRouter(LoginRouter)}
        {renderRouter(RegisterRouter)}
        {renderRouter(ForgotPasswordRouter)}
        {renderRouter(ResetPasswordRouter)}
        <Route path="service">
          {renderRouter(UnAuthDomainRouter)}
          {renderRouter(UnAuthEmailSecurityRouter)}
          {renderRouter(UnAuthHostingRouter)}
          {renderRouter(UnAuthCloudServerRouter)}
          {renderRouter(UnAuthSSLRouter)}
          {renderRouter(UnAuthCloudVPSRouter)}
          {renderRouter(UnAuthServiceRegistrationRouter)}
        </Route>
      </Route>

      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<Navigate to="portal/dashboard" />} />
        <Route path="portal">
          {renderRouter(DashboardRouter)}
          <Route path="service">
            {renderRouter(DomainRouter)}
            {renderRouter(EmailSecurityRouter)}
            {renderRouter(HostingRouter)}
            {renderRouter(CloudServerRouter)}
            {renderRouter(SSLRouter)}
            {renderRouter(CloudVPSRouter)}
            {renderRouter(ServiceRegistrationRouter)}
          </Route>
          <Route path="invoice">
            {renderRouter(TransactionRouter)}
            {renderRouter(InvoiceRouter)}
            {renderRouter(RechargeRouter)}
            {renderRouter(WithdrawalRouter)}
          </Route>
          <Route path="affiliates">
            {renderRouter(AffiliatesRouter)}
            {renderRouter(CommissionRouter)}
            {renderRouter(PromoCodeRouter)}
          </Route>
          <Route path="account">
            {renderRouter(InformationRouter)}
            {renderRouter(SecurityRouter)}
            {renderRouter(SetupSecurityRouter)}
            {renderRouter(HistoryRouter)}
            {renderRouter(LogoutRouter)}
          </Route>
          <Route path="shopping-cart">{renderRouter(ShoppingCartRouter)}</Route>
          <Route path="payment-cart">{renderRouter(PaymentCartRouter)}</Route>
        </Route>
      </Route>

      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="check-auth" element={<CheckAuthPage />} />
      </Route>

      <Route path="*" element={<PageNotfound />} />
      <Route path="/403" element={<PageNotPermission />} />
      <Route path="/401" element={<PageUnauthorized />} />
    </Routes>
  );
};
