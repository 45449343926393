import { useQuery } from '@tanstack/react-query';
import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { useMemo, useState } from 'react';
import {
  Badge,
  DocumentDuplicateOutlined,
  DropdownItem,
  formatCurrency,
  formatDate,
  getQueryParams,
  PaginationProps,
  Tabs,
  Tag,
  Tooltip,
  updateQueryParams,
} from 'tera-dls';
import PaymentMethodApi from '../Withdrawal/api/payment';
import { TransactionMethod, TransactionType } from '../Withdrawal/constants';
import Header from './containers/Header';
import TransactionFilter from './containers/TransactionFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import { STATUS_ORDER, STATUS_ORDER_COLOR } from './constants';
import { copyText } from '_common/utils';
import moment from 'moment';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { TRANSACTION_URL } from './url';

function TransactionPage() {
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const transactionList = useQuery(
    ['get-transaction-list', queryParams],
    () => {
      const params = {
        get_created_by: 1,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        ...queryParams,
      };
      return PaymentMethodApi.transactionList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateQueryParams({ page: page, limit: pageSize });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const generateDropDownItems = (record: any): DropdownItem[] => {
    record;
    const dropdownItems: DropdownItem[] = [
      {
        key: 1,
        label: <a>Xem</a>,
        onClick: () => {
          navigate(`${TRANSACTION_URL.detail.path}/1`);
        },
      },
    ];

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã giao dịch',
      dataIndex: 'transaction_code',
      width: 150,
      render: (val) => (
        <span className="flex gap-x-1 items-center">
          {val}
          <Tooltip placement="top" title="Sao chép">
            <DocumentDuplicateOutlined
              className="w-5 h-5 text-green-600 shrink-0 cursor-pointer"
              onClick={() => copyText(val)}
            />
          </Tooltip>
        </span>
      ),
    },

    {
      title: 'Loại giao dịch',
      dataIndex: 'transaction_type',
      width: 150,
      render: (method) => TransactionType[method],
    },
    {
      title: 'Phương thức',
      dataIndex: 'methods',
      width: 150,
      render: (method) => TransactionMethod[method],
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      width: 200,
      render: (amount) => amount && formatCurrency(amount),
    },
    {
      title: 'Ngày giờ tạo',
      dataIndex: 'created_at',
      width: 200,
      render: (created_at) =>
        created_at && formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
      render: (status) => (
        <Tag color={STATUS_ORDER_COLOR[status]}>{STATUS_ORDER[status]}</Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          containerClassName="w-[50px]"
          placement="bottom-end"
        />
      ),
    },
  ];

  const handleChangeTabs = (activeKey) => {
    handleUpdateQueryParams({ status: activeKey, page: 1 });
  };

  const tabItems = useMemo(() => {
    const data = Object.keys(STATUS_ORDER)?.map((key) => ({
      key: key,
      label: (
        <h3 className="tab-table">
          <span>{STATUS_ORDER[key]}</span>
          <Badge showZero count={0} />
        </h3>
      ),
    }));
    return [
      {
        key: 'all',
        label: (
          <h3 className="tab-table">
            <span>{'Tất cả đơn hàng'}</span>
            <Badge showZero count={0} />
          </h3>
        ),
      },
      ...data,
    ];
  }, []);

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2
          title="Danh sách giao dịch"
          onClickFilter={() => setOpenFilter(true)}
          actionLeftRender={
            <Header onSearch={handleSearch} initialValue={queryParams} />
          }
          bottomContent={
            <Tabs
              onChange={handleChangeTabs}
              items={tabItems}
              activeKey={queryParams.status}
              className="mb-0"
            />
          }
        >
          <TableTera
            columns={columns}
            data={
              transactionList.data?.data ?? [
                {
                  id: 1,
                  transaction_code: 'CTZ6892',
                  transaction_type: 1,
                  methods: 1,
                  amount: 1075000,
                  created_at: moment().format(),
                  status: 1,
                },
                {
                  id: 2,
                  transaction_code: 'CTZ6892',
                  transaction_type: 2,
                  methods: 2,
                  amount: 1075000,
                  created_at: moment().format(),
                  status: 2,
                },
                {
                  id: 3,
                  transaction_code: 'CTZ6892',
                  transaction_type: 3,
                  methods: 3,
                  amount: 1075000,
                  created_at: moment().format(),
                  status: 1,
                },
              ]
            }
            pagination={{
              onChange: handleChangePage,
              to: transactionList.data?.to,
              from: transactionList.data?.from,
              current: transactionList.data?.current_page,
              pageSize: transactionList.data?.per_page,
              total: transactionList.data?.total,
            }}
          />
        </HeaderViewListV2>
      </div>
      {openFilter && (
        <TransactionFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValue={queryParams}
          onFilter={handleFilter}
        />
      )}
    </>
  );
}

export default TransactionPage;
