import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, DropdownItem, Tag } from 'tera-dls';
import { DOMAIN_STATUS, DOMAIN_STATUS_COLOR } from '../constants';
import { DOMAIN_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const itemActions = (): Array<DropdownItem> => {
    return [
      {
        key: '1',
        label: 'Xem',
        onClick: () => navigate(`${DOMAIN_URL.detail.path}/1`),
      },
      {
        key: '2',
        label: <span className="text-red-500">Yêu cầu hủy dịch vụ</span>,
        onClick: () =>
          confirm.warning({
            title: 'Xác nhận yêu cầu hủy dịch vụ',
            content: (
              <>
                <p>Bạn có chắc muốn xác nhận yêu cầu hủy dịch vụ</p>
                <p>
                  <span className="font-bold">Mã dịch vụ</span> này không?
                </p>
              </>
            ),
          }),
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: 'IP',
      dataIndex: '1',
      render: () => <span className="text-blue-500">192.168.1.1</span>,
    },
    {
      title: 'Tên miền',
      dataIndex: '2',
      render: () => <span className="text-blue-500">https://</span>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <Tag
          color={
            !props.queryParams.status || props.queryParams.status === 'all'
              ? status !== undefined
                ? DOMAIN_STATUS_COLOR[status]
                : DOMAIN_STATUS_COLOR[1]
              : DOMAIN_STATUS_COLOR[props.queryParams.status]
          }
          className="font-[500]"
        >
          {!props.queryParams.status || props.queryParams.status === 'all'
            ? status !== undefined
              ? DOMAIN_STATUS[status]
              : DOMAIN_STATUS[1]
            : DOMAIN_STATUS[props.queryParams.status]}
        </Tag>
      ),
    },
    {
      title: 'Chu kỳ thanh toán',
      dataIndex: 'payment_period',
      render: (payment_period) => (payment_period ? payment_period : '6 tháng'),
    },
    {
      title: 'Ngày đăng ký',
      dataIndex: 'registration_date',
      render: () => 'dd/mm/yyyy',
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expired_date',
      render: () => 'dd/mm/yyyy',
    },
    {
      title: 'Tự động gia hạn',
      dataIndex: 'auto_renewal',
      render: (auto_renewal) => (
        <Tag color={auto_renewal ? 'green03' : 'gray02'} className="font-[500]">
          <span className={!auto_renewal && '!text-yellow-600'}>
            {auto_renewal ? 'Bật' : 'Tắt'}
          </span>
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: () => {
        return <ActionDropdown dropdownItems={itemActions()} trigger="click" />;
      },
    },
  ];

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading}
        className="[&_.tera-table-expanded-row-fixed]:px-0 [&_.tera-table-cell]:align-middle"
        {...props}
      />
    </Fragment>
  );
};

export default Table;
