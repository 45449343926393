import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { EffectCards, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import ModalCreate from '../ModalCreate';
import Card from './Card';

import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import './styles.css';

function Slider({ data }) {
  const [modal, setModal] = useState({
    open: false,
    id: null,
  });
  const queryClient = useQueryClient();
  // const confirm = useConfirm();

  // const deleteCard = useMutation(
  //   (id: number) => PaymentMethodApi.deleteCardBank({ id }),
  //   {
  //     onSuccess(res) {
  //       if (res?.code === 200) {
  //         queryClient.invalidateQueries(['get-card-bank-list']);
  //         notification.success({
  //           message: res?.msg,
  //         });
  //       }
  //     },
  //     onError(error: any) {
  //       ErrorToast({ errorProp: error?.data });
  //     },
  //   },
  // );

  // const handleDeleteBankCard = (card) => {
  //   confirm.warning({
  //     title: 'Xác nhận xóa ngân hàng',
  //     content: (
  //       <>
  //         <p>Bạn có chắc muốn xóa thẻ ngân hàng</p>
  //         <p>
  //           <b className="break-word">{card?.cardholder}</b> này không?
  //         </p>
  //       </>
  //     ),
  //     onOk: async () => {
  //       await deleteCard.mutateAsync(card?.id);
  //     },
  //   });
  // };

  return (
    <div className="tera-bank-card overflow-hidden py-10">
      <Swiper
        effect="cards"
        grabCursor={true}
        navigation={true}
        modules={[Navigation, EffectCards]}
        className="mySwiper"
      >
        {data?.map((card) => (
          <SwiperSlide>
            <Card
              image={card?.card_type?.image_bank}
              fullName={card?.cardholder}
              bankName={card?.card_type?.card_name}
              description={card?.card_type?.description}
              cardNumber={card?.account_number}
              onUpdate={() => setModal({ open: true, id: card?.id })}
              // onDelete={() => handleDeleteBankCard(card)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {modal?.open && (
        <ModalCreate
          id={modal?.id}
          onClose={() => setModal({ open: false, id: null })}
          open={modal?.open}
          onRefetch={() =>
            queryClient.invalidateQueries(['get-card-bank-list'])
          }
        />
      )}
    </div>
  );
}

export default Slider;
