import { IRouteProps } from '_common/interface/router';
import UnAuthDomainPage from '.';
import AddDomainPage from './pages/Add';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import { UNAUTH_DOMAIN_URL } from './url';

export const UnAuthDomainRouter: IRouteProps[] = [
  {
    key: UNAUTH_DOMAIN_URL.index.key,
    path: UNAUTH_DOMAIN_URL.index.shortenUrl,
    component: <UnAuthDomainPage />,
  },
  {
    key: UNAUTH_DOMAIN_URL.add.key,
    path: UNAUTH_DOMAIN_URL.add.shortenUrl,
    component: <AddDomainPage />,
  },
  {
    key: UNAUTH_DOMAIN_URL.paymentInformation.key,
    path: UNAUTH_DOMAIN_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: UNAUTH_DOMAIN_URL.payment.key,
    path: UNAUTH_DOMAIN_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
];
