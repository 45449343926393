import ZingIcon from '_common/component/ZingIcon';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import { Button, ShoppingCartSolid } from 'tera-dls';
import { UNAUTH_DOMAIN_URL } from '../url';
import FormSummary from './FormSummary';
import SearchResultItem, { IDomainItem } from './SearchResultItem';

const domainList: Array<IDomainItem> = [
  { id: 1, name: 'tenmien.com', is_register: true, price: 0, discount: 0 },
  {
    id: 2,
    name: 'tenmien.io.vn',
    is_register: false,
    price: 650000,
    discount: 350000,
  },
  { id: 3, name: 'tenmien.vn', is_register: false, price: 30000, discount: 0 },
  {
    id: 4,
    name: 'tenmien.conetm',
    is_register: true,
    price: 310000,
    discount: 0,
  },
];

interface ISearchResultProps {
  searchKey: string;
  domainProcesses: Array<IDomainItem>;
  handleToggleDomain: (domain: IDomainItem) => void;
}

const SearchResult = ({
  searchKey,
  domainProcesses,
  handleToggleDomain,
}: ISearchResultProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="flex flex-col gap-4">
        <div className="bg-white p-4 rounded-[10px] drop-shadow flex flex-col">
          <div className="flex gap-1">
            <span>Kết quả kiểm tra cho:</span>
            <span className="text-[#0E9F6E]">{searchKey}</span>
          </div>
          <div className="flex flex-col mt-[2rem]">
            {domainList.map((domain: IDomainItem) => {
              const isExisted =
                domainProcesses.filter(
                  (domainProcess) => domainProcess.id === domain.id,
                ).length > 0;

              return (
                <SearchResultItem
                  key={domain.id}
                  domain={domain}
                  searchKey={searchKey}
                  handleToggleDomain={handleToggleDomain}
                  isExisted={isExisted}
                />
              );
            })}
          </div>
        </div>
        <div className="flex justify-center">
          <Button className="bg-[#3F83F8]" prefix={<ZingIcon.IconArrowCache />}>
            Xem thêm
          </Button>
        </div>
      </div>
      <FormSummary
        rightButton={
          <Button
            className="bg-[#3F83F8]"
            prefix={<ShoppingCartSolid />}
            onClick={() => navigate(UNAUTH_DOMAIN_URL.add.path)}
          >
            Mua tên miền đã chọn
          </Button>
        }
      />
    </Fragment>
  );
};

export default SearchResult;
