import { observer } from 'mobx-react-lite';
import { UNAUTH_CLOUD_SERVER_URL } from 'pages/UnAuth/ServiceManagement/CloudServer/url';
import { UNAUTH_CLOUD_VPS_URL } from 'pages/UnAuth/ServiceManagement/CloudVPS/url';
import { UNAUTH_DOMAIN_URL } from 'pages/UnAuth/ServiceManagement/Domain/url';
import { UNAUTH_EMAIL_SECURITY_URL } from 'pages/UnAuth/ServiceManagement/EmailSecurity/url';
import { UNAUTH_HOSTING_URL } from 'pages/UnAuth/ServiceManagement/Hosting/url';
import { UNAUTH_SSL_URL } from 'pages/UnAuth/ServiceManagement/SSL/url';
import { useNavigate } from 'react-router-dom';
import { Button, ChevronDownSolid, Popover } from 'tera-dls';

const services = [
  { name: 'Tên miền', path: UNAUTH_DOMAIN_URL.index.path },
  { name: 'Web Hosting', path: UNAUTH_HOSTING_URL.index.path },
  { name: 'Cloud VPSz', path: UNAUTH_CLOUD_VPS_URL.index.path },
  { name: 'Cloud Server', path: UNAUTH_CLOUD_SERVER_URL.index.path },
  { name: 'Email và Bảo mật', path: UNAUTH_EMAIL_SECURITY_URL.index.path },
  { name: 'Chứng chỉ SSL', path: UNAUTH_SSL_URL.index.path },
];

export interface UserRegisterServiceProps {
  isExpand?: boolean;
}

const UserRegisterService = ({
  isExpand,
}: UserRegisterServiceProps): JSX.Element => {
  const navigate = useNavigate();

  const Content = (): JSX.Element => {
    return (
      <div className={`px-2 ${isExpand ? 'md:ml-[225px]' : 'md:ml-[50px]'}`}>
        <div className="bg-white border rounded-[10px] shadow flex flex-col">
          <div className="text-[16px] font-bold p-4 border-b-2">
            Đăng ký dịch vụ
          </div>
          <div className="grid grid-cols-3">
            {services.map((service, index) => (
              <Button
                key={index}
                className="hover:text-[#3F83F8] bg-transparent hover:bg-transparent px-4 py-3 border-b-[1px] flex justify-start cursor-pointer"
                onClick={() => navigate(service.path)}
              >
                <span className="text-black font-normal">{service.name}</span>
              </Button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Popover
      trigger="click"
      content={<Content />}
      placement="bottom-end"
      showArrow={false}
      className="w-full bg-transparent p-0 shadow-none"
    >
      <div className="flex items-center text-white bg-[#3F83F8] px-3 py-2 rounded-[4px] gap-2 cursor-pointer">
        <span>Đăng ký dịch vụ</span>
        <ChevronDownSolid className="w-4 h-4" />
      </div>
    </Popover>
  );
};

export default observer(UserRegisterService);
