import { IRouteProps } from '_common/interface/router';
import ShoppingCartPage from '.';
import { SHOPPING_CART_URL } from './url';

export const ShoppingCartRouter: IRouteProps[] = [
  {
    key: SHOPPING_CART_URL.cart.key,
    path: SHOPPING_CART_URL.cart.shortenUrl,
    component: <ShoppingCartPage />,
  },
];
