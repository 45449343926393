import { IRouteProps } from '_common/interface/router';
import CloudVPSPage from '.';
import AddCloudVPSPage from './pages/Add';
import DetailVPSPage from './pages/Detail';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import ReinstallOSVPSPage from './pages/ReinstallOS';
import UpgradeVPSPage from './pages/Upgrade';
import UpgradeVPSPaymentPage from './pages/UpgradePayment';
import { CLOUD_VPS_URL } from './url';

export const CloudVPSRouter: IRouteProps[] = [
  {
    key: CLOUD_VPS_URL.list.key,
    path: CLOUD_VPS_URL.list.shortenUrl,
    component: <CloudVPSPage />,
  },
  {
    key: CLOUD_VPS_URL.add.key,
    path: CLOUD_VPS_URL.add.shortenUrl,
    component: <AddCloudVPSPage />,
  },
  {
    key: CLOUD_VPS_URL.paymentInformation.key,
    path: CLOUD_VPS_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: CLOUD_VPS_URL.payment.key,
    path: CLOUD_VPS_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
  {
    key: CLOUD_VPS_URL.detail.key,
    path: CLOUD_VPS_URL.detail.shortenUrl,
    component: <DetailVPSPage />,
  },
  {
    key: CLOUD_VPS_URL.upgrade.key,
    path: CLOUD_VPS_URL.upgrade.shortenUrl,
    component: <UpgradeVPSPage />,
  },
  {
    key: CLOUD_VPS_URL.upgradePayment.key,
    path: CLOUD_VPS_URL.upgradePayment.shortenUrl,
    component: <UpgradeVPSPaymentPage />,
  },
  {
    key: CLOUD_VPS_URL.reinstallOS.key,
    path: CLOUD_VPS_URL.reinstallOS.shortenUrl,
    component: <ReinstallOSVPSPage />,
  },
];
