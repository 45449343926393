import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import InputPassword from '_common/dof/Control/InputPassword';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Col, EyeOutlined, EyeSlashOutlined, Row } from 'tera-dls';

export interface IRegisterValues {
  username: string;
  email: string;
  country: string;
  full_name: string;
  birthday: string;
  province: string;
  name: string;
  country_code: number;
  phone: string;
  district: string;
  nickname: string;
  address: string;
  ward: string;
  id_card: string;
  password: string;
  re_password: string;
}

const FormRegister = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      username: '',
      email: '',
      country: '',
      full_name: '',
      birthday: '',
      province: '',
      name: '',
      country_code: 1,
      phone: '',
      district: '',
      nickname: '',
      address: '',
      ward: '',
      id_card: '',
      password: '',
      re_password: '',
    },
  });

  return (
    <FormTera form={form} className="flex flex-col gap-2">
      <Row className="grid grid-cols-3">
        {/* Username */}
        <Col>
          <FormTeraItem
            label="Tài khoản"
            name="username"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input />
          </FormTeraItem>
        </Col>
        {/* Email */}
        <Col>
          <FormTeraItem
            label="Email"
            name="email"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input />
          </FormTeraItem>
        </Col>
        {/* Country */}
        <Col>
          <FormTeraItem
            label="Quốc gia"
            name="country"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Select options={[]} />
          </FormTeraItem>
        </Col>
      </Row>
      <Row className="grid grid-cols-3">
        {/* Full name */}
        <Col>
          <FormTeraItem
            label="Họ và tên đệm"
            name="full_name"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input />
          </FormTeraItem>
        </Col>
        {/* Birthday */}
        <Col className="flex flex-col">
          <FormTeraItem
            label="Ngày sinh"
            name="birthday"
            className="w-full mb-0"
          >
            <DatePicker />
          </FormTeraItem>
          <i className="text-[12px] mt-2">Định dạng đúng dd/mm/yyyy</i>
        </Col>
        {/* Province */}
        <Col>
          <FormTeraItem
            label="Tỉnh/Thành"
            name="province"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Select options={[]} />
          </FormTeraItem>
        </Col>
      </Row>
      <Row className="grid grid-cols-3">
        {/* Name */}
        <Col>
          <FormTeraItem
            label="Tên"
            name="name"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input />
          </FormTeraItem>
        </Col>
        {/* Phone */}
        <Col>
          <FormTeraItem
            label="Điện thoại"
            name="phone"
            className="w-full mb-0"
            childrenClassName="[&>*:first-child>div]:pl-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input
              maxLength={10}
              className="pl-[4rem]"
              prefix={
                <FormTeraItem name="country_code" className="mb-0">
                  <Select
                    options={[
                      {
                        value: 1,
                        label: '+84',
                        labelDisplay: (
                          <Fragment>Vietnam (Việt Nam) +84</Fragment>
                        ),
                      },
                    ]}
                    placement="bottom-start"
                    className="w-[55px] [&>*:first-child]:h-[32px] [&>*:first-child]:bg-gray-100 [&>*:first-child]:px-2 [&>*:nth-child(2)]:end-1"
                    dropdownClassName="!w-[250px]"
                  />
                </FormTeraItem>
              }
            />
          </FormTeraItem>
        </Col>
        {/* District */}
        <Col>
          <FormTeraItem
            label="Quận/Huyện"
            name="district"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Select options={[]} />
          </FormTeraItem>
        </Col>
      </Row>
      <Row className="grid grid-cols-3">
        {/* Nickname */}
        <Col>
          <FormTeraItem
            label="Danh xưng"
            name="nickname"
            className="w-full mb-0"
          >
            <Input />
          </FormTeraItem>
        </Col>
        {/* Address */}
        <Col>
          <FormTeraItem
            label="Địa chỉ"
            name="address"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Input />
          </FormTeraItem>
        </Col>
        {/* Ward */}
        <Col>
          <FormTeraItem
            label="Phường/Xã"
            name="ward"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <Select options={[]} />
          </FormTeraItem>
        </Col>
      </Row>
      <Row className="grid grid-cols-3">
        {/* ID card/Passport number */}
        <Col>
          <FormTeraItem
            label="Số CCCD/Passport"
            name="id_card"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
            ]}
          >
            <InputNumber />
          </FormTeraItem>
        </Col>
        {/* Password */}
        <Col>
          <FormTeraItem
            label="Mật khẩu"
            name="password"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
              {
                pattern: {
                  value: new RegExp(REGEX.PASSWORD),
                  message: messageValidate.password,
                },
                minLength: {
                  value: 8,
                  message: messageValidate.min_length_password,
                },
                maxLength: {
                  value: 16,
                  message: messageValidate.max_length_password,
                },
              },
            ]}
          >
            <InputPassword
              iconRender={(visible) =>
                visible ? (
                  <EyeSlashOutlined className="w-5 h-5" />
                ) : (
                  <EyeOutlined className="w-5 h-5" />
                )
              }
            />
          </FormTeraItem>
        </Col>
        {/* Re-Password */}
        <Col>
          <FormTeraItem
            label="Nhập lại mật khẩu"
            name="re_password"
            className="w-full mb-0"
            rules={[
              {
                required: messageValidate.emptyText,
              },
              {
                pattern: {
                  value: new RegExp(REGEX.PASSWORD),
                  message: messageValidate.password,
                },
                minLength: {
                  value: 8,
                  message: messageValidate.min_length_password,
                },
                maxLength: {
                  value: 16,
                  message: messageValidate.max_length_password,
                },
                validate: {
                  existed: (value: string, values: IRegisterValues) => {
                    const password = values?.password.trim();

                    if (value?.trim() !== password) {
                      return messageValidate.confirm_password;
                    }
                  },
                },
              },
            ]}
          >
            <InputPassword
              iconRender={(visible) =>
                visible ? (
                  <EyeSlashOutlined className="w-5 h-5" />
                ) : (
                  <EyeOutlined className="w-5 h-5" />
                )
              }
            />
          </FormTeraItem>
        </Col>
      </Row>
    </FormTera>
  );
};

export default FormRegister;
