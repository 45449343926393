import CardDetail from '_common/component/CardDetail';
import { REGEX } from '_common/constants/common';
import { messageValidate } from '_common/constants/message';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Row } from 'tera-dls';

const ChangePassword = () => {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    values;
  };

  useEffect(() => {
    form.setValue('is_active', true);
  }, []);

  return (
    <div className="flex flex-col gap-[16px] pb-2.5">
      <CardDetail className="text-sm">
        <FormTera form={form}>
          <Row className="grid-cols-1 sm:grid-cols-2 xmd:grid-cols-3">
            <FormTeraItem
              label="Mật khẩu cũ"
              name="old_password"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.PASSWORD),
                    message: messageValidate.password,
                  },
                  minLength: {
                    value: 8,
                    message: messageValidate.min_length_password,
                  },
                  maxLength: {
                    value: 16,
                    message: messageValidate.max_length_password,
                  },
                },
              ]}
            >
              <InputPassword />
            </FormTeraItem>
            <FormTeraItem
              label="Mật khẩu mới"
              name="new_password"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.PASSWORD),
                    message: messageValidate.password,
                  },
                  minLength: {
                    value: 8,
                    message: messageValidate.min_length_password,
                  },
                  maxLength: {
                    value: 16,
                    message: messageValidate.max_length_password,
                  },
                },
              ]}
            >
              <InputPassword />
            </FormTeraItem>
            <FormTeraItem
              label="Nhập lại mật khẩu"
              name="confirm_password"
              rules={[
                {
                  required: messageValidate.emptyText,
                  pattern: {
                    value: new RegExp(REGEX.PASSWORD),
                    message: messageValidate.password,
                  },
                  minLength: {
                    value: 8,
                    message: messageValidate.min_length_password,
                  },
                  maxLength: {
                    value: 16,
                    message: messageValidate.max_length_password,
                  },
                  validate: {
                    existed: (value, values) => {
                      const password = values?.new_password.trim();

                      if (value?.trim() !== password) {
                        return messageValidate.confirm_password;
                      }
                    },
                  },
                },
              ]}
            >
              <InputPassword />
            </FormTeraItem>
          </Row>
        </FormTera>
      </CardDetail>
      <span>
        <Button
          onClick={form.handleSubmit(handleSubmitForm)}
          className="px-12 font-light text-[12px] bg-blue-500"
        >
          Thay đổi mật khẩu
        </Button>
      </span>
    </div>
  );
};

export default ChangePassword;
