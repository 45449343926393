import { IRouteProps } from '_common/interface/router';
import DomainPage from '.';
import AddDomainPage from './pages/Add';
import DetailDomainPage from './pages/Detail';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import SearchDomainPage from './pages/Search';
import { DOMAIN_URL } from './url';

export const DomainRouter: IRouteProps[] = [
  {
    key: DOMAIN_URL.list.key,
    path: DOMAIN_URL.list.shortenUrl,
    component: <DomainPage />,
  },
  {
    key: DOMAIN_URL.search.key,
    path: DOMAIN_URL.search.shortenUrl,
    component: <SearchDomainPage />,
  },
  {
    key: DOMAIN_URL.add.key,
    path: DOMAIN_URL.add.shortenUrl,
    component: <AddDomainPage />,
  },
  {
    key: DOMAIN_URL.paymentInformation.key,
    path: DOMAIN_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: DOMAIN_URL.payment.key,
    path: DOMAIN_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
  {
    key: DOMAIN_URL.detail.key,
    path: DOMAIN_URL.detail.shortenUrl,
    component: <DetailDomainPage />,
  },
];
