import BreadcrumbTag from '_common/component/BreadcrumbTag';
import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import FormTera from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  ShoppingCartSolid,
} from 'tera-dls';
import FormSummary from '../../components/FormSummary';
import { DOMAIN_URL } from '../../url';
import FormAdd from './components/FormAdd';

const AddDomainPage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      domains: [{ id: 1, name: 'tenmien.io.vn', duration: 1 }],
      nameservers: [{ nameserver: '' }, { nameserver: '' }],
    },
  });
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Tên miền
        </span>
      ),
      onClick: () => navigate(DOMAIN_URL.list.path),
    },
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Thêm Tên miền
        </span>
      ),
      onClick: () => navigate(DOMAIN_URL.search.path),
    },
    {
      title: 'Cấu hình Tên miền',
    },
  ];

  const itemTags = [
    {
      title: (
        <IconText prefix={<ZingIcon.IconWorldWide className="w-4 h-4" />}>
          Tên miền
        </IconText>
      ),
      onClick: () => navigate(DOMAIN_URL.list.path),
    },
    {
      title: (
        <IconText prefix={<ZingIcon.IconVPS />}>Cấu hình Tên miền</IconText>
      ),
      onClick: () => navigate(DOMAIN_URL.add.path),
    },
    {
      title: (
        <IconText prefix={<ShoppingCartSolid className="w-4 h-4" />}>
          Thông tin đơn hàng
        </IconText>
      ),
    },
  ];

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
        <div className="pb-4">
          <BreadcrumbTag items={itemTags} className="px-2" />
        </div>
      </div>
      <div className="flex-1 px-2 mt-[1rem] flex flex-col">
        <FormTera form={form} className="flex flex-col gap-4">
          <FormAdd form={form} />
        </FormTera>
        <FormSummary
          rightButton={
            <Button
              className="bg-[#3F83F8]"
              prefix={<ShoppingCartSolid />}
              onClick={() => navigate(DOMAIN_URL.paymentInformation.path)}
            >
              Tiếp tục
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default AddDomainPage;
