import { useLocation, useNavigate } from 'react-router-dom';
import {
  formatCurrency,
  getQueryParams,
  Spin,
  Tabs,
  updateQueryParams,
} from 'tera-dls';
import Estimate from './containers/Estimate';
import InvoiceDetail from './containers/InvoiceDetail';
import Recharge from './containers/Recharge';

const InvoicePage = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };
  const tabItems = [
    {
      key: 'invoice',
      label: 'Hóa đơn',
    },
    {
      key: 'recharge',
      label: 'Nạp tiền',
    },
    {
      key: 'estimates',
      label: 'Estimates',
    },
  ];

  const tabData = {
    invoice: <InvoiceDetail />,
    recharge: <Recharge />,
    estimates: <Estimate />,
  };
  return (
    <Spin spinning={false}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div className="text-gray-700 font-semibold text-base uppercase">
                DANH SÁCH HÓA ĐƠN
              </div>
            </div>
            <div className="page-header-v2__function shrink-0">
              {/* <Button onClick={form.handleSubmit(handleSubmitForm)}>Lưu</Button> */}
            </div>
          </div>
        </div>
        <div className="page-content-v2 bg-white shadow-xsm rounded-md !px-[16px] h-full mx-2.5 !mb-2.5 !gap-y-0">
          <div className="mb-2 rounded-md [box-shadow:0px_1px_5px_0px_#0000001A,0px_-1px_5px_0px_#0000001A] bg-white p-2.5 flex flex-col gap-2.5">
            <span className="text-green-500 text-2xl">{formatCurrency(0)}</span>
            <span className="text-gray-500">Tín dụng</span>
          </div>
          <Tabs
            onChange={(tab) => handleUpdateQueryParams({ tab })}
            items={tabItems}
            activeKey={queryParams?.tab || 'invoice'}
            className="mb-[16px] pt-0 bg-white rounded-t-md"
          />
          <div className="">
            {tabData[queryParams?.tab || 'invoice'] ?? <InvoiceDetail />}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default InvoicePage;
