import { IRouteProps } from '_common/interface/router';
import HostingPage from '.';
import AddHostingPage from './pages/Add';
import DetailHostingPage from './pages/Detail';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import { HOSTING_URL } from './url';

export const HostingRouter: IRouteProps[] = [
  {
    key: HOSTING_URL.list.key,
    path: HOSTING_URL.list.shortenUrl,
    component: <HostingPage />,
  },
  {
    key: HOSTING_URL.add.key,
    path: HOSTING_URL.add.shortenUrl,
    component: <AddHostingPage />,
  },
  {
    key: HOSTING_URL.paymentInformation.key,
    path: HOSTING_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: HOSTING_URL.payment.key,
    path: HOSTING_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
  {
    key: HOSTING_URL.detail.key,
    path: HOSTING_URL.detail.shortenUrl,
    component: <DetailHostingPage />,
  },
];
