import { useMutation } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { forwardRef, useImperativeHandle, useState } from 'react';
import OtpInput from 'react-otp-input';
import TagInfo from './TagInfo';
import WalletApi from '../../api';
import customTwMerge from 'tailwind-merge.config';
import useCountDown from '_common/hooks/useCountDown';
const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  gap: 16,
  marginBottom: 10,
};
const inputStyle = {
  width: 70,
  height: 120,
  background: '#fff',
  border: '1px solid #E5E7EB',
  fontSize: '60px',
  borderRadius: '10px',
};
function OTP(prop, ref) {
  const {
    authStore: { user },
  } = useStores();

  const [messageError, setMessageError] = useState<string | null>(null);
  const {
    transactionStore: {
      otp,
      account,
      nextStep,
      setOtp,
      sessionId,
      setSessionId,
    },
  } = useStores();

  const { mutate: mutateVerify } = useMutation(
    (variables: any) => WalletApi.verifyOtp(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          nextStep();
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
        setMessageError(error?.data);
        nextStep();
      },
    },
  );

  const { mutate: mutateSendMail } = useMutation(() => WalletApi.sendMail(), {
    onSuccess: (res) => {
      if (res?.code === 200) {
        setSessionId(res?.data?.session_id);
      }
    },
    onError(error: any) {
      ErrorToast({ errorProp: error?.data });
    },
  });

  const { time, resetCountdown } = useCountDown({ initialTime: 120 });
  const formattedTime = moment.utc(time * 1000).format('mm:ss');

  useImperativeHandle(ref, () => {
    return {
      nextStep: () => {
        // otp &&
        //   sessionId &&
        mutateVerify({ params: { session_id: sessionId, otp } });
      },
    };
  });

  const handleResendOtp = () => {
    if (time > 60) return;
    resetCountdown();
    mutateSendMail();
  };

  return (
    <div className="flex flex-col gap-y-4">
      <p>Đến</p>
      <TagInfo
        cardName={account?.cardName}
        name={account?.cardholder}
        number={account?.number}
      />
      <p>Nhập mã xác thực</p>
      <div className="flex flex-col gap-y-[30px]">
        <p>
          Chúng tôi đã gửi cho bạn 1 mã xác thực gồm 6 chữ số vào email{' '}
          <span className="text-blue-500">{user?.email}</span>. Mã xác thực có
          sẽ hết hạn trong 2 phút. Liên hệ với chúng tôi nếu bạn có gặp vấn đề
          rắc rối nào.
        </p>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderInput={(props, index) => {
            return (
              <input
                key={index}
                {...props}
                className={`${
                  messageError ? '!border-[1px] !border-red-500' : ''
                } tera-no-control`}
              />
            );
          }}
          inputType="number"
          containerStyle={containerStyle}
          inputStyle={inputStyle}
          shouldAutoFocus
        />
        <p className="text-center">
          Thời gian còn lại: <span>{formattedTime}</span>
        </p>
        <p className="text-center">
          Chưa nhận được mã?{' '}
          <span
            className={customTwMerge(
              'font-medium text-blue-600',
              time > 60 && '!text-gray-500',
              time < 60 && '!cursor-pointer',
            )}
            onClick={handleResendOtp}
          >
            Gửi lại
          </span>
        </p>
      </div>
    </div>
  );
}

export default observer(forwardRef(OTP));
