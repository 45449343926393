export const EMAIL_SECURITY_URL = {
  list: {
    key: '',
    path: '/portal/service/email-security/list',
    shortenUrl: 'email-security/list',
  },
  add: {
    key: '',
    path: '/portal/service/email-security/add',
    shortenUrl: 'email-security/add',
  },
  paymentInformation: {
    key: '',
    path: '/portal/service/email-security/payment-information',
    shortenUrl: 'email-security/payment-information',
  },
  payment: {
    key: '',
    path: '/portal/service/email-security/payment',
    shortenUrl: 'email-security/payment',
  },
  detail: {
    key: '',
    path: '/portal/service/email-security/detail',
    shortenUrl: 'email-security/detail/:id',
  },
};
