import CopyClipboardText from '_common/component/CopyClipboardText';
import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FlagVN from 'styles/images/Icons/FlagVN.svg';
import {
  Button,
  ChevronDoubleRightSolid,
  CloudArrowUpSolid,
  ColumnsType,
  Tag,
  formatCurrency,
} from 'tera-dls';
import { VPS_STATUS, VPS_STATUS_COLOR } from '../constants';
import ModalExtend from '../modals/ModalExtend';
import ModalNote from '../modals/ModalNote';
import { CLOUD_VPS_URL } from '../url';

const Table = (props: ITeraTableProps): JSX.Element => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const confirm = useConfirm();

  const [isOpenModalExtend, setIsOpenModalExtend] = useState(false);
  const [isOpenModalNote, setIsOpenModalNote] = useState(false);

  const navigate = useNavigate();

  const columns: ColumnsType<any> = [
    {
      title: 'IP',
      dataIndex: 'investor_name',
      render: (location) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconLocation className="w-4 h-4" color="#3f83f8" />
          {location ? location : '103.250.78.65'}
        </div>
      ),
    },
    {
      title: 'Vị trí',
      dataIndex: 'location',
      render: (location) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconSquareBox className="w-4 h-4" color="#3f83f8" />
          {location ? location : '103.250.78.65'}
        </div>
      ),
    },
    {
      title: 'Tên',
      dataIndex: 'planCode',
      render: (location) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconEarth className="w-4 h-4" color="#3f83f8" />
          {location ? location : '103.250.78.65'}
          <img
            src={FlagVN}
            alt="name_flag"
            className="w-4 h-4 rounded-[1rem] object-cover"
          />
        </div>
      ),
    },
    {
      title: 'OS',
      dataIndex: 'email',
      render: (location) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconWindows className="w-4 h-4" color="#3f83f8" />
          {location ? location : '103.250.78.65'}
        </div>
      ),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      render: (created_at) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconCalendarCheck className="w-4 h-4" color="#3f83f8" />
          {created_at ? created_at : 'dd/mm/yyyy'}
        </div>
      ),
    },
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expirated_at',
      render: (expirated_at) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconCalendarEmpty className="w-4 h-4" color="#3f83f8" />
          {expirated_at ? expirated_at : 'dd/mm/yyyy'}
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <div className="flex items-center gap-2">
          <ZingIcon.IconCompass className="w-4 h-4" color="#3f83f8" />
          <Tag
            color={
              !props.queryParams.status || props.queryParams.status === 'all'
                ? status !== undefined
                  ? VPS_STATUS_COLOR[status]
                  : VPS_STATUS_COLOR[1]
                : VPS_STATUS_COLOR[props.queryParams.status]
            }
            className="font-[500]"
          >
            {!props.queryParams.status || props.queryParams.status === 'all'
              ? status !== undefined
                ? VPS_STATUS[status]
                : VPS_STATUS[1]
              : VPS_STATUS[props.queryParams.status]}
          </Tag>
        </div>
      ),
    },
    {
      fixed: 'right',
      width: 150,
      render: (record) => {
        return (
          <Button
            className="bg-green-500 p-2"
            prefix={<ZingIcon.IconSettings />}
            onClick={() =>
              setExpandedRowKeys(
                expandedRowKeys.includes(record.id)
                  ? expandedRowKeys.filter((k) => k !== record.id)
                  : [...expandedRowKeys, record.id],
              )
            }
          >
            Cloud Control
          </Button>
        );
      },
    },
  ];

  const CloudVPSExpand = ({ record }) => {
    return (
      <div className="bg-[#F9FAFB] p-4 flex flex-col gap-2">
        <IconText prefix={<ZingIcon.IconVPS color="#0E9F6E" />}>
          <span className="font-bold">Cấu hình:</span>
        </IconText>
        <div className="flex gap-4">
          <IconText prefix={<ZingIcon.IconRam color="#3f83f8" />}>
            <span className="font-[500]">16</span> GB RAM
          </IconText>
          <IconText prefix={<ZingIcon.IconCPU color="#3f83f8" />}>
            <span className="font-[500]">8</span> vCPU
          </IconText>
          <IconText prefix={<ZingIcon.IconCPUStorage color="#3f83f8" />}>
            <span className="font-[500]">90</span> GB SSD
          </IconText>
          <IconText prefix={<ZingIcon.IconNetwork color="#3f83f8" />}>
            <span className="font-[500]">800</span> Mbps
          </IconText>
          <IconText prefix={<ZingIcon.IconWifiVertical color="#3f83f8" />}>
            <span className="font-[500]">Unlimited</span> Mbps
          </IconText>
          <IconText prefix={<ZingIcon.IconDollarCard />}>
            Đơn giá
            <span className="text-[#e02424] font-[500]">
              {formatCurrency(1075000)}
            </span>
            / tháng
          </IconText>
        </div>
        <IconText prefix={<ZingIcon.IconPersonShield />}>
          <span className="font-bold">Credentials:</span>
        </IconText>
        <div className="flex gap-4">
          <IconText prefix={<ZingIcon.IconLocationSolid color="#3f83f8" />}>
            <span className="text-blue-500">IP Port:</span>
            <CopyClipboardText valueCopy="103.250.78.65:22">
              <u className="text-black font-[500]">103.250.78.65:22</u>
            </CopyClipboardText>
          </IconText>
          <IconText prefix={<ZingIcon.IconShield />}>
            <span className="text-blue-500">Username:</span>
            <CopyClipboardText valueCopy="root">
              <u className="text-black font-[500]">root</u>
            </CopyClipboardText>
          </IconText>
          <IconText prefix={<ZingIcon.IconKey />}>
            <span className="text-blue-500">Password:</span>
            <CopyClipboardText valueCopy="S!D4@!8ZU4pht">
              <u className="text-black font-[500]">S!D4@!8ZU4pht</u>
            </CopyClipboardText>
          </IconText>
        </div>
        <IconText prefix={<ZingIcon.IconSettings color="#0e9f6e" />}>
          <span className="font-bold">Control Panel:</span>
        </IconText>
        <div className="flex gap-4">
          <Button
            className="w-[165px] bg-blue-500"
            prefix={<ZingIcon.IconArrowRestart />}
            onClick={() =>
              confirm.warning({
                title: 'Xác nhận restart VPS',
                content: (
                  <>
                    <p>Bạn có chắc muốn xác nhận restart VPS</p>
                    <p>
                      <span className="font-bold">Tên VPS</span> này không?
                    </p>
                  </>
                ),
              })
            }
          >
            Restart
          </Button>
          <Button
            className="w-[165px] bg-red-500"
            prefix={<ZingIcon.IconLocationSolid color="" />}
            onClick={() =>
              navigate(`${CLOUD_VPS_URL.detail.path}/${record?.id}`)
            }
          >
            Thay đổi IP
          </Button>
          <Button
            className="w-[165px] bg-green-500"
            prefix={<ZingIcon.IconSquarePlus />}
            onClick={() => setIsOpenModalExtend(true)}
          >
            Gia hạn
          </Button>
          <Button
            className="w-[165px] bg-yellow-500"
            prefix={<CloudArrowUpSolid />}
            onClick={() =>
              navigate(`${CLOUD_VPS_URL.upgrade.path}/${record?.id}`)
            }
          >
            Nâng cấp
          </Button>
          <Button
            className="w-[165px] bg-indigo-500"
            prefix={<ZingIcon.IconRecycle />}
            onClick={() =>
              navigate(`${CLOUD_VPS_URL.reinstallOS.path}/${record?.id}`)
            }
          >
            Hệ điều hành
          </Button>
          <Button
            className="w-[165px] bg-gray-500"
            prefix={<ZingIcon.IconTools />}
            onClick={() =>
              confirm.warning({
                title: 'Xác nhận khắc phục sự cố VPS',
                content: (
                  <>
                    <p>Bạn có chắc muốn xác nhận khắc phục sự cố VPS</p>
                    <p>
                      <span className="font-bold">Tên VPS</span> này không?
                    </p>
                  </>
                ),
              })
            }
          >
            Khắc phục sự cố
          </Button>
          <Button
            className="w-[165px] bg-purple-500"
            prefix={<ChevronDoubleRightSolid />}
            onClick={() =>
              navigate(`${CLOUD_VPS_URL.detail.path}/${record?.id}`)
            }
          >
            Thông tin chi tiết
          </Button>
          <Button
            className="w-[165px] bg-blue-500"
            prefix={<ZingIcon.IconNote />}
            onClick={() => setIsOpenModalNote(true)}
          >
            Ghi chú
          </Button>
          <Button
            className="w-[200px] bg-red-500"
            prefix={<ZingIcon.IconClose />}
            onClick={() =>
              confirm.warning({
                title: 'Xác nhận yêu cầu hủy dịch vụ',
                content: (
                  <>
                    <p>Bạn có chắc muốn xác nhận yêu cầu hủy dịch vụ</p>
                    <p>
                      <span className="font-bold">Mã dịch vụ</span> này không?
                    </p>
                  </>
                ),
              })
            }
          >
            Yêu cầu hủy dịch vụ
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <TableTera
        rowKey="id"
        columns={columns}
        loading={props?.loading}
        expandable={{
          expandedRowKeys,
          expandIconColumnIndex: -1,
          expandedRowRender: (record) => <CloudVPSExpand record={record} />,
        }}
        className="[&_.tera-table-expanded-row-fixed]:px-0 [&_.tera-table-cell]:align-middle"
        rowClassName={(_, index) =>
          index % 2 === 0 ? '!bg-white' : '!bg-[#f9fafb]'
        }
        {...props}
      />
      {isOpenModalExtend && (
        <ModalExtend
          open={isOpenModalExtend}
          close={() => setIsOpenModalExtend(false)}
        />
      )}
      {isOpenModalNote && (
        <ModalNote
          open={isOpenModalNote}
          close={() => setIsOpenModalNote(false)}
        />
      )}
    </Fragment>
  );
};

export default Table;
