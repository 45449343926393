import TableTera from '_common/dof/TableTera';
import { useState } from 'react';
import { Button, PlusOutlined, TrashSolid } from 'tera-dls';

const SSHKeys = () => {
  const [dataSource, setDataSource] = useState<any>([
    {
      id: 1,
      key: 'ssh-rsa 2049 cb:a6:h5:j7:f9:39:r8:f1:o0',
      content: 'Content',
    },
    {
      id: 2,
      key: 'ssh-rsa 2049 cb:a6:h5:j7:f9:39:r8:f1:o0',
      content: 'Content',
    },
  ]);

  const columns: any = [
    {
      title: 'Tên SSH Key',
      dataIndex: 'key',
      width: 200,
    },
    {
      title: 'SSH key in OpenSSH format',
      dataIndex: 'content',
      width: 200,
    },
    {
      title: '',
      dataIndex: 'action',
      width: 50,
      fixed: 'right',
      render: (_, record) => (
        <div className="flex justify-end p-1.5">
          <TrashSolid
            className="size-4 text-red-500 cursor-pointer "
            onClick={() =>
              setDataSource((prev) => prev.filter((i) => i.id !== record?.id))
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="flex justify-end">
        <Button className="font-light">
          <PlusOutlined className="size-4" />
          <span>Thêm mới SSH Key</span>
        </Button>
      </div>
      <TableTera className="center-table" data={dataSource} columns={columns} />
    </div>
  );
};

export default SSHKeys;
