import { useQuery } from '@tanstack/react-query';
import { Button, ExclamationTriangleOutlined, Modal, Spin } from 'tera-dls';
import WalletApi from '../../api';
import PaymentMethodApi from '../../api/payment';
import TransactionContent from './TransactionContent';

function TransactionModal({ type, id, open, onClose }) {
  const transactionDetail = useQuery(
    ['get-transaction-detail', id],
    () => PaymentMethodApi.transactionDetail({ id: id }),
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const imageQR = useQuery(
    ['get-image-qr', transactionDetail],
    () => {
      const params = {
        card_id: transactionDetail?.data?.card_id,
        transaction_id: transactionDetail?.data?.id,
      };
      return WalletApi.getQr({ params });
    },
    {
      enabled: !!transactionDetail,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );
  return (
    <Modal
      closeIcon={false}
      open={open}
      title={'Chi tiết giao dịch'}
      width={630}
      footer={
        <div className="flex w-full justify-center">
          <Button onClick={onClose}>Đóng</Button>
        </div>
      }
    >
      <Spin spinning={false}>
        <div className="flex flex-col items-center gap-y-2.5">
          <TransactionContent
            {...{
              // type: TransactionTypeStatistic[
              //   transactionDetail?.data?.transaction_type
              // ] as TransactionContentType,
              type: 'withdraw',
              amount: transactionDetail.data?.amount,
              transaction_date: transactionDetail.data?.transaction_date,
              transaction_code: transactionDetail.data?.transaction_code,
              invoice_code: transactionDetail.data?.bill?.bill?.code,
              payment_method: transactionDetail.data?.methods,
              account_number: '2344342332',
              cardholder: 'Nguyễn Cao Kỳ Duyên',
              card_type: '1',
              card_name: 'ACB',
            }}
          />
          {type === 'recharge' && (
            <div className="flex justify-center">
              {imageQR.isLoading ? (
                <Spin />
              ) : imageQR.data?.code === '00' ? (
                <img
                  src={imageQR?.data?.data?.qrDataURL}
                  alt="qr-recharge"
                  className="w-2/3"
                />
              ) : (
                <div className="flex items-center gap-x-1">
                  <ExclamationTriangleOutlined className="w-10 h-10 text-red-500" />
                  <span>Lỗi hiển thị mã QR</span>
                </div>
              )}
            </div>
          )}
        </div>
      </Spin>
    </Modal>
  );
}

export default TransactionModal;
