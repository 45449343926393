import CardDetail from '_common/component/CardDetail';
import DefaultImage from '_common/component/DefaultImage';
import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import {
  ArrowDownOutlined,
  Button,
  Col,
  formatCurrency,
  formatNumber,
  Progress,
  Row,
} from 'tera-dls';

const Overview = () => {
  const columns1: any = [
    {
      title: 'Tên dự án',
      dataIndex: 'name',
      width: 150,
    },
    {
      title: 'Tỉ lệ hòa vốn hàng ngày',
      dataIndex: 'percentage',
      width: 180,
      render: (val) => <span>{val}%</span>,
    },
    {
      title: 'Số tiền đầu tư',
      dataIndex: 'amount',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Tiền lãi đã trả',
      dataIndex: '12222222223',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Tiền lãi chưa trả',
      dataIndex: '22222233333',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 150,
    },
    {
      title: 'Kiểm tra',
      dataIndex: 'check',
      width: 150,
    },
    {
      title: 'Hợp đồng',
      dataIndex: 'contract',
      fixed: 'right',
      width: 130,
      render: () => (
        <Button className="flex gap-1 bg-blue-500 font-light text-[13px]">
          <ArrowDownOutlined className="size-4" />
          <span>Tải xuống</span>
        </Button>
      ),
    },
  ];

  const columns2: any = [
    {
      title: 'Ngày nhận quyền lợi',
      dataIndex: 'time',
      width: 150,
      render: (val) => <div className="py-1.5">{val}</div>,
    },
    {
      title: 'Tiền bạc',
      dataIndex: 'amount',
      width: 150,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Dự án',
      dataIndex: 'project',
      width: 150,
    },
  ];

  const columns3: any = [
    {
      title: 'Loại thông tin',
      dataIndex: 'information',
      width: 150,
      render: (val) => <div className="py-1.5">{val}</div>,
    },
    {
      title: 'Dữ liệu',
      dataIndex: 'data',
      width: 150,
    },
    {
      title: 'Purpose',
      dataIndex: 'purpose',
      width: 150,
    },
  ];

  return (
    <div className="flex flex-col gap-[16px]">
      <CardDetail title="Tài khoản người dùng" className="px-5">
        <div className="flex items-center flex-col xmd:flex-row gap-[30px]">
          <div className="flex flex-col gap-2 items-center ml-2.5">
            <DefaultImage
              src="https://static.artzone.ai/media/53335/conversions/QGUEaajd4NxjZ7gQbeNWxqNlj5O8d62eI5fLZzd3-w768.webp"
              alt=""
              className="size-[100px] rounded-full"
            />
            <Col className="col-span-1 flex-col gap-[16px]  flex xmd:hidden">
              <div className="font-medium  text-[20px] mt-2 leading-[24px] text-center">
                hamy
              </div>
              <div className="flex gap-2 items-center sm:hidden">
                <span className="shrink-0">Cấp độ bảo mật:</span>
                <span className="w-[200px]">
                  <Progress percent={70} size={10} />
                </span>
              </div>
            </Col>
          </div>

          <div className="flex-1 flex items-center ">
            <Row className="grid grid-cols-2 sm:grid-cols-3 w-full ">
              <Col className="col-span-1 flex-col gap-[16px] hidden sm:flex">
                <div className="font-medium hidden xmd:block text-[20px] leading-[24px]">
                  hamy
                </div>
                <div className="flex gap-2 items-center">
                  <span className="shrink-0">Cấp độ bảo mật:</span>
                  <span className="w-[200px]">
                    <Progress percent={70} size={10} />
                  </span>
                </div>
              </Col>
              <Col className="flex gap-1.5 col-span-1 items-end justify-center">
                Số dư khả dụng:{' '}
                <span className="text-red-500">
                  {formatCurrency(171133002)}
                </span>
              </Col>
              <Col className="flex gap-1.5 col-span-1 items-end justify-end">
                Tổng tài khoản:{' '}
                <span className="text-red-500">
                  {formatCurrency(171133002)}
                </span>
              </Col>
            </Row>
          </div>
        </div>
      </CardDetail>
      <CardDetail>
        <Row className="grid grid-cols-2 md:grid-cols-5 gap-5">
          <Col className="col-span-1 flex flex-col gap-[16px] items-center">
            <span className="text-gray-500">Số lợi nhuận chờ nhận</span>
            <span className="font-medium text-gray-700">
              {formatNumber(290000000)}
            </span>
          </Col>
          <Col className="col-span-1 flex flex-col gap-[16px] items-center">
            <span className="text-gray-500">Lãi tích lũy</span>
            <span className="font-medium text-gray-700">
              {formatCurrency(180000000)}
            </span>
          </Col>
          <Col className="col-span-1 flex flex-col gap-[16px] items-center">
            <span className="text-gray-500">Số tiền gốc chờ nhận</span>
            <span className="font-medium text-gray-700">9</span>
          </Col>
          <Col className="col-span-1 flex flex-col gap-[16px] items-center">
            <span className="text-gray-500">Số tiền rút</span>
            <span className="font-medium text-gray-700">9</span>
          </Col>
          <Col className="col-span-1 flex flex-col gap-[16px] items-center">
            <span className="text-gray-500">Tổng Số tiền rút</span>
            <span className="font-medium text-gray-700">
              {formatCurrency(10000000)}
            </span>
          </Col>
        </Row>
        <Row className="flex flex-col divide-y-[1px] divide-dotted">
          <CardDetail title="Đầu từ gần đây" className="!shadow-none">
            <TableTera
              className="center-table"
              data={[
                {
                  name: 'Khu đô thị Mông hóa',
                  percentage: 4.34,
                  amount: 1800000000,
                  '1': 78120000,
                  '2': 0,
                  status: 'Định cư',
                  check: 'Kiểm tra',
                },
              ]}
              columns={columns1}
            />
          </CardDetail>
          <CardDetail
            title="Thu nhập gần đây"
            className="!shadow-none pt-5 pb-0"
          >
            <TableTera
              className="center-table"
              data={[
                {
                  time: moment().format(DATE_FORMAT),
                  amount: 1800000000,
                  project: 'Khu đô thị Mông Hóa',
                },
              ]}
              columns={columns2}
            />
          </CardDetail>
        </Row>
      </CardDetail>
      <TableTera
        wrapperClassName="[box-shadow:0px_1px_7px_0px_#0000001A,0px_-1px_7px_0px_#0000001A] overflow-hidden rounded-[10px]"
        className="center-table"
        data={[
          {
            information: 'Loại',
            data: 'Cá nhân',
            purpose: 'Hóa đơn',
          },
          {
            information: 'Danh xưng',
            data: 'Chị',
            purpose: '',
          },
          {
            information: 'Họ và tên đệm',
            data: 'Nguyễn Thị',
            purpose: 'Hóa đơn',
          },
          {
            information: 'Tên',
            data: 'Hà My',
            purpose: 'Hóa đơn',
          },
          {
            information: 'Số CCCD/Passport',
            data: '55534535645',
            purpose: '',
          },
          {
            information: 'Email',
            data: '@email',
            purpose: 'Hóa đơn',
          },
          {
            information: 'Ngày sinh',
            data: 'dd/mm/yyyy',
            purpose: 'Hóa đơn',
          },
          {
            information: 'Điện thoại',
            data: '0909123456',
            purpose: 'Hóa đơn',
          },
          {
            information: 'Quốc gia',
            data: 'Việt Nam',
            purpose: 'Hóa đơn',
          },
          {
            information: 'Tỉnh/Thành',
            data: 'Hồ Chí Minh',
            purpose: 'Hóa đơn',
          },
        ]}
        columns={columns3}
      />
      <div className="flex flex-col gap-2.5">
        <p className="text-[#6B7280] text-[14px] leading-[16px] tracking-[0.3px]">
          Bạn có thể tải thông tin xuống bằng cách click nút phía dưới
        </p>
        <span>
          <Button className="flex gap-1 bg-blue-500 font-light text-[13px]">
            <ArrowDownOutlined className="size-4" />
            <span>Tải xuống</span>
          </Button>
        </span>
      </div>
    </div>
  );
};

export default Overview;
