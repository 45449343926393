import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  formatCurrency,
  FunnelOutlined,
  getQueryParams,
  Row,
  updateQueryParams,
} from 'tera-dls';
import ButtonWallet from './ButtonWallet';
import RechargeFilter from './containers/Filter';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { INVOICE_URL } from '../Invoice/url';
import RechargeModel from './containers/RechargeModel';

const RechargePage = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openRechargeModel, setOpenRechargeModel] = useState<any>(false);
  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams(values);
  };

  const renderItemActions = (item: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    item;
    dropdownItems.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        navigate(`${INVOICE_URL.detail.path}/1`);
      },
    });

    return dropdownItems;
  };

  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'date',
      width: 200,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: 'Số tiền nap',
      dataIndex: 'amount',
      width: 200,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      width: 200,
    },
    {
      dataIndex: 'action',
      title: '',
      width: 50,
      render: (_, record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2 title="Nạp tiền">
          <div className="p-[16px] flex flex-col gap-2.5">
            <Row className="flex justify-between">
              <p>
                Nạp tiền để sử dụng trong tương lai, cho các đơn đặt hàng tiếp
                theo hoặc tự động gia hạn các hoá đơn.
              </p>
              <span>
                <ButtonWallet
                  title="Nạp tiền"
                  typeBtn="recharge"
                  onClick={() => setOpenRechargeModel(true)}
                />
              </span>
            </Row>
            <div className="flex justify-end flex-col shadow rounded-md py-2 gap-2">
              <div className="flex flex-col px-2 justify-end items-end gap-2">
                <div className="flex gap-2.5">
                  <Button
                    type="alternative"
                    className="rounded-xsm py-2 px-2  bg-blue-50"
                    onClick={() => setOpenFilter(true)}
                  >
                    <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0 size-4" />
                  </Button>
                </div>
              </div>
              <TableTera
                columns={columns}
                className="center-table"
                wrapperClassName="!shadow-none"
                pagination={{}}
                data={[
                  {
                    id: 1,
                    date: moment().format(),
                    amount: 50000,
                    description: 'Ghi chú',
                  },
                  {
                    id: 2,
                    date: moment().format(),
                    amount: 50000,
                    description: 'Ghi chú',
                  },
                  {
                    id: 3,
                    date: moment().format(),
                    amount: 50000,
                    description: 'Ghi chú',
                  },
                ]}
              />
            </div>
          </div>
        </HeaderViewListV2>
      </div>
      {openFilter && (
        <RechargeFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValues={queryParams}
          onFilter={handleFilter}
        />
      )}
      {openRechargeModel && (
        <RechargeModel
          open={openRechargeModel}
          onClose={() => setOpenRechargeModel(false)}
        />
      )}
    </>
  );
};

export default RechargePage;
