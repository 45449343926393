import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import useConfirm from '_common/hooks/useConfirm';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  Button,
  ChevronDoubleRightSolid,
  CloudArrowUpSolid,
  CreditCardSolid,
} from 'tera-dls';
import ModalChangeIP from '../../modals/ModalChangeIP';
import ModalExtend from '../../modals/ModalExtend';
import ModalNote from '../../modals/ModalNote';
import { CLOUD_VPS_URL } from '../../url';
import LoginInformation from './components/LoginInformation';
import Overview from './components/Overview';

const DetailVPSPage = (): JSX.Element => {
  const { id } = useParams();
  const confirm = useConfirm();

  const [activeTab, setActiveTab] = useState(1);
  const [isOpenModalChangeIP, setIsOpenModalChangeIP] = useState(false);
  const [isOpenModalExtend, setIsOpenModalExtend] = useState(false);
  const [isOpenModalNote, setIsOpenModalNote] = useState(false);

  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () => navigate(CLOUD_VPS_URL.list.path),
    },
    {
      title: 'Chi tiết Cloud VPS',
    },
  ];

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <div className="px-2">
        <div className="bg-white p-2 rounded-[10px] flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <IconText prefix={<ZingIcon.IconSettings color="#0e9f6e" />}>
              <span className="font-bold">Control Panel:</span>
            </IconText>
            <div className="flex flex-wrap gap-3">
              <Button
                className="w-[191px] bg-blue-500"
                prefix={<ZingIcon.IconArrowRestart />}
                onClick={() =>
                  confirm.warning({
                    title: 'Xác nhận restart VPS',
                    content: (
                      <>
                        <p>Bạn có chắc muốn xác nhận restart VPS</p>
                        <p>
                          <span className="font-bold">Tên VPS</span> này không?
                        </p>
                      </>
                    ),
                  })
                }
              >
                Restart
              </Button>
              <Button
                className="w-[191px] bg-red-500"
                prefix={<ZingIcon.IconLocationSolid color="" />}
                onClick={() => setIsOpenModalChangeIP(true)}
              >
                Thay đổi IP
              </Button>
              <Button
                className="w-[191px] bg-green-500"
                prefix={<ZingIcon.IconSquarePlus />}
                onClick={() => setIsOpenModalExtend(true)}
              >
                Gia hạn
              </Button>
              <Button
                className="w-[191px] bg-yellow-500"
                prefix={<CloudArrowUpSolid />}
                onClick={() => navigate(`${CLOUD_VPS_URL.upgrade.path}/${id}`)}
              >
                Nâng cấp
              </Button>
              <Button
                className="w-[191px] bg-indigo-500"
                prefix={<ZingIcon.IconRecycle />}
                onClick={() =>
                  navigate(`${CLOUD_VPS_URL.reinstallOS.path}/${id}`)
                }
              >
                Hệ điều hành
              </Button>
              <Button
                className="w-[191px] bg-gray-500"
                prefix={<ZingIcon.IconTools />}
                onClick={() =>
                  confirm.warning({
                    title: 'Xác nhận khắc phục sự cố VPS',
                    content: (
                      <>
                        <p>Bạn có chắc muốn xác nhận khắc phục sự cố VPS</p>
                        <p>
                          <span className="font-bold">Tên VPS</span> này không?
                        </p>
                      </>
                    ),
                  })
                }
              >
                Khắc phục sự cố
              </Button>
              <Button
                className="w-[191px] bg-purple-500"
                prefix={<ChevronDoubleRightSolid />}
              >
                Thông tin chi tiết
              </Button>
              <Button
                className="w-[191px] bg-blue-500"
                prefix={<ZingIcon.IconNote />}
                onClick={() => setIsOpenModalNote(true)}
              >
                Ghi chú
              </Button>
              <Button
                className="w-[191px] bg-red-500"
                prefix={<ZingIcon.IconClose />}
                onClick={() =>
                  confirm.warning({
                    title: 'Xác nhận yêu cầu hủy dịch vụ',
                    content: (
                      <>
                        <p>Bạn có chắc muốn xác nhận yêu cầu hủy dịch vụ</p>
                        <p>
                          <span className="font-bold">Mã dịch vụ</span> này
                          không?
                        </p>
                      </>
                    ),
                  })
                }
              >
                Yêu cầu hủy dịch vụ
              </Button>
              <Button
                className="w-[191px] bg-blue-500"
                prefix={<CreditCardSolid />}
                onClick={() => navigate(`${INVOICE_URL.detail.path}/1`)}
              >
                Thanh toán ngay
              </Button>
            </div>
          </div>
          <div className="bg-white p-4 rounded-[10px] drop-shadow flex gap-10">
            <div className="w-[300px] flex flex-col gap-2">
              <span
                className={`p-2 px-[1rem] rounded-[4px] cursor-pointer ${
                  activeTab === 1 && 'text-white bg-blue-500'
                }`}
                onClick={() => setActiveTab(1)}
              >
                Tổng quan
              </span>
              <span
                className={`p-2 px-[1rem] rounded-[4px] cursor-pointer ${
                  activeTab === 2 && 'text-white bg-blue-500'
                }`}
                onClick={() => setActiveTab(2)}
              >
                Thông tin đăng nhập
              </span>
            </div>
            {activeTab === 1 && <Overview />}
            {activeTab === 2 && <LoginInformation />}
          </div>
        </div>
      </div>
      {isOpenModalChangeIP && (
        <ModalChangeIP
          open={isOpenModalChangeIP}
          close={() => setIsOpenModalChangeIP(false)}
        />
      )}
      {isOpenModalExtend && (
        <ModalExtend
          open={isOpenModalExtend}
          close={() => setIsOpenModalExtend(false)}
        />
      )}
      {isOpenModalNote && (
        <ModalNote
          open={isOpenModalNote}
          close={() => setIsOpenModalNote(false)}
        />
      )}
    </div>
  );
};

export default DetailVPSPage;
