import { IRouteProps } from '_common/interface/router';
import TransactionPage from '.';
import { TRANSACTION_URL } from './url';
import TransactionDetail from './containers/Detail';

export const TransactionRouter: IRouteProps[] = [
  {
    key: TRANSACTION_URL.list.key,
    path: TRANSACTION_URL.list.shortenUrl,
    component: <TransactionPage />,
  },
  {
    key: TRANSACTION_URL.detail.key,
    path: TRANSACTION_URL.detail.shortenUrl,
    component: <TransactionDetail />,
  },
];
