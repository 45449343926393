import { DATE_TIME_FORMAT } from '_common/constants/common';
import { copyText } from '_common/utils';
import moment from 'moment';
import bgInvoice from 'styles/images/wallet/bg-invoice.png';
import {
  CreditCardSolid,
  DocumentDuplicateOutlined,
  formatCurrency,
  Tag,
  Tooltip,
} from 'tera-dls';

const CheckedIcon = (props) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_13987_316)">
      <path
        d="M28 3C34.6304 3 40.9893 5.63392 45.6777 10.3223C50.3661 15.0107 53 21.3696 53 28C53 34.6304 50.3661 40.9893 45.6777 45.6777C40.9893 50.3661 34.6304 53 28 53C21.3696 53 15.0107 50.3661 10.3223 45.6777C5.63392 40.9893 3 34.6304 3 28C3 21.3696 5.63392 15.0107 10.3223 10.3223C15.0107 5.63392 21.3696 3 28 3ZM24.8857 32.9321L19.3321 27.375C19.133 27.1759 18.8967 27.018 18.6366 26.9102C18.3764 26.8025 18.0976 26.747 17.8161 26.747C17.5345 26.747 17.2557 26.8025 16.9956 26.9102C16.7355 27.018 16.4991 27.1759 16.3 27.375C15.8979 27.7771 15.672 28.3224 15.672 28.8911C15.672 29.4597 15.8979 30.0051 16.3 30.4071L23.3714 37.4786C23.57 37.6787 23.8061 37.8375 24.0663 37.9459C24.3265 38.0543 24.6056 38.1101 24.8875 38.1101C25.1694 38.1101 25.4485 38.0543 25.7087 37.9459C25.9689 37.8375 26.205 37.6787 26.4036 37.4786L41.0464 22.8321C41.2482 22.6339 41.4087 22.3976 41.5187 22.137C41.6287 21.8764 41.686 21.5966 41.6873 21.3137C41.6886 21.0309 41.6339 20.7505 41.5263 20.4889C41.4188 20.2273 41.2605 19.9896 41.0606 19.7894C40.8607 19.5893 40.6231 19.4307 40.3616 19.3228C40.1001 19.215 39.8199 19.1599 39.537 19.1609C39.2542 19.1619 38.9743 19.2189 38.7135 19.3286C38.4528 19.4383 38.2164 19.5985 38.0179 19.8L24.8857 32.9321Z"
        fill="#0E9F6E"
      />
    </g>
    <rect
      x="1.5"
      y="1.5"
      width="53"
      height="53"
      rx="26.5"
      stroke="white"
      stroke-width="3"
    />
    <defs>
      <clipPath id="clip0_13987_316">
        <rect x="3" y="3" width="50" height="50" rx="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
const Item = () => {
  return (
    <div
      className="px-[16px] [box-shadow:0px_1px_5px_0px_#0000001A,0px_-1px_5px_0px_#0000001A] rounded-xl pt-[30px]"
      style={{
        backgroundImage: `linear-gradient(rgba(255,255,255,0.95),rgba(255,255,255,0.95)),url(${bgInvoice})`,
        backgroundPosition: 'center',
      }}
    >
      <div className="flex flex-col gap-[13px] min-h-[400px]">
        <div className="flex flex-col items-center gap-[16px]">
          <h2 className="text-base font-bold">ZingServer Auto Payment</h2>
          <CheckedIcon />
        </div>
        <div className="flex gap-x-2.5 items-center">
          <CreditCardSolid className="size-9" color="#3F83F8" />
          <div className="flex flex-col gap-y-[5px] text-base">
            <span className={'text-gray-500 font-light'}>Chuyển khoản</span>
            <span className="text-green-500 font-bold flex gap-1 items-center">
              <span className="tracking-[3px]">{formatCurrency(99000000)}</span>
              <Tooltip placement="top" title="Sao chép">
                <DocumentDuplicateOutlined
                  className="w-5 h-5 text-green-600 shrink-0 cursor-pointer"
                  onClick={() => copyText(99000000)}
                  strokeWidth={'1px'}
                />
              </Tooltip>
            </span>
          </div>
        </div>
        <div className="flex flex-col divide-y-[1px] divide-dashed">
          <div className="flex flex-col gap-[7px] pb-[13px]">
            <div className="flex justify-between items-center">
              <span className={'text-gray-500 font-light'}>Trạng thái</span>
              <span className="flex gap-x-1 items-center">
                <Tag className="rounded" color="green03">
                  Hoàn thành
                </Tag>
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className={'text-gray-500 font-light'}>Thời gian</span>
              <span className="flex gap-x-1 items-center">
                {moment().format(DATE_TIME_FORMAT)}
              </span>
            </div>
          </div>
          <div className="flex justify-between items-center py-[13px]">
            <span className={'text-gray-500 font-light'}> Nội dung</span>
            <span className="flex gap-x-1 items-center">
              CTZS L5TMQYCQ
              <Tooltip placement="top" title="Sao chép">
                <DocumentDuplicateOutlined
                  strokeWidth={'1px'}
                  className="w-5 h-5 text-blue-600 shrink-0 cursor-pointer"
                  onClick={() => copyText('CTZS L5TMQYCQ ')}
                />
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
