import { IRouteProps } from '_common/interface/router';
import EmailSecurityPage from '.';
import AddEmailSecurityPage from './pages/Add';
import DetailEmailSecurityPage from './pages/Detail';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import { EMAIL_SECURITY_URL } from './url';

export const EmailSecurityRouter: IRouteProps[] = [
  {
    key: EMAIL_SECURITY_URL.list.key,
    path: EMAIL_SECURITY_URL.list.shortenUrl,
    component: <EmailSecurityPage />,
  },
  {
    key: EMAIL_SECURITY_URL.add.key,
    path: EMAIL_SECURITY_URL.add.shortenUrl,
    component: <AddEmailSecurityPage />,
  },
  {
    key: EMAIL_SECURITY_URL.paymentInformation.key,
    path: EMAIL_SECURITY_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: EMAIL_SECURITY_URL.payment.key,
    path: EMAIL_SECURITY_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
  {
    key: EMAIL_SECURITY_URL.detail.key,
    path: EMAIL_SECURITY_URL.detail.shortenUrl,
    component: <DetailEmailSecurityPage />,
  },
];
