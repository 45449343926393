import ZingIcon from '_common/component/ZingIcon';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import {
  ArrowDownTraySolid,
  ArrowSmallLeftSolid,
  ArrowUpTraySolid,
  Breadcrumb,
  Button,
  ChevronDoubleRightSolid,
  Collapse,
  CollapseProps,
  ColumnsType,
  CreditCardSolid,
  Tag,
} from 'tera-dls';
import { DOMAIN_URL } from '../../url';

const DetailDomainPage = (): JSX.Element => {
  const navigate = useNavigate();
  const confirm = useConfirm();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Tên miền
        </span>
      ),
      onClick: () => navigate(DOMAIN_URL.list.path),
    },
    {
      title: 'Chi tiết Tên miền',
    },
  ];

  const details = [
    {
      label: 'Tên miền',
      value: <span className="text-blue-500">tenmien.com</span>,
    },
    {
      label: 'Ngày hết hạn',
      value: (
        <div className="flex gap-2">
          <span className="text-red-500">03/08/2024</span>
          <span>Hoá đơn chưa thanh toán</span>
          <span className="text-blue-500">#TNG616011</span>
        </div>
      ),
    },
    {
      label: 'Khoá / Mở khoá (Registrar Lock)',
      value: 'Không có thông tin hiển thị',
    },
    {
      label: 'Tự động gia hạn',
      value: (
        <div className="flex gap-4">
          <Tag color="green03">Bật</Tag>
          <Tag color="gray01">Tắt</Tag>
        </div>
      ),
    },
  ];

  const DetailHeader = (): JSX.Element => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between items-center">
          <div className="flex flex-col gap-4">
            <h1 className="text-[20px] font-[600]">
              <span className="text-blue-500">tenmien.com</span> # TNG615950
            </h1>
            <div className="flex gap-4">
              <Tag color="green03" className="p-2">
                Hoạt động
              </Tag>
              <Tag color="yellow03">Đang chờ</Tag>
              <Tag color="red03">Đã hủy</Tag>
            </div>
          </div>
          <div className="flex gap-4">
            <Button
              className="bg-blue-500"
              prefix={<CreditCardSolid />}
              onClick={() => navigate(`${INVOICE_URL.detail.path}/1`)}
            >
              Thanh toán ngay
            </Button>
            <Button
              className="bg-red-500"
              prefix={<ZingIcon.IconClose />}
              onClick={() =>
                confirm.warning({
                  title: 'Xác nhận yêu cầu hủy dịch vụ',
                  content: (
                    <>
                      <p>Bạn có chắc muốn xác nhận yêu cầu hủy dịch vụ</p>
                      <p>
                        <span className="font-bold">#TNG615950</span> này không?
                      </p>
                    </>
                  ),
                })
              }
            >
              Yêu cầu hủy dịch vụ
            </Button>
          </div>
        </div>
        <Tag
          color="yellow03"
          className="p-2 border-[1px] border-yellow-500 rounded-none"
        >
          Tên miền chưa được đăng ký. Khi nhận được thanh toán, hệ thống sẽ tự
          động đăng ký tên miền.
        </Tag>
      </div>
    );
  };

  const DetailContent = (): JSX.Element => {
    const columns: ColumnsType<any> = [
      {
        title: 'Tên miền/Hostname',
        dataIndex: '1',
        render: () => 'ns3.tino.org',
      },
      {
        title: 'Địa chỉ IP',
        dataIndex: '2',
        render: () => '149.28.128.213',
      },
    ];

    const domainDocuments = [
      {
        left: (
          <div className="pl-1 flex justify-between items-center">
            <span className="w-[300px]">
              Xác thực điện tử (EKYC) hồ sơ tên miền
            </span>
          </div>
        ),
        right: (
          <Button
            className="min-w-[150px] bg-green-500"
            prefix={<ChevronDoubleRightSolid />}
            onClick={() =>
              window.open(
                'https://kyc.info.vn/ekyc.php?token=JDIkNzdVVkhBNURvL3FMV2FHUVFTOW9DOUR1QzlSd3hieEt0Vm1pZlBhVENrZVNGTXZYVzFmU2xac29nSm9IQWlqRTlKUS83RnEvam9yKythNXdaUEpMZ1k0PQ==',
                '_blank',
              )
            }
          >
            Xác thực ngay
          </Button>
        ),
      },
      {
        left: (
          <div className="pl-1 flex justify-between items-center">
            <div className="w-[300px] flex flex-col">
              Bản khai tên miền
              <span className="text-[12px] text-gray-500">
                Bản khai tên miền
              </span>
            </div>
            <div className="flex flex-col">
              <Tag color="green03">Đang chờ duyệt</Tag>
              <span className="text-[12px] text-gray-500">
                dd/mm/yyyy - hh:mm
              </span>
            </div>
          </div>
        ),
        right: (
          <div className="flex gap-2">
            <Button
              className="min-w-[150px] bg-blue-500"
              prefix={<ArrowDownTraySolid />}
            >
              Tải về mẫu hồ sơ
            </Button>
            <Button
              className="min-w-[150px] bg-yellow-500"
              prefix={<ZingIcon.IconSign className="w-5 h-5" />}
              onClick={() =>
                window.open(
                  'https://kyc.info.vn/?token=JDIkQ00yVjg0RmFaOFpvamRod25OSUp5SGpRWU9RUUwxYkMyQ0lFeEpkS3RFR3pWdE1sdXhJOFY3MFlvQldLajhXV2tDbjJDSEozWWtnSlFURGU0Ny9GOXBCZg==',
                  '_blank',
                )
              }
            >
              Ký tên nhanh
            </Button>
            <Button
              className="min-w-[150px] bg-green-500"
              prefix={<ArrowUpTraySolid />}
            >
              Tải lên
            </Button>
          </div>
        ),
      },
      {
        left: (
          <div className="pl-1 flex justify-between items-center">
            <div className="w-[300px] flex flex-col">
              Căn cước công dân (Mặt trước)
              <span className="text-[12px] text-gray-500">
                Định dạng file được phép: png,jpg,jpeg
              </span>
            </div>
            <Tag color="yellow03">Chưa tải lên</Tag>
          </div>
        ),
        right: (
          <Button
            className="min-w-[150px] bg-green-500"
            prefix={<ArrowUpTraySolid />}
          >
            Tải lên
          </Button>
        ),
      },
      {
        left: (
          <div className="pl-1 flex justify-between items-center">
            <div className="w-[300px] flex flex-col">
              Căn cước công dân (Mặt sau)
              <span className="text-[12px] text-gray-500">
                Định dạng file được phép: png,jpg,jpeg
              </span>
            </div>
            <Tag color="yellow03">Chưa tải lên</Tag>
          </div>
        ),
        right: (
          <Button
            className="min-w-[150px] bg-green-500"
            prefix={<ArrowUpTraySolid />}
          >
            Tải lên
          </Button>
        ),
      },
    ];

    const itemCollapses: CollapseProps['items'] = [
      {
        key: '1',
        label: 'Các loại hồ sơ khác',
        children: (
          <Fragment>
            <div className="p-2 border-b-[1px] flex justify-between items-center">
              <div className="pl-1 flex justify-between items-center">
                <span className="w-[300px]">
                  Bản khai chuyển đổi nhà đăng ký
                </span>
              </div>
              <Button
                className="min-w-[150px] bg-blue-500"
                prefix={<ArrowDownTraySolid />}
              >
                Tải xuống
              </Button>
            </div>
            <div className="p-2 border-b-[1px] flex justify-between items-center">
              <div className="pl-1 flex justify-between items-center">
                <span className="w-[300px]">
                  Đơn đề nghị thay đổi thông tin tên miền
                </span>
              </div>
              <Button
                className="min-w-[150px] bg-blue-500"
                prefix={<ArrowDownTraySolid />}
              >
                Tải xuống
              </Button>
            </div>
            <div className="p-2 border-b-[1px] flex justify-between items-center">
              <div className="pl-1 flex justify-between items-center">
                <span className="w-[300px]">
                  Đơn đề nghị thay đổi tên chủ thể tên miền
                </span>
              </div>
              <Button
                className="min-w-[150px] bg-blue-500"
                prefix={<ArrowDownTraySolid />}
              >
                Tải xuống
              </Button>
            </div>
            <div className="p-2 border-b-[1px] flex justify-between items-center">
              <div className="pl-1 flex justify-between items-center">
                <span className="w-[300px]">
                  Đơn đề nghị thay đổi tên chủ thể tên miền
                </span>
                <Tag color="yellow03">Chưa tải lên</Tag>
              </div>
              <Button
                className="min-w-[150px] bg-green-500"
                prefix={<ArrowUpTraySolid />}
              >
                Tải lên
              </Button>
            </div>
          </Fragment>
        ),
      },
    ];

    return (
      <div className="p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-4">
        <div className="pb-4 border-b-[2px] border-dashed">
          <span className="text-blue-500">Thông tin chung</span>
          <div className="p-4 flex flex-col gap-1">
            {details.map((detail, index) => (
              <div
                key={index}
                className="py-3 border-b-[1px] flex items-center"
              >
                <span className="flex-1 text-gray-500">{detail.label}</span>
                <span className="flex-1">{detail.value}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="pb-4 border-b-[2px] border-dashed mt-2">
          <span className="text-blue-500">Thông tin tên miền</span>
          <div className="p-4 flex flex-col gap-1">
            <div className="py-3 flex items-center">
              <span className="flex-1 text-gray-500">
                Lệ phí đăng ký tên miền
              </span>
              <span className="flex-1">Không có thông tin hiển thị</span>
            </div>
            <div className="py-3 flex items-center">
              <span className="flex-1 text-gray-500">Phí duy trì tên miền</span>
              <span className="flex-1">Không có thông tin hiển thị</span>
            </div>
          </div>
        </div>
        <div className="pb-8 border-b-[2px] border-dashed mt-2 flex flex-col gap-4">
          <span className="text-blue-500">Nameservers</span>
          <TableTera
            rowKey="id"
            data={[{ id: 1 }, { id: 2 }]}
            columns={columns}
          />
        </div>
        <div className="pb-4 mt-2 flex flex-col gap-4">
          <span className="text-blue-500">Domain Documents</span>
          <div className="bg-white rounded-[6px] drop-shadow flex flex-col">
            <span className="p-4 border-b-[1px]">
              Để sử dụng tên miền đúng quy định pháp luật, bạn cần hoàn tất các
              tài liệu dưới đây.
            </span>
            {domainDocuments.map((document, index) => (
              <div
                key={index}
                className="p-2 border-b-[1px] flex justify-between items-center"
              >
                {document.left}
                {document.right}
              </div>
            ))}
            <Collapse
              items={itemCollapses}
              headingClassName="bg-white p-3 border-0 border-b-[1px]"
              contentClassName="p-0 border-0"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <div className="px-2 flex flex-col gap-4">
        <DetailHeader />
        <DetailContent />
      </div>
    </div>
  );
};

export default DetailDomainPage;
