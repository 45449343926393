import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import FlagVN from 'styles/images/Icons/FlagVN.svg';
import { Button, Tag, formatCurrency } from 'tera-dls';
import { PresetColors } from 'tera-dls/lib/components/Tag/style';
import { VPS_STATUS, VPS_STATUS_COLOR } from '../../../constants';

const Overview = (): JSX.Element => {
  const form = useForm({ mode: 'onChange' });

  return (
    <div className="w-full flex flex-col gap-4">
      <IconText prefix={<ZingIcon.IconInformation color="#0e9f6e" />}>
        <span className="font-[500]">Thông tin chung:</span>
      </IconText>
      <div className="pb-4 pt-2 border-b-[1px] flex">
        <IconText prefix={<ZingIcon.IconLocation />} className="w-[50%]">
          Vị trí:
          <span className="font-[500]">103.250.78.65</span>
        </IconText>
        <IconText prefix={<ZingIcon.IconSquareBox />} className="w-[50%]">
          Tên:
          <span className="font-[500]">103.250.78.65</span>
        </IconText>
      </div>
      <div className="pb-4 pt-2 border-b-[1px] flex">
        <IconText
          prefix={<ZingIcon.IconEarth />}
          suffix={
            <img
              src={FlagVN}
              alt="name_flag"
              className="w-4 h-4 rounded-[1rem] object-cover"
            />
          }
          className="w-[50%]"
        >
          Khu vực:
          <span className="font-[500]">Việt Nam</span>
        </IconText>
        <IconText prefix={<ZingIcon.IconWindows />} className="w-[50%]">
          Hệ điều hành:
          <span className="font-[500]">Windows Server 2012</span>
        </IconText>
      </div>
      <div className="pb-4 pt-2 border-b-[1px] flex">
        <IconText prefix={<ZingIcon.IconCalendarCheck />} className="w-[50%]">
          Ngày tạo:
          <span className="font-[500]">dd/mm/yyyy</span>
        </IconText>
        <IconText prefix={<ZingIcon.IconCalendarEmpty />} className="w-[50%]">
          Ngày hết hạn:
          <span className="font-[500]">dd/mm/yyyy</span>
        </IconText>
      </div>
      <div className="pb-4 pt-2 border-b-[1px] flex">
        <IconText prefix={<ZingIcon.IconCompass />} className="w-[50%]">
          Trạng thái:
          <span className="font-[500]">
            <Tag
              color={VPS_STATUS_COLOR[1] as PresetColors}
              className="font-[500]"
            >
              {VPS_STATUS[1]}
            </Tag>
          </span>
        </IconText>
        <IconText
          prefix={<ZingIcon.IconArrowCache color="#3f83f8" />}
          className="w-[50%]"
        >
          Số lần đổi IP:
          <span className="text-green-500 font-[500]">0/15</span>
        </IconText>
      </div>
      <IconText prefix={<ZingIcon.IconVPS color="#0E9F6E" />}>
        <span className="font-[500]">Cấu hình:</span>
      </IconText>
      <div className="pb-4 pt-2 border-b-[1px] flex">
        <IconText
          prefix={<ZingIcon.IconRam color="#3f83f8" />}
          className="w-[50%]"
        >
          RAM:
          <span className="font-[500]">16</span>
          GB
        </IconText>
        <IconText
          prefix={<ZingIcon.IconCPU color="#3f83f8" />}
          className="w-[50%]"
        >
          vCPU:
          <span className="font-[500]">8</span>
        </IconText>
      </div>
      <div className="pb-4 pt-2 border-b-[1px] flex">
        <IconText
          prefix={<ZingIcon.IconCPUStorage color="#3f83f8" />}
          className="w-[50%]"
        >
          SSD:
          <span className="font-[500]">90</span>
          GB
        </IconText>
        <IconText
          prefix={<ZingIcon.IconNetwork color="#3f83f8" />}
          className="w-[50%]"
        >
          Mbps:
          <span className="font-[500]">800</span>
        </IconText>
      </div>
      <div className="pb-4 pt-2 border-b-[1px] flex">
        <IconText
          prefix={<ZingIcon.IconWifiVertical color="#3f83f8" />}
          className="w-[50%]"
        >
          Wifi:
          <span className="font-[500]">Unlimited</span>
        </IconText>
        <IconText prefix={<ZingIcon.IconDollarCard />} className="w-[50%]">
          Đơn giá:
          <span className="text-[#e02424] font-[500]">
            {formatCurrency(1075000)}
          </span>
          / tháng
        </IconText>
      </div>
      <IconText prefix={<ZingIcon.IconNote color="#0e9f6e" />}>
        <span className="font-[500]">Ghi chú:</span>
      </IconText>
      <FormTera form={form}>
        <FormTeraItem name="note" className="mb-0">
          <TextArea
            placeholder="Vui lòng nhập"
            className="h-[100px] resize-none"
          />
        </FormTeraItem>
        <Button htmlType="submit" prefix={<ZingIcon.IconFloppyDisk />}>
          Lưu ghi chú
        </Button>
      </FormTera>
    </div>
  );
};

export default Overview;
