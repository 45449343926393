import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import Select from '_common/dof/Control/Select';
import { FormTeraItem } from '_common/dof/FormTera';
import TableTera from '_common/dof/TableTera';
import { durations } from 'pages/ServiceManagement/Domain/constants';
import { HOSTING_URL } from 'pages/ServiceManagement/Hosting/url';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fragment } from 'react/jsx-runtime';
import {
  Button,
  ChevronDoubleRightSolid,
  ColumnsType,
  PlusCircleSolid,
  ShoppingCartSolid,
  Tooltip,
  TrashSolid,
  formatCurrency,
} from 'tera-dls';

const FormAdd = ({ form }): JSX.Element => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [isCheckingPromoCode, setIsCheckingPromoCode] =
    useState<boolean>(false);
  const navigate = useNavigate();

  const columns1: ColumnsType<any> = [
    {
      title: 'Tên miền',
      dataIndex: 'name',
    },
    {
      title: 'Thời hạn',
      width: 300,
      render: (_, __, index) => (
        <FormTeraItem name={`domains.${index}.duration`} className="mb-0">
          <Select options={durations} />
        </FormTeraItem>
      ),
    },
  ];

  const columns2: ColumnsType<any> = [
    {
      title: 'Tên miền',
      dataIndex: 'name',
      render: (name, record) => (
        <div className="flex items-center gap-2">
          {name}
          <Tooltip
            title={<div className="w-[190px]">{record.description}</div>}
            placement="top"
          >
            <ZingIcon.IconQuestionMark className="text-blue-500" />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Thời hạn',
      width: 300,
      render: (_, record) => (
        <div className="flex justify-end">{record.button}</div>
      ),
    },
  ];

  const NameServerExpand = (): JSX.Element => {
    return (
      <Fragment>
        {form.watch('nameservers').map((_, index) => (
          <div key={index} className="p-2 border-b-[1px] flex items-center">
            <span className={index > 1 ? 'w-[300px]' : 'w-[348px]'}>
              NS{index + 1}
            </span>
            {index > 1 && (
              <span className="w-[48px] flex justify-center">
                <TrashSolid
                  className="w-4 h-4 text-red-500 cursor-pointer"
                  onClick={() => {
                    form.watch('nameservers').splice(index, 1);
                    form.setValue('nameservers', form.watch('nameservers'));
                  }}
                />
              </span>
            )}
            <FormTeraItem
              name={`nameservers.${index}.nameserver`}
              className="flex-1 mb-0"
            >
              <Input />
            </FormTeraItem>
          </div>
        ))}
        <IconText
          prefix={<PlusCircleSolid className="w-4 h-4 text-blue-500" />}
          className="p-2 border-b-[1px] justify-center gap-1 cursor-pointer"
          onClick={() =>
            form.setValue('nameservers', [
              ...form.watch('nameservers'),
              { nameserver: '' },
            ])
          }
        >
          <span className="text-blue-500">Thêm mới</span>
        </IconText>
      </Fragment>
    );
  };

  const formSections = [
    {
      content: (
        <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-6">
          <span className="text-blue-500">Tên miền</span>
          <TableTera
            data={form.watch('domains')}
            columns={columns1}
            className="[&_.tera-table-cell]:align-middle"
            summary={() => (
              <>
                <tr className="tera-table-row">
                  <td className="tera-table-cell" colSpan={2}>
                    Lệ phí đăng ký tên miền
                    <br />
                    Đã bao gồm trong giá bán - Không chịu thuế
                  </td>
                </tr>
                <tr className="tera-table-row">
                  <td className="tera-table-cell" colSpan={2}>
                    Phí duy trì tên miền
                    <br />
                    Đã bao gồm trong giá bán - Không chịu thuế
                  </td>
                </tr>
              </>
            )}
          />
        </div>
      ),
    },
    {
      content: (
        <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-6">
          <span className="text-blue-500">Hiệu chỉnh cấu hình</span>
          <TableTera
            data={[
              {
                id: 1,
                name: 'Sửa nameserver',
                description:
                  'Mặc định, tất cả các domain đăng ký đều sử dụng nameserver của ZingServer. Nếu bạn cần sử dụng nameserver của bạn thì chọn vào',
                button: (
                  <Button
                    htmlType="button"
                    prefix={<ChevronDoubleRightSolid />}
                    className="bg-green-500"
                    onClick={() =>
                      setExpandedRowKeys(
                        expandedRowKeys.includes(1)
                          ? expandedRowKeys.filter((k) => k !== 1)
                          : [...expandedRowKeys, 1],
                      )
                    }
                  >
                    Sử dụng nameserver của tôi
                  </Button>
                ),
              },
              {
                id: 2,
                name: 'Bạn chưa có hosting',
                description: 'Bạn có thể đăng ký thêm hosting ở tùy chọn này',
                button: (
                  <Button
                    htmlType="button"
                    prefix={<ShoppingCartSolid />}
                    className="bg-blue-500"
                    onClick={() => navigate(HOSTING_URL.add.path)}
                  >
                    Mua tên hosting
                  </Button>
                ),
              },
            ]}
            columns={columns2}
            className="[&_.tera-table-cell]:align-middle"
            expandable={{
              expandedRowKeys,
              expandIconColumnIndex: -1,
              expandedRowRender: () => <NameServerExpand />,
            }}
          />
        </div>
      ),
    },
    {
      content: (
        <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-6">
          <span className="text-blue-500">Thông tin chủ thể tên miền</span>
          <FormTeraItem name="receive_notification" className="flex-1 mb-0">
            <CheckBox labelClassName="font-[400]">
              Nhận thông báo email liên quan đến tên miền
            </CheckBox>
          </FormTeraItem>
          <i className="text-gray-500">
            Lưu ý thông tin phải chính xác để xác nhận tính hợp lệ của chủ thể
            tên miền
          </i>
        </div>
      ),
    },
    {
      content: (
        <div className="w-full p-4 bg-white rounded-[6px] drop-shadow flex flex-col gap-6">
          <span className="text-blue-500">Sử dụng mã khuyến mãi</span>
          <div className="flex items-center gap-2">
            <FormTeraItem name="promo_code" className="flex-1 mb-0">
              <Input placeholder="Vui lòng nhập" />
            </FormTeraItem>
            <Button
              htmlType="button"
              className="bg-blue-500"
              onClick={() => setIsCheckingPromoCode(true)}
            >
              Kiểm tra
            </Button>
          </div>
          {isCheckingPromoCode && (
            <div className="w-[360px] flex flex-col gap-2">
              <span className="text-red-500">Mã khuyến mãi không khả dụng</span>
              <div className="flex justify-between items-center">
                <span>Mã khuyến mãi</span>
                <span>KM123</span>
              </div>
              <div className="flex justify-between items-center">
                <span>Số tiền khuyến mãi</span>
                <span className="text-green-500">{formatCurrency(100000)}</span>
              </div>
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Fragment>
      {formSections.map((section, index) => (
        <Fragment key={index}>{section.content}</Fragment>
      ))}
    </Fragment>
  );
};

export default FormAdd;
