import { IRouteProps } from '_common/interface/router';
import InformationPage from '.';
import { INFORMATION_URL } from './url';

export const InformationRouter: IRouteProps[] = [
  {
    key: INFORMATION_URL.list.key,
    path: INFORMATION_URL.list.shortenUrl,
    component: <InformationPage />,
  },
];
