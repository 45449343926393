import ZingIcon from '_common/component/ZingIcon';

const WidgetOverview = (): JSX.Element => {
  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="bg-white p-5 rounded-[4px] drop-shadow flex justify-between items-center">
        <ZingIcon.IconLightning />
        <div className="font-bold text-[#0E9F6E] flex flex-col items-center gap-2">
          <span>1</span>
          <span>Đang chạy</span>
        </div>
      </div>
      <div className="bg-white p-5 rounded-[4px] drop-shadow flex justify-between items-center">
        <ZingIcon.IconHourglass />
        <div className="font-bold text-[#C27803] flex flex-col items-center gap-2">
          <span>1</span>
          <span>Sắp hết hạn</span>
        </div>
      </div>
      <div className="bg-white p-5 rounded-[4px] drop-shadow flex justify-between items-center">
        <ZingIcon.IconCloseCircle />
        <div className="font-bold text-[#6B7280] flex flex-col items-center gap-2">
          <span>1</span>
          <span>Đã hủy</span>
        </div>
      </div>
      <div className="bg-white p-5 rounded-[4px] drop-shadow flex justify-between items-center">
        <ZingIcon.IconSquaresBox />
        <div className="font-bold text-[#3F83F8] flex flex-col items-center gap-2">
          <span>1</span>
          <span>Tổng số</span>
        </div>
      </div>
    </div>
  );
};

export default WidgetOverview;
