import FormTera from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb } from 'tera-dls';
import { CLOUD_VPS_URL } from '../../url';
import FormAdd from './components/FormAdd';
import FormSummary from './components/FormSummary';

const AddCloudVPSPage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      service_package: 1,
      cpu: 1,
      ram: 1,
      storage: 20,
      os: 1,
      billing_cycle: 1,
      direct_admin_plus: {
        period: 1,
        license_name: 1,
      },
      direct_admin_lite: {
        period: 1,
      },
    },
  });
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () => navigate(CLOUD_VPS_URL.list.path),
    },
    {
      title: 'Thêm Cloud VPS',
    },
  ];

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <FormTera form={form} className="flex-1 flex flex-col">
        <FormAdd form={form} />
        <FormSummary />
      </FormTera>
    </div>
  );
};

export default AddCloudVPSPage;
