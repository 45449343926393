import FormTera from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb, ShoppingCartSolid } from 'tera-dls';
import Advertisement from './components/Advertisement';
import InformationCustomer from './components/InformationCustomer';
import InformationOrder from './components/InformationOrder';
import Note from './components/Note';
import Notification from './components/Notification';
import Payment from './components/Payment';
import PaymentMethod from './components/PaymentMethod';
import PromoCode from './components/PromoCode';
import BreadcrumbTag from '_common/component/BreadcrumbTag';
import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import { CLOUD_VPS_URL } from '../../url';

const PaymentInformationPage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: { payment_method: 1, is_ok: true },
  });
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () => navigate(CLOUD_VPS_URL.list.path),
    },
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Thêm Cloud VPS
        </span>
      ),
      onClick: () => navigate(CLOUD_VPS_URL.add.path),
    },
    {
      title: 'Thông tin thanh toán Cloud VPS',
    },
  ];

  const itemTags = [
    {
      title: (
        <IconText
          prefix={<ZingIcon.IconMenuCloud className="w-4 h-4" />}
          className="text-blue-500"
        >
          Cloud VPS
        </IconText>
      ),
      onClick: () => navigate(CLOUD_VPS_URL.list.path),
    },
    {
      title: (
        <IconText prefix={<ZingIcon.IconVPS />} className="text-blue-500">
          Cấu hình Cloud VPS
        </IconText>
      ),
      onClick: () => navigate(CLOUD_VPS_URL.add.path),
    },
    {
      title: (
        <IconText
          prefix={<ShoppingCartSolid className="w-4 h-4" />}
          className="text-blue-500"
        >
          Thông tin đơn hàng
        </IconText>
      ),
      onClick: () => navigate(CLOUD_VPS_URL.paymentInformation.path),
    },
  ];

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
        <div className="pb-4">
          <BreadcrumbTag items={itemTags} className="px-4" />
        </div>
      </div>
      <FormTera form={form} className="w-full p-4 flex items-start gap-8">
        <div className="w-[70%] p-4 bg-white rounded-[6px] drop-shadow">
          <InformationOrder />
          <Notification />
          <InformationCustomer />
          <Advertisement />
          <Note />
        </div>
        <div className="w-[30%] flex flex-col gap-8">
          <PromoCode />
          <PaymentMethod />
          <Payment />
        </div>
      </FormTera>
    </div>
  );
};

export default PaymentInformationPage;
