import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import {
  TransactionMethod,
  TransactionType,
} from 'pages/InvoiceManagement/Withdrawal/constants';

import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function TransactionFilter({ open, onClose, onFilter, initialValue }: IProps) {
  const form = useForm();

  const handleSubmitForm = (values) => {
    onFilter({
      ...values,
      ...(values?.time && { time: values?.time?.format(DATE_BACKEND_FORMAT) }),
    });
    onClose();
  };

  const handleReset = () => {
    const values = {
      transaction_type: null,
      methods: null,
      time: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, [
      'status',
      'transaction_type',
      'methods',
    ]);

    form.reset({
      ...values,
      ...(initialValue?.time && {
        time: moment(initialValue?.time, DATE_BACKEND_FORMAT),
      }),
    });
  }, [initialValue]);

  const optionsMethod = Object.entries(TransactionMethod).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );
  const optionsType = Object.entries(TransactionType).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={handleSubmitForm} isLoading={false}>
        <FormTeraItem label="Loại giao dịch" name="transaction_type">
          <Select options={optionsType} />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="methods">
          <Select options={optionsMethod} />
        </FormTeraItem>
        <FormTeraItem label="Thời gian" name="time">
          <DatePicker />
        </FormTeraItem>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default TransactionFilter;
