import { IRouteProps } from '_common/interface/router';
import RechargePage from '.';
import { RECHARGE_URL } from './url';

export const RechargeRouter: IRouteProps[] = [
  {
    key: RECHARGE_URL.list.key,
    path: RECHARGE_URL.list.shortenUrl,
    component: <RechargePage />,
  },
];
