import PageTitle from '_common/component/PageTitle';
import { useNavigate } from 'react-router-dom';
import { Button, Row } from 'tera-dls';
import { LOGIN_URL } from '../Login/url';
import FormRegister from './components/FormRegister';

const RegisterPage = (): JSX.Element => {
  const navigate = useNavigate();

  const handleRegister = () => {
    navigate(LOGIN_URL.index.path);
  };

  return (
    <>
      <PageTitle
        title="ĐĂNG KÝ"
        leftSide={
          <Row className="p-3">
            <Button className="bg-[#3F83F8] p-3 px-4" onClick={handleRegister}>
              Đăng ký
            </Button>
          </Row>
        }
      />
      <div className="tera-page">
        <div className="w-full h-full bg-white p-3 border-[1px] border-[#E5E7EB] rounded-[10px] drop-shadow">
          <FormRegister />
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
