export const HOSTING_URL = {
  list: {
    key: '',
    path: '/portal/service/hosting/list',
    shortenUrl: 'hosting/list',
  },
  add: {
    key: '',
    path: '/portal/service/hosting/add',
    shortenUrl: 'hosting/add',
  },
  paymentInformation: {
    key: '',
    path: '/portal/service/hosting/payment-information',
    shortenUrl: 'hosting/payment-information',
  },
  payment: {
    key: '',
    path: '/portal/service/hosting/payment',
    shortenUrl: 'hosting/payment',
  },
  detail: {
    key: '',
    path: '/portal/service/hosting/detail',
    shortenUrl: 'hosting/detail/:id',
  },
};
