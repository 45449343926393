import { IRouteProps } from '_common/interface/router';
import UnAuthCloudVPSPage from '.';
import UnAuthAddCloudVPSPage from './pages/Add';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import { UNAUTH_CLOUD_VPS_URL } from './url';

export const UnAuthCloudVPSRouter: IRouteProps[] = [
  {
    key: UNAUTH_CLOUD_VPS_URL.index.key,
    path: UNAUTH_CLOUD_VPS_URL.index.shortenUrl,
    component: <UnAuthCloudVPSPage />,
  },
  {
    key: UNAUTH_CLOUD_VPS_URL.add.key,
    path: UNAUTH_CLOUD_VPS_URL.add.shortenUrl,
    component: <UnAuthAddCloudVPSPage />,
  },
  {
    key: UNAUTH_CLOUD_VPS_URL.paymentInformation.key,
    path: UNAUTH_CLOUD_VPS_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: UNAUTH_CLOUD_VPS_URL.payment.key,
    path: UNAUTH_CLOUD_VPS_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
];
