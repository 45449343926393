import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';

interface SelectBankProps extends SelectProps {
  paramsApi?: TParamsApiDof;
}

const SelectBank = forwardRef<HTMLInputElement, SelectBankProps>(
  ({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
    const [searchBank, setSearchBank] = useState<string>('');
    const searchBankDebounce = useDebounce(searchBank, 300);
    paramsApi;
    const { data: listBank } = useQuery(
      ['get-bank-list', searchBankDebounce],
      () => {
        data: [];
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const OptionBank = ({ image, title, sub }) => {
      return (
        <div className="flex gap-x-2.5 items-center">
          <div className="w-[30px] h-[30px] rounded p-[2px] flex items-center justify-center overflow-hidden shadow-[0px_2px_2px_0px_rgba(0,0,0,0.06),0px_-2px_2px_0px_rgba(0,0,0,0.02)]">
            <img src={image} alt={title} className="w-full" />
          </div>
          <div>
            <p className="font-semibold text-gray-800">{title}</p>
            <p className="text-gray-500">{sub}</p>
          </div>
        </div>
      );
    };

    const optionsBank: OptionProps[] =
      (listBank as any)?.map((bank) => ({
        label: `${bank?.card_name} - ${bank?.description}`,
        value: bank?.id,
        labelDisplay: (
          <OptionBank
            image={bank?.image_bank}
            sub={bank?.description}
            title={bank?.card_name}
          />
        ),
      })) ?? [];

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        placeholder={placeholder}
        options={optionsBank}
        searchValue={searchBank}
        onSearch={setSearchBank}
        {...props}
      />
    );
  },
);

export default SelectBank;
