import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValues?: any;
}

const InvoiceDetailFilter = (props: IProps) => {
  const { open, onClose, onFilter, initialValues } = props;

  const form = useForm();

  useEffect(() => {
    initialValues &&
      form.reset({
        status: initialValues?.status,
        ...(initialValues?.created_date?.[0] && {
          created_date: [
            moment(initialValues?.created_date?.[0]),
            moment(initialValues?.created_date?.[1]),
          ],
        }),
        ...(initialValues?.expired_date?.[0] && {
          expired_date: [
            moment(initialValues?.expired_date?.[0]),
            moment(initialValues?.expired_date?.[1]),
          ],
        }),
        ...(initialValues?.payment_date?.[0] && {
          payment_date: [
            moment(initialValues?.payment_date?.[0]),
            moment(initialValues?.payment_date?.[1]),
          ],
        }),
      });
  }, [initialValues]);

  const handleSubmitForm = (value): void => {
    onFilter({
      status: value?.status,
      ...(value?.created_date?.[0] && {
        created_date: [
          value?.created_date?.[0]?.format(DATE_BACKEND_FORMAT),
          value?.created_date?.[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
      ...(value?.expired_date?.[0] && {
        expired_date: [
          value?.expired_date?.[0]?.format(DATE_BACKEND_FORMAT),
          value?.expired_date?.[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
      ...(value?.payment_date?.[0] && {
        payment_date: [
          value?.payment_date?.[0]?.format(DATE_BACKEND_FORMAT),
          value?.payment_date?.[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
    });
    onClose();
  };

  const handleReset = (): void => {
    form.reset();
    onFilter({
      created_date: null,
      expired_date: null,
      payment_date: null,
      status: null,
    });
    onClose();
  };

  return (
    <>
      <Filter
        open={open}
        onClose={onClose}
        onCancel={onClose}
        onFilter={() => form.handleSubmit(handleSubmitForm)()}
      >
        <FormTera form={form}>
          <FormTeraItem label="Trạng thái" name="status">
            <Select
              options={[
                {
                  value: 1,
                  label: 'Hoàn thành',
                },
                {
                  value: 2,
                  label: 'Chưa thanh toán',
                },
              ]}
            />
          </FormTeraItem>
          <FormTeraItem label="Thời gian tạo" name="created_date">
            <RangePicker />
          </FormTeraItem>
          <FormTeraItem label="Thời gian hết hạn" name="expired_date">
            <RangePicker />
          </FormTeraItem>
          <FormTeraItem label="Thời gian thanh toán" name="payment_date">
            <RangePicker />
          </FormTeraItem>
          <a
            className="text-red-500 text-sm font-normal cursor-pointer"
            onClick={() => handleReset()}
          >
            Hủy bộ lọc
          </a>
        </FormTera>
      </Filter>
    </>
  );
};

export default InvoiceDetailFilter;
