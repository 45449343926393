import CopyClipboardText from '_common/component/CopyClipboardText';
import IconText from '_common/component/IconText';
import { useNavigate } from 'react-router-dom';
import ImgQRPayment from 'styles/images/pages/Payment/qr_payment.png';
import {
  ArrowSmallLeftSolid,
  Breadcrumb,
  CreditCardSolid,
  DocumentDuplicateOutlined,
  formatCurrency,
  Tag,
} from 'tera-dls';
import { SSL_URL } from '../../url';

const PaymentPage = (): JSX.Element => {
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách SSL
        </span>
      ),
      onClick: () => navigate(SSL_URL.list.path),
    },
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Thêm cấu hình SSL
        </span>
      ),
      onClick: () => navigate(SSL_URL.add.path),
    },
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Thông tin thanh toán SSL
        </span>
      ),
      onClick: () => navigate(SSL_URL.paymentInformation.path),
    },
    {
      title: 'Thanh toán SSL',
    },
  ];

  const details = [
    { label: 'Số tài khoản', value: 432432432 },
    { label: 'Người thụ hưởng', value: 'Nguyễn Cao Kỳ Duyên' },
    { label: 'Ngân hàng', value: 'ACB' },
  ];

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <div className="px-2">
        <div className="bg-white p-4 rounded-[10px] flex flex-col gap-4">
          <span className="text-blue-500">Thông tin thanh toán</span>
          <span>
            Quý khách vui lòng điền đúng số hoá đơn và số tiền hoặc quét mã QR
            để thanh toán được xác nhận nhanh chóng.
          </span>
          <div className="w-full flex justify-center">
            <div className="w-[432px] bg-white p-4 py-8 rounded-[4px] drop-shadow relative">
              <div className="before:content-[''] before:bg-[url('styles/images/pages/Payment/background_payment.png')] before:bg-center before:bg-cover before:bg-no-repeat before:absolute before:top-0 before:right-0 before:bottom-0 before:left-0 before:opacity-[0.1] before:z-[-1]" />
              <div className="flex flex-col gap-4 z-1">
                <div className="w-full flex flex-col items-center gap-4">
                  <span className="text-[16px] font-bold">
                    Thanh toán ZingServer
                  </span>
                  <img src={ImgQRPayment} alt="QR Payment" />
                </div>
                <div className="flex items-center gap-2">
                  <CreditCardSolid className="w-[30px] text-blue-500" />
                  <div className="text-[16px] flex flex-col gap-2">
                    <span>Thanh toán</span>
                    <CopyClipboardText
                      valueCopy="99000000"
                      className="text-green-500 font-bold tracking-widest flex"
                    >
                      <IconText
                        suffix={
                          <DocumentDuplicateOutlined className="w-6 h-6" />
                        }
                      >
                        {formatCurrency(99000000)}
                      </IconText>
                    </CopyClipboardText>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-500">Trạng thái</span>
                    <Tag color="yellow03">Chờ xác nhận</Tag>
                  </div>
                  <div className="pb-4 border-b-[2px] border-dashed flex justify-between items-center">
                    <span className="text-gray-500">Thời gian</span>
                    <span>dd/mm/yyyy - hh:mm CH</span>
                  </div>
                  {details.map((detail, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <span className="text-gray-500">{detail.label}</span>
                      <span>{detail.value}</span>
                    </div>
                  ))}
                  <div className="flex justify-between items-center">
                    <span className="text-gray-500">Nội dung</span>
                    <CopyClipboardText valueCopy="CTZS L5TMQYCQ">
                      <IconText
                        suffix={
                          <DocumentDuplicateOutlined className="w-6 h-6 text-blue-500" />
                        }
                      >
                        CTZS L5TMQYCQ
                      </IconText>
                    </CopyClipboardText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
