const InformationCustomer = (): JSX.Element => {
  const details = [
    { label: 'Tên công ty', value: 'Công ty ABC' },
    { label: 'Mã số thuế', value: 'Công ty ABC' },
    { label: 'Tên', value: 'Hà My' },
    { label: 'Họ và tên đệm', value: 'Nguyễn Thị' },
    { label: 'Danh xưng', value: 'Anh' },
    { label: 'Số CCCD/Passport', value: 'Công ty ABC' },
    { label: 'Email', value: '@email' },
    { label: 'Điện thoại', value: '0909123456' },
    { label: 'Quốc gia', value: 'Việt Nam' },
    { label: 'Tỉnh/Thành', value: 'Hồ Chí Minh' },
    { label: 'Quận/Huyện', value: 'Quận 1' },
    { label: 'Phường/Xã', value: 'Bến Nghé' },
    { label: 'Địa chỉ', value: '12 Phan Bội Châu' },
    { label: 'Email nhận hóa đơn', value: '@email' },
  ];

  return (
    <div className="py-8 border-b-[2px] border-dashed flex flex-col gap-4">
      <span className="text-blue-500">Thông tin khách hàng</span>
      <div className="flex flex-wrap">
        {details.map((detail, index) => (
          <div key={index} className="w-[50%] py-4 border-b-[1px]">
            <span className="w-[150px] text-gray-500 inline-block">
              {detail.label}
            </span>
            <span>{detail.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InformationCustomer;
