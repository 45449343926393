import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, Spin, Tabs, updateQueryParams } from 'tera-dls';
import Secure from './containers/Secure';
import SSHKeys from './containers/SSHKeys';
import Verify from './containers/Verify';

const SecurityPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const tabItems = [
    {
      key: 'security',
      label: 'Bảo mật',
    },
    {
      key: 'ssh',
      label: 'SSH Keys',
    },
    {
      key: 'verify',
      label: 'Xác thực 2 bước',
    },
  ];

  const tabData = {
    security: <Secure />,
    ssh: <SSHKeys />,
    verify: <Verify />,
  };

  return (
    <Spin spinning={false}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div className="text-gray-700 font-semibold text-base uppercase">
                Bảo mật
              </div>
            </div>
            <div className="page-header-v2__function shrink-0">
              {/* <Button onClick={form.handleSubmit(handleSubmitForm)}>Lưu</Button> */}
            </div>
          </div>
        </div>
        <div className="page-content-v2 bg-white shadow-xsm rounded-md !px-[16px] h-full mx-2.5 !mb-2.5 !gap-y-0">
          <div className="flex flex-col gap-[16px] pb-2.5 text-sm">
            <Tabs
              onChange={(tab) => handleUpdateQueryParams({ tab })}
              items={tabItems}
              activeKey={queryParams?.tab || 'security'}
              className="mb-0 pt-0 bg-white rounded-t-md"
            />
            <div className="pt-[5px]">
              {tabData[queryParams?.tab || 'security'] ?? <Secure />}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default SecurityPage;
