import TableTera from '_common/dof/TableTera';
import { ColumnsType, formatCurrency, Modal } from 'tera-dls';

interface IModalDomainProcessesDetailProps {
  open: boolean;
  close: () => void;
}

const ModalSummary = ({
  open,
  close,
}: IModalDomainProcessesDetailProps): JSX.Element => {
  const columns: ColumnsType<any> = [
    {
      title: 'Mô tả',
      dataIndex: 'description',
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      render: (price) => <span>{formatCurrency(price)} / 01 năm</span>,
    },
  ];

  return (
    <Modal
      title="Áp dụng thuế VAT cho tên miền"
      okText="Đóng"
      open={open}
      onOk={close}
      centered={true}
      cancelButtonProps={{ className: 'hidden' }}
      className="sm:w-[90%] xl:w-[1000px]"
    >
      <TableTera
        columns={columns}
        data={[{ id: 1, description: 'Server C (tenmien.com)', price: 313000 }]}
        summary={() => (
          <>
            <tr className="tera-table-row">
              <td className="tera-table-cell !align-middle">Tạm tính</td>
              <td className="tera-table-cell flex flex-col">
                {formatCurrency(3653000)}
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell !align-middle">VAT (10%)</td>
              <td className="tera-table-cell flex flex-col">
                {formatCurrency(226300)}
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell !align-middle">
                Thanh toán theo định kỳ
              </td>
              <td className="tera-table-cell flex flex-col">
                <span>{formatCurrency(2489300)} / 01 năm</span>
                <span>{formatCurrency(1390000)} / 02 năm</span>
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell !align-middle">
                Thanh toán hôm nay
              </td>
              <td className="tera-table-cell">
                <span className="text-[20px] text-[#0E9F6E]">
                  {formatCurrency(480000)}
                </span>
              </td>
            </tr>
          </>
        )}
      />
    </Modal>
  );
};

export default ModalSummary;
