import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { INVOICE_URL } from 'pages/InvoiceManagement/Invoice/url';
import ButtonWallet from 'pages/InvoiceManagement/Recharge/ButtonWallet';
import WithDrawalFilter from 'pages/InvoiceManagement/Withdrawal/containers/Filter';
import TransactionModal from 'pages/InvoiceManagement/Withdrawal/containers/TransactionDetail/TransactionModal';
import WithDrawal from 'pages/InvoiceManagement/Withdrawal/containers/WithDrawal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  formatCurrency,
  FunnelOutlined,
  Row,
  updateQueryParams,
} from 'tera-dls';

const Withdrawal = (): JSX.Element => {
  const navigate = useNavigate();
  const [params, setParams] = useState<any>();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openWithDrawal, setOpenWithDrawal] = useState<boolean>(false);
  const {
    transactionStore: {
      clearResultTransaction,
      open_transaction = false,
      transaction_id = null,
    },
  } = useStores();

  const handleFilter = (values) => {
    setParams((prev = {}) => ({ ...prev, ...values, page: 1 }));
  };

  const renderItemActions = (item: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    item;
    dropdownItems.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        const queryParams = updateQueryParams({
          type: 'detail',
        });
        navigate(`${INVOICE_URL.detail.path}/1${queryParams}`);
      },
    });

    return dropdownItems;
  };

  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'date',
      width: 200,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: 'Số tiền rút',
      dataIndex: 'amount',
      width: 200,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      width: 200,
    },
    {
      dataIndex: 'action',
      title: '',
      width: 50,
      render: (_, record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <div>
        <div className=" flex flex-col gap-2.5">
          <Row className="flex justify-between">
            <p>
              Với hệ thống cộng tác viên của chúng tôi, bạn có thể tự tạo mã
              khuyến mãi cho riêng mình và gửi để thu hút nhiều khách hàng hơn.
            </p>
            <span>
              <ButtonWallet
                title="Rút tiền"
                typeBtn="withdrawal"
                onClick={() => setOpenWithDrawal(true)}
              />
            </span>
          </Row>
          <div className="flex justify-end flex-col shadow rounded-md py-2 gap-2">
            <div className="flex flex-col px-2 justify-end items-end gap-2">
              <div className="flex gap-2.5">
                <Button
                  type="alternative"
                  className="rounded-xsm py-2 px-2  bg-blue-50"
                  onClick={() => setOpenFilter(true)}
                >
                  <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0 size-4" />
                </Button>
              </div>
            </div>
            <TableTera
              columns={columns}
              className="center-table"
              wrapperClassName="shadow-none"
              pagination={{}}
              data={[
                {
                  id: 1,
                  date: moment().format(),
                  amount: 50000,
                  description: 'Ghi chú',
                },
                {
                  id: 2,
                  date: moment().format(),
                  amount: 50000,
                  description: 'Ghi chú',
                },
                {
                  id: 3,
                  date: moment().format(),
                  amount: 50000,
                  description: 'Ghi chú',
                },
              ]}
            />
          </div>
        </div>
      </div>
      {openFilter && (
        <WithDrawalFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValues={params}
          onFilter={handleFilter}
        />
      )}
      {openWithDrawal && (
        <WithDrawal
          open={openWithDrawal}
          type={'withdrawal'}
          onClose={() => setOpenWithDrawal(false)}
        />
      )}
      {open_transaction && (
        <TransactionModal
          type="withdrawal"
          id={transaction_id}
          open={open_transaction}
          onClose={() => {
            clearResultTransaction();
          }}
        />
      )}
    </>
  );
};

export default observer(Withdrawal);
