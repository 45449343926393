import Radio from '_common/dof/Control/Radio';
import { FormTeraItem } from '_common/dof/FormTera';
import { formatCurrency } from 'tera-dls';

const PaymentMethod = (): JSX.Element => {
  const methods = [
    {
      value: 1,
      label: 'ACB - Ngân hàng TMCP Á Châu',
    },
    {
      value: 2,
      label: 'VCB - Ngân hàng TMCP Ngoại Thương Việt Nam',
    },
    {
      value: 3,
      label: 'Thanh toán QRPAY/Thẻ ATM/Credit Card',
    },
    {
      value: 4,
      label: 'Ví của tôi',
    },
  ];

  return (
    <div className="bg-[#FAFAF9] p-4 rounded-[6px] drop-shadow flex flex-col gap-4">
      <span className="text-[16px] font-[600]">
        Chọn phương thức thanh toán
      </span>
      <div className="p-4 flex flex-col gap-3">
        <FormTeraItem name="payment_method" className="flex-1 mb-0">
          <Radio listOption={methods} />
        </FormTeraItem>
        <div className="text-[13px] flex gap-1">
          <span className="text-[#6B7280]">Số dư ví</span>
          <span className="text-green-500 font-[500]">
            {formatCurrency(29000000)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
