import { IRouteProps } from '_common/interface/router';
import UnAuthSSLPage from '.';
import PaymentPage from './pages/Payment';
import PaymentInformationPage from './pages/PaymentInformation';
import { UNAUTH_SSL_URL } from './url';

export const UnAuthSSLRouter: IRouteProps[] = [
  {
    key: UNAUTH_SSL_URL.index.key,
    path: UNAUTH_SSL_URL.index.shortenUrl,
    component: <UnAuthSSLPage />,
  },
  {
    key: UNAUTH_SSL_URL.paymentInformation.key,
    path: UNAUTH_SSL_URL.paymentInformation.shortenUrl,
    component: <PaymentInformationPage />,
  },
  {
    key: UNAUTH_SSL_URL.payment.key,
    path: UNAUTH_SSL_URL.payment.shortenUrl,
    component: <PaymentPage />,
  },
];
