import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import VerifyGoogleIcon from 'styles/images/Icons/VerifyGoogleIcon';
import {
  ArrowPathOutlined,
  Button,
  FormItem,
  Input,
  Modal,
  notification,
} from 'tera-dls';

const Authenticator = (props) => {
  const { open, onCancel, onSubmit, value } = props;
  const confirm = useConfirm();
  const [step, setStep] = useState<number>(1);

  const handleCancel = () => {
    confirm.warning({
      title: 'Thoát ',
      content: (
        <>
          <p>Bạn có chắc muốn thoát thiết lập này không?</p>
          <p>Sau khi thoát dữ liệu sẽ không được lưu cấu hình.</p>
        </>
      ),
      onOk: () => {
        onCancel();
      },
    });
  };
  return !value ? (
    <Modal
      open={open}
      title={'Google Authenticator'}
      closeIcon={false}
      okText="Tiếp tục"
      cancelText="Hủy"
      className="!w-[90%] md:!w-[70%]"
      maskClosable={false}
      onCancel={handleCancel}
      footer={
        <div className="flex gap-2.5">
          <Button type="light" className="px-5" onClick={handleCancel}>
            Hủy
          </Button>
          {step === 1 ? (
            <Button onClick={() => setStep(2)}>Tiếp tục</Button>
          ) : (
            <Button
              onClick={() => {
                onSubmit(true);
                onCancel();
              }}
            >
              Đồng ý
            </Button>
          )}
        </div>
      }
    >
      {step === 1 ? (
        <div className="flex flex-col border-[1px] rounded-[5px] divide-x-[1px] shadow-lg">
          <p className="bg-gray-50 py-2.5 px-[16px] border-b">
            Google Authenticator
          </p>
          <div className="py-2.5 flex gap-[16px] px-[16px] text-gray-700 pb-5">
            <div className="flex flex-col gap-2.5">
              <img
                src="https://s3-alpha-sig.figma.com/img/3ea8/5b69/f5d179192be4e9435b42cde724699b08?Expires=1724630400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Q67o1ybp2Y5CKqJmy1G82VofbDZYGx6pa9FDyGS7z4zhXuHYjkH5SqOp2cUKaBHI7U1kVoBVJLGmcF3RH2YRz56~VCGBY0FIW6cgVzVJanxoasJhDg86zX2RFcYuseT5p2TF~YTCfwRc2dPFwAM0TM2viS-IqwDZvynK5tOHrpHzbH03vHc33P6D5CKqhEPkxfDdfYaFhk-HSfqdobRAHCKvUoLQuOUiXhxlQpHBIvp40hdrssVb6tUKyckyl9AcfiHg359v3oL-ewjS8rhps9RmbxGUqDnxcwkxRXBj2jqyEWbP5bsq1KIKs2uDbP~omX57ZyJc9rK3p~KNGeTAFg__"
                className="w-[200px] shrink-0"
              />
              <div className="bg-gray-50 p-2.5 flex justify-center items-center rounded border border-gray-200">
                6E62DJGAAX3CCVX7
              </div>
              <div className="text-blue-500 flex gap-1 justify-center cursor-pointer">
                <ArrowPathOutlined className="size-4" />
                <span> Tạo mới</span>
              </div>
            </div>
            <div className="flex flex-col gap-[15px]">
              <p>
                Hệ thống của chúng tôi sử dụng mật khẩu một lần dựa trên
                time-based (TOTP) làm công nghệ cơ bản để xác thực hai bước của
                chúng tôi. Vui lòng nhập mã hoặc quét mã QR bằng một trong các
                ứng dụng được liệt kê bên dưới để bật thêm một lớp bảo mật cho
                tài khoản của bạn.
              </p>
              <p>Vui lòng sử dụng một trong các ứng dụng sau:</p>
              <ul className="list-decimal	pl-10">
                <li className="p-2.5 border-b ">
                  Cloud Hosting: Host NVMe 1 GB
                </li>
                <li className="p-2.5 border-b">Authy (Android/iPhone)</li>
                <li className="p-2.5 border-b">
                  Google Authenticator (Android/iPhone/BlackBerry)
                </li>
                <li className="p-2.5 border-b">
                  LastPass Authenticator (Android/iPhone/Windows Phone)
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col border-[1px] rounded-[5px] divide-x-[1px]">
          <p className="bg-gray-50 py-2.5 px-[16px] border-b">
            Google Authenticator
          </p>
          <div className="py-2.5 px-[16px] text-gray-700 flex flex-col gap-[16px]">
            <div className="rounded border border-green-500 text-green-500 py-2.5 px-[16px] bg-green-50">
              Để hoàn tất cấu hình xác thực hai yếu tố, vui lòng nhập mã gồm 6
              chữ số do ứng dụng của bạn cung cấp
            </div>
            <FormItem label="Mã bảo mật" isRequired={false}>
              <Input placeholder="Vui lòng nhập" />
            </FormItem>
            <div className="flex justify-end">
              <Button className="font-light text-[13px] bg-blue-500">
                Xác thực
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  ) : (
    <Modal
      open={open}
      title={'Google Authenticator'}
      closeIcon={false}
      okText="Tiếp tục"
      cancelText="Hủy"
      className="!w-[90%] md:!w-[70%]"
      maskClosable={false}
      onCancel={handleCancel}
      footer={
        <div className="flex gap-2.5">
          <Button type="light" className="px-5" onClick={handleCancel}>
            Hủy
          </Button>
          <Button
            onClick={() => {
              onSubmit(false);
              onCancel();
            }}
          >
            Đồng ý
          </Button>
        </div>
      }
    >
      <div className="flex flex-col border-[1px] rounded-[5px] divide-x-[1px]">
        <p className="bg-gray-50 py-2.5 px-[16px] border-b">Xác thực 2 bước</p>
        <div className="py-2.5 px-[16px] text-gray-700 flex flex-col gap-[16px]">
          <div className="rounded border border-green-500 text-green-500 py-2.5 px-[16px] bg-green-50">
            Nhập mật khẩu tài khoản của bạn trước khi tắt xác thực đa yếu tố
          </div>
          <FormItem label="Mật khẩu" isRequired={false}>
            <Input placeholder="Vui lòng nhập" />
          </FormItem>
          <div className="flex justify-end">
            <Button className="font-light text-[13px] bg-blue-500">
              Xác thực
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const Verify = () => {
  const [openVerify, setOpenVerify] = useState<boolean>(false);
  const [verify, setVerify] = useState<boolean>(false);

  return (
    <>
      <div className="flex flex-col gap-[16px]">
        <h2 className="text-xl">Xác thực 2 bước</h2>
        {!verify ? (
          <div className="rounded border border-red-500 py-2.5 px-[16px] bg-red-50 ">
            Xác thực đa yếu tố chưa được cấu hình!
          </div>
        ) : (
          <div className="rounded border border-green-500 text-green-500 py-2.5 px-[16px] bg-green-50">
            Xác thực đa yếu tố được cấu hình thành công.
          </div>
        )}
        <p>Chọn dịch vụ 2FA có sẵn để cấu hình xác thực</p>
        <div className="rounded-[10px] bg-gray-50 shadow p-[16px] flex flex-col gap-[14px] w-[300px] justify-center">
          <div className="flex justify-center">
            <VerifyGoogleIcon />
          </div>
          <div className="flex flex-col items-center gap-[16px]">
            <p>Google Authenticator</p>
            {!verify ? (
              <Button
                className="font-light text-[13px] w-full bg-blue-500"
                onClick={() => setOpenVerify(true)}
              >
                Kích hoạt
              </Button>
            ) : (
              <Button
                className="font-light text-[13px] w-full"
                onClick={() => setOpenVerify(true)}
                type="danger"
              >
                Hủy kích hoạt
              </Button>
            )}
          </div>
        </div>
      </div>
      {openVerify && (
        <Authenticator
          open={openVerify}
          onCancel={() => setOpenVerify(false)}
          value={verify}
          onSubmit={(verify) => {
            if (verify) {
              notification.success({ message: 'Đã xác thực thành công!' });
              setVerify(true);
              return;
            }
            notification.success({ message: 'Đã hủy xác thực thành công!' });
            setVerify(false);
          }}
        />
      )}
    </>
  );
};

export default Verify;
