import HeaderViewListV2 from '_common/component/HeaderViewList/HeaderViewListV2';
import { DATE_FORMAT } from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import moment from 'moment';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  DropdownItem,
  formatCurrency,
  FunnelOutlined,
  getQueryParams,
  Row,
  updateQueryParams,
} from 'tera-dls';
import WithDrawalFilter from './containers/Filter';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { INVOICE_URL } from '../Invoice/url';
import ButtonWallet from '../Recharge/ButtonWallet';
import WithDrawal from './containers/WithDrawal';
import TransactionModal from './containers/TransactionDetail/TransactionModal';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';

const WithdrawalPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openWithDrawal, setOpenWithDrawal] = useState<boolean>(false);
  const {
    transactionStore: {
      clearResultTransaction,
      open_transaction = false,
      transaction_id = null,
    },
  } = useStores();
  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams(values);
  };

  const renderItemActions = (item: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];
    item;
    dropdownItems.push({
      key: 1,
      label: 'Xem',
      onClick: () => {
        const queryParams = updateQueryParams({
          type: 'detail',
        });
        navigate(`${INVOICE_URL.detail.path}/1${queryParams}`);
      },
    });

    return dropdownItems;
  };

  const columns = [
    {
      title: 'Ngày',
      dataIndex: 'date',
      width: 200,
      render: (val) => moment(val).format(DATE_FORMAT),
    },
    {
      title: 'Số tiền rút',
      dataIndex: 'amount',
      width: 200,
      render: (val) => formatCurrency(val ?? 0),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      width: 200,
    },
    {
      dataIndex: 'action',
      title: '',
      width: 50,
      render: (_, record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <div className="tera-page">
        <HeaderViewListV2 title="Rút tiền">
          <div className="p-[16px] flex flex-col gap-2.5">
            <Row className="flex justify-end">
              <span>
                <ButtonWallet
                  title="Rút tiền"
                  typeBtn="withdrawal"
                  onClick={() => setOpenWithDrawal(true)}
                />
              </span>
            </Row>
            <div className="flex justify-end flex-col shadow rounded-md py-2 gap-2">
              <div className="flex flex-col px-2 justify-end items-end gap-2">
                <div className="flex gap-2.5">
                  <Button
                    type="alternative"
                    className="rounded-xsm py-2 px-2  bg-blue-50"
                    onClick={() => setOpenFilter(true)}
                  >
                    <FunnelOutlined className=" text-blue-500 rounded-[4px] shrink-0 size-4" />
                  </Button>
                </div>
              </div>
              <TableTera
                columns={columns}
                className="center-table"
                wrapperClassName="shadow-none"
                pagination={{}}
                data={[
                  {
                    id: 1,
                    date: moment().format(),
                    amount: 50000,
                    description: 'Ghi chú',
                  },
                  {
                    id: 2,
                    date: moment().format(),
                    amount: 50000,
                    description: 'Ghi chú',
                  },
                  {
                    id: 3,
                    date: moment().format(),
                    amount: 50000,
                    description: 'Ghi chú',
                  },
                ]}
              />
            </div>
          </div>
        </HeaderViewListV2>
      </div>
      {openFilter && (
        <WithDrawalFilter
          open={openFilter}
          onClose={() => setOpenFilter(false)}
          initialValues={queryParams}
          onFilter={handleFilter}
        />
      )}
      {openWithDrawal && (
        <WithDrawal
          open={openWithDrawal}
          type={'withdrawal'}
          onClose={() => setOpenWithDrawal(false)}
        />
      )}
      {open_transaction && (
        <TransactionModal
          type="withdrawal"
          id={transaction_id}
          open={open_transaction}
          onClose={() => {
            clearResultTransaction();
          }}
        />
      )}
    </>
  );
};

export default observer(WithdrawalPage);
