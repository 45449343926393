import { useNavigate } from 'react-router-dom';
import { ArrowSmallLeftSolid, Breadcrumb } from 'tera-dls';
import { CLOUD_VPS_URL } from '../../url';
import CurrentVPS from './components/CurrentVPS';
import ReinstallOS from './components/ReinstallOS';

const ReinstallOSVPSPage = (): JSX.Element => {
  const navigate = useNavigate();

  const items = [
    {
      title: (
        <span className="!text-blue-400 hover:!text-blue-600">
          Danh sách Cloud VPS
        </span>
      ),
      onClick: () => navigate(CLOUD_VPS_URL.list.path),
    },
    {
      title: (
        <div className="font-[500] flex gap-1">
          Cài lại hệ điều hành cho dịch vụ
          <span className="text-green-500">ZS6892_011391</span>
        </div>
      ),
    },
  ];

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={items[items.length - 2].onClick}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb separator={'/'} items={items} />
          </div>
        </div>
      </div>
      <div className="px-2 flex items-start gap-4">
        <CurrentVPS />
        <ReinstallOS />
      </div>
    </div>
  );
};

export default ReinstallOSVPSPage;
