import CardDetail from '_common/component/CardDetail';
import { useState } from 'react';
import SecureIcon from 'styles/images/Icons/SecureIcon';
import { Button, Input, TrashSolid } from 'tera-dls';

const Secure = () => {
  const [data, setData] = useState<any>([]);
  const [input, setInput] = useState<any>();
  return (
    <CardDetail>
      <div className="flex flex-col gap-[30px]">
        <div className="flex gap-[16px] items-center">
          <SecureIcon className="size-[40px]" />
          <div className="flex flex-col gap-[5px]">
            <p className="text-[12px] text-gray-500">PP của bạn hiện tại:</p>
            <p className="font-medium">125.235.238.106</p>
          </div>
        </div>
        <CardDetail className="!shadow-none" title="Bảo mật">
          <div className="flex flex-col gap-2.5">
            <div className="flex gap-2.5">
              <Input
                placeholder="eg. 125.235.238.106"
                className="rounded-[5px]"
                value={input}
                onChange={(val) => setInput(val?.target.value)}
              />
              <Button
                className="font-light bg-blue-500 shrink-0"
                onClick={() => {
                  if (input.trim() === '') return;
                  setData((prev) => [input, ...prev]);
                  setInput('');
                }}
              >
                Thêm IP
              </Button>
            </div>
            <div className="flex flex-col border-[1px] rounded-[5px] divide-x-[1px]">
              <p className="bg-gray-50 py-2.5 px-[16px] border-b">
                IP được phép truy cập
              </p>
              <div className="py-2.5 px-[16px] ">
                {data?.length > 0 ? (
                  data?.map((item, index) => (
                    <div className="flex justify-between py-2 border-b">
                      <span>{item}</span>{' '}
                      <TrashSolid
                        className="size-4 cursor-pointer text-red-500"
                        onClick={() =>
                          setData((prev) => prev.filter((_, i) => i !== index))
                        }
                      />
                    </div>
                  ))
                ) : (
                  <p>
                    Không có rule nào được cấu hình - Hiện tại đang cho phép
                    truy cập từ tất cả các IP. Lưu ý tính năng này chỉ áp dụng
                    nếu mạng internet tại nhà hoặc công ty của bạn sử dụng IP
                    tĩnh, nghĩa là IP public của mạng internet bạn chỉ sử dụng 1
                    hoặc vài IP biết trước.
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col border-[1px] rounded-[5px] divide-x-[1px]">
              <p className="bg-gray-50 py-2.5 px-[16px] border-b">
                Quy định cấu hình
              </p>
              <div className="py-2.5 px-[16px] text-gray-700">
                <p>all - keyword matching all IPs</p>
                <p>xxx.xxx.xxx.xxx - IP đơn</p>
                <p>xxx.xxx.xxx.xxx/M - IP with Mask in CIDR format</p>
                <p>
                  xxx.xxx.xxx.xxx/mmm.mmm.mmm.mmm - IP with Mask in dotted quad
                  format
                </p>
                <p className="my-2">Example rules</p>
                <p>
                  120.123.123.57/28 matches IP from 120.123.123.48 to
                  120.123.123.63
                </p>
                <p>
                  120.123.123.57/16 matches IP from 120.123.0.0 to
                  120.123.255.255
                </p>
              </div>
            </div>
          </div>
        </CardDetail>
      </div>
    </CardDetail>
  );
};

export default Secure;
