import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioProps, Radio as RadioTera } from 'tera-dls';

interface IProps extends RadioProps {
  listOption: any[];
}

const Radio = React.memo(({ listOption, ...props }: IProps) => {
  const { form } = useTeraForm();
  const { item } = useTeraFormItem();
  const { control } = form;

  return (
    <Controller
      control={control}
      {...item}
      render={({ field }) => {
        return (
          <RadioTera.Group {...props} {...field}>
            {listOption?.map((option) => (
              <RadioTera key={option?.value} value={option?.value}>
                {option?.label}
              </RadioTera>
            ))}
          </RadioTera.Group>
        );
      }}
    />
  );
});

export default Radio;
