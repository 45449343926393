import areaList from '_common/mockData/areaList.json';
import vpsList from '_common/mockData/vpsList.json';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FlagVN from 'styles/images/Icons/FlagVN.svg';
import { Modal } from 'tera-dls';
import { UNAUTH_CLOUD_VPS_URL } from '../url';

interface IModalDomainProcessesDetailProps {
  open: boolean;
  close: () => void;
}

const ModalAddCloudVPS = ({
  open,
  close,
}: IModalDomainProcessesDetailProps): JSX.Element => {
  const [activeArea, setActiveArea] = useState<number>(1);
  const [vpsSelected, setVPSSelected] = useState<number>(1);

  const navigate = useNavigate();

  return (
    <Modal
      title="Tạo dự án AI mới"
      open={open}
      okText="Đồng ý"
      cancelText="Hủy"
      onOk={() => navigate(UNAUTH_CLOUD_VPS_URL.add.path)}
      onCancel={close}
      centered={true}
      className="sm:w-[90%] xl:w-[80%] h-[90%]"
      contentClassName="h-full"
      bodyClassName="!p-0 flex-1"
    >
      <div className="h-full flex">
        <div className="w-[330px] bg-[#F3F4F6] flex flex-col cursor-pointer">
          {areaList.map((area) => (
            <div
              key={area.id}
              className={`hover:bg-white flex items-center ${
                activeArea === area.id
                  ? 'bg-white before:content-[""] before:w-[4px] before:h-full before:bg-[#3F83F8]'
                  : 'bg-[#F3F4F6]'
              }`}
              onClick={() => setActiveArea(area.id)}
            >
              <div className="p-4 flex flex-col">
                <span className="font-bold">{area.name}</span>
                <span className="pl-2">{area.description}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="p-4 flex-1">
          <div className="text-[16px] font-bold pb-4 border-b-[1px] flex gap-1">
            <span>KHỞI TẠO KHU VỰC</span>
            <span className="text-[#0e9f6e]">
              {areaList[activeArea - 1]?.shortName}
            </span>
          </div>
          <div className="grid grid-cols-4 gap-4 py-2">
            {vpsList
              .filter((vps) => vps.area_id === activeArea)
              .map((vps) => (
                <div
                  key={vps.id}
                  className={`p-1 flex flex-col gap-1 cursor-pointer ${
                    vpsSelected === vps.id && 'bg-blue-100'
                  }`}
                  onClick={() => setVPSSelected(vps.id)}
                >
                  <div className="flex items-center gap-1">
                    <img src={FlagVN} />
                    <span className="text-[16px] text-blue-600">
                      {vps.name}
                    </span>
                  </div>
                  <span className="text-gray-500">{vps.description}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAddCloudVPS;
