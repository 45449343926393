import TableTera from '_common/dof/TableTera';
import { ColumnsType, formatCurrency, Modal, Tag, TrashSolid } from 'tera-dls';

interface IModalDomainProcessesDetailProps {
  open: boolean;
  close: () => void;
}

const ModalSummary = ({
  open,
  close,
}: IModalDomainProcessesDetailProps): JSX.Element => {
  const columns: ColumnsType<any> = [
    {
      title: 'Tên miền',
      dataIndex: 'domain',
      width: 350,
      render: (domain) => (
        <div className="flex items-center gap-2">
          <Tag color="green03" className="font-[500] overflow-visible">
            Đăng ký mới
          </Tag>
          <span>{domain}</span>
        </div>
      ),
    },
    {
      title: 'Phí cài đặt',
      dataIndex: 'installation_fee',
      render: (installation_fee) => formatCurrency(installation_fee),
    },
    {
      title: 'Giá',
      dataIndex: 'price',
      render: (price, record) => {
        return record.discount > 0 ? (
          <div className="flex gap-3">
            <span>{formatCurrency(record.discount)}</span>
            <del className="text-[#6B7280]">{formatCurrency(price)}</del>
          </div>
        ) : (
          <span>{formatCurrency(price)}</span>
        );
      },
    },
    {
      width: 40,
      fixed: 'right',
      render: () => (
        <TrashSolid
          color="#e05252"
          className="w-[20px] h-[20px] cursor-pointer"
        />
      ),
    },
  ];

  return (
    <Modal
      title="Chi tiết tên miền đã chọn"
      okText="Đóng"
      open={open}
      onOk={close}
      centered={true}
      cancelButtonProps={{ className: 'hidden' }}
      className="sm:w-[90%] xl:w-[1000px]"
    >
      <TableTera
        className="[&_.tera-table-cell]:!align-middle"
        columns={columns}
        data={[
          {
            id: 1,
            domain: 'tenmien.io.vn',
            installation_fee: 0,
            price: 650000,
            discount: 350000,
          },
        ]}
        summary={() => (
          <>
            <tr className="tera-table-row">
              <td className="tera-table-cell" colSpan={2}>
                Thanh toán theo định kỳ
              </td>
              <td className="tera-table-cell flex flex-col">
                <span>{formatCurrency(480000)} / 01 năm</span>
              </td>
            </tr>
            <tr className="tera-table-row">
              <td className="tera-table-cell" colSpan={2}>
                Thanh toán hôm nay
              </td>
              <td className="tera-table-cell">
                <span className="text-[20px] text-[#0E9F6E]">
                  {formatCurrency(480000)}
                </span>
              </td>
            </tr>
          </>
        )}
      />
    </Modal>
  );
};

export default ModalSummary;
