import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface IProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValues?: any;
}

const RechargeFilter = (props: IProps) => {
  const { open, onClose, onFilter, initialValues } = props;

  const form = useForm();

  useEffect(() => {
    initialValues?.time &&
      form.setValue('time', moment(initialValues?.time, DATE_BACKEND_FORMAT));
  }, [initialValues]);

  const handleSubmitForm = (value): void => {
    onFilter({
      ...(value?.time && { time: value?.time?.format(DATE_BACKEND_FORMAT) }),
    });
    onClose();
  };

  const handleReset = (): void => {
    form.reset();
    onFilter({
      time: null,
    });
    onClose();
  };

  return (
    <>
      <Filter
        open={open}
        onClose={onClose}
        onCancel={onClose}
        onFilter={() => form.handleSubmit(handleSubmitForm)()}
      >
        <FormTera form={form}>
          <FormTeraItem label="Thời gian nạp" name="time">
            <DatePicker format={DATE_FORMAT} />
          </FormTeraItem>
          <a
            className="text-red-500 text-sm font-normal cursor-pointer"
            onClick={() => handleReset()}
          >
            Hủy bộ lọc
          </a>
        </FormTera>
      </Filter>
    </>
  );
};

export default RechargeFilter;
