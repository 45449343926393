import FormTera from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import FormAdd from './components/FormAdd';
import FormSummary from './components/FormSummary';

const UnAuthAddCloudVPSPage = (): JSX.Element => {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      service_package: 1,
      cpu: 1,
      ram: 1,
      storage: 20,
      os: 1,
      billing_cycle: 1,
      direct_admin_plus: {
        period: 1,
        license_name: 1,
      },
      direct_admin_lite: {
        period: 1,
      },
    },
  });

  return (
    <div className="tera-page-form min-h-[calc(100vh_-_45px)]">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb text-[16px] font-[600]">
            CLOUD VPS
          </div>
        </div>
      </div>
      <FormTera form={form} className="flex-1 flex flex-col">
        <FormAdd form={form} />
        <FormSummary />
      </FormTera>
    </div>
  );
};

export default UnAuthAddCloudVPSPage;
