import CopyClipboardText from '_common/component/CopyClipboardText';
import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';

const LoginInformation = (): JSX.Element => {
  return (
    <div className="w-full flex flex-col gap-4">
      <IconText prefix={<ZingIcon.IconPersonShield />}>
        <span className="font-[500]">Credentials:</span>
      </IconText>
      <div className="pb-4 pt-2 border-b-[1px] flex">
        <IconText
          prefix={<ZingIcon.IconLocationSolid color="#3f83f8" />}
          className="w-[50%]"
        >
          <span className="text-blue-500">IP Port:</span>
          <CopyClipboardText valueCopy="103.250.78.65:22">
            <u className="text-black font-[500]">103.250.78.65:22</u>
          </CopyClipboardText>
        </IconText>
        <IconText prefix={<ZingIcon.IconShield />} className="w-[50%]">
          <span className="text-blue-500">Username:</span>
          <CopyClipboardText valueCopy="root">
            <u className="text-black font-[500]">root</u>
          </CopyClipboardText>
        </IconText>
      </div>
      <div className="pb-4 pt-2 border-b-[1px] flex">
        <IconText prefix={<ZingIcon.IconKey />} className="w-[50%]">
          <span className="text-blue-500">Default password:</span>
          <CopyClipboardText valueCopy="S!D4@!8ZU4pht">
            <u className="text-black font-[500]">S!D4@!8ZU4pht</u>
          </CopyClipboardText>
        </IconText>
      </div>
    </div>
  );
};

export default LoginInformation;
