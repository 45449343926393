export const UNAUTH_EMAIL_SECURITY_URL = {
  index: {
    key: '',
    path: '/welcome/service/email-security',
    shortenUrl: 'email-security',
  },
  paymentInformation: {
    key: '',
    path: '/welcome/service/email-security/payment-information',
    shortenUrl: 'email-security/payment-information',
  },
  payment: {
    key: '',
    path: '/welcome/service/email-security/payment',
    shortenUrl: 'email-security/payment',
  },
};
