import React, { useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import customTwMerge from 'tailwind-merge.config';
import { Tooltip } from 'tera-dls';

type CopyClipboardTextProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  valueCopy: string;
};
const CopyClipboardText = ({
  valueCopy,
  children,
  className,
  ...restProps
}: CopyClipboardTextProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [, copy] = useCopyToClipboard();

  return (
    <Tooltip
      title={isCopied ? 'Đã copy' : 'Click để copy'}
      onOpenChange={() => setIsCopied(false)}
    >
      <span
        onClick={(e) => {
          e.stopPropagation();
          copy(valueCopy);
          setIsCopied(true);
        }}
        className={customTwMerge('cursor-pointer', className)}
        {...restProps}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export default CopyClipboardText;
