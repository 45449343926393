import { makeAutoObservable } from 'mobx';
import { TTransaction } from './interface';

export class TransactionStore {
  type: TTransaction = null;
  transfer_type = null;
  step = 0;
  account = null;
  amount = 0;
  invoice = null;
  method = 1;
  otp = '';
  sessionId = null;
  open_transaction = false;
  transaction_id = null;

  constructor() {
    makeAutoObservable(this);
  }

  setStep = (value: number) => {
    this.step = value;
  };

  nextStep = () => {
    this.step = this.step + 1;
  };

  backStep = () => {
    this.step = this.step - 1;
  };

  setAccount = (value) => {
    this.account = value;
  };

  setTransferType = (value) => {
    this.account = value;
  };

  setSessionId = (sessionId) => {
    this.sessionId = sessionId;
  };

  setAmount = (value: number) => {
    this.amount = value;
  };

  setInvoice = (value) => {
    this.invoice = value;
  };

  setMethod = (value) => {
    this.method = value;
  };

  setType = (value: TTransaction) => {
    this.type = value;
  };

  setOtp = (value: string) => {
    this.otp = value;
  };

  setOpenTransaction = (value: boolean, id: string | number) => {
    this.open_transaction = value;
    this.transaction_id = id;
  };

  clearTransaction = () => {
    this.type = null;
    this.transfer_type = null;
    this.step = 0;
    this.account = null;
    this.amount = 0;
    this.invoice = null;
    this.method = 1;
    this.otp = '';
    this.sessionId = null;
  };

  clearResultTransaction = () => {
    this.transaction_id = null;
    this.open_transaction = false;
  };
}
