import { Row } from 'tera-dls';

export default ({
  title,
  leftSide,
}: {
  title: string;
  leftSide?: JSX.Element;
}): JSX.Element => (
  <Row className="w-full bg-[#f3f3f9] flex justify-between items-center sticky top-[45px] z-[49]">
    <h1 className="text-[16px] font-bold text-left p-3">{title}</h1>
    {leftSide && leftSide}
  </Row>
);
