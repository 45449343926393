import TextArea from '_common/dof/Control/TextArea';
import { FormTeraItem } from '_common/dof/FormTera';

const Note = (): JSX.Element => {
  return (
    <div className="pt-8 flex flex-col gap-4">
      <span className="text-blue-500">Ghi chú / Thông tin thêm</span>
      <FormTeraItem name="note" className="mb-0">
        <TextArea
          placeholder="Tại đây bạn có thể thêm thông tin ghi chú, các yêu cầu nào khác cần thực hiện cho gói dịch vụ của bạn. Ví dụ cài thêm phần mềm cho VPS/Server hay yêu cầu cấu hình PHP cho hosting, xuất hoá đơn VAT,..."
          className="h-[200px]"
        />
      </FormTeraItem>
    </div>
  );
};

export default Note;
