import IconText from '_common/component/IconText';
import ZingIcon from '_common/component/ZingIcon';
import { useState } from 'react';
import FlagVN from 'styles/images/Icons/FlagVN.svg';
import { Button, formatCurrency } from 'tera-dls';

const ServiceToUpgrade = (): JSX.Element => {
  const [error, setError] = useState<string>(null);

  return (
    <div className="flex-1 bg-white p-4 rounded-[10px] drop-shadow flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <span className="text-blue-500 font-bold">Dịch vụ muốn nâng cấp</span>
        <div className="px-3 flex flex-col gap-5">
          <IconText prefix={<ZingIcon.IconInformation color="#0e9f6e" />}>
            <span className="font-[500]">Thông tin chung:</span>
          </IconText>
          <div className="pb-4 border-b-[1px] flex">
            <IconText prefix={<ZingIcon.IconLocation />} className="w-[50%]">
              Vị trí:
              <span className="font-[500]">103.250.78.65</span>
            </IconText>
            <IconText prefix={<ZingIcon.IconSquareBox />} className="w-[50%]">
              Tên:
              <span className="font-[500]">103.250.78.65</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconEarth />}
              suffix={
                <img
                  src={FlagVN}
                  alt="name_flag"
                  className="w-4 h-4 rounded-[1rem] object-cover"
                />
              }
              className="w-[50%]"
            >
              Khu vực:
              <span className="font-[500]">Việt Nam</span>
            </IconText>
            <IconText prefix={<ZingIcon.IconWindows />} className="w-[50%]">
              Hệ điều hành:
              <span className="font-[500]">Windows Server 2012</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconCalendarCheck />}
              className="w-[50%]"
            >
              Ngày tạo:
              <span className="font-[500]">dd/mm/yyyy</span>
            </IconText>
            <IconText
              prefix={<ZingIcon.IconCalendarEmpty />}
              className="w-[50%]"
            >
              Ngày hết hạn:
              <span className="font-[500]">dd/mm/yyyy</span>
            </IconText>
          </div>
        </div>
        <div className="px-3 flex flex-col gap-5">
          <IconText prefix={<ZingIcon.IconVPS color="#0E9F6E" />}>
            <span className="font-[500]">Cấu hình:</span>
          </IconText>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconRam color="#3f83f8" />}
              className="w-[50%]"
            >
              RAM:
              <span className="font-[500]">16</span>
              GB
            </IconText>
            <IconText
              prefix={<ZingIcon.IconCPU color="#3f83f8" />}
              className="w-[50%]"
            >
              vCPU:
              <span className="font-[500]">8</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconCPUStorage color="#3f83f8" />}
              className="w-[50%]"
            >
              SSD:
              <span className="font-[500]">90</span>
              GB
            </IconText>
            <IconText
              prefix={<ZingIcon.IconNetwork color="#3f83f8" />}
              className="w-[50%]"
            >
              Mbps:
              <span className="font-[500]">800</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconWifiVertical color="#3f83f8" />}
              className="w-[50%]"
            >
              Wifi:
              <span className="font-[500]">Unlimited</span>
            </IconText>
            <IconText
              prefix={<ZingIcon.IconDollarCard color="#3f83f8" />}
              className="w-[50%]"
            >
              Đơn giá:
              <span className="text-[#e02424] font-[500]">
                {formatCurrency(1855000)}
              </span>
              / tháng
            </IconText>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <span className="text-blue-500 font-bold">Chi phí nâng cấp</span>
        <div className="px-3 flex flex-col gap-5">
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconDollar color="#3f83f8" />}
              className="w-[50%]"
            >
              Chênh lệch dịch vụ:
              <span className="font-[500]">{formatCurrency(309166)}</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconDollar color="#3f83f8" />}
              className="w-[50%]"
            >
              Khấu trừ dịch vụ cũ:
              <span className="font-[500]">{formatCurrency(179166)}</span>
            </IconText>
          </div>
          <div className="pb-4 border-b-[1px] flex">
            <IconText
              prefix={<ZingIcon.IconDollar color="#3f83f8" />}
              className="w-[50%]"
            >
              Số tiền cần thanh toán:
              <span className="font-[500]">{formatCurrency(130000)}</span>
            </IconText>
          </div>
          {error && <span className="text-red-500">{error}</span>}
          <Button
            className="w-[120px] bg-green-500 p-2"
            prefix={<ZingIcon.IconSettings />}
            onClick={() =>
              setError('Số dư của Quý khách không đủ để thực hiện giao dịch')
            }
          >
            Thanh toán
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ServiceToUpgrade;
