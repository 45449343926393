export const CLOUD_VPS_URL = {
  list: {
    key: '',
    path: '/portal/service/cloud-vps/list',
    shortenUrl: 'cloud-vps/list',
  },
  add: {
    key: '',
    path: '/portal/service/cloud-vps/add',
    shortenUrl: 'cloud-vps/add',
  },
  paymentInformation: {
    key: '',
    path: '/portal/service/cloud-vps/payment-information',
    shortenUrl: 'cloud-vps/payment-information',
  },
  payment: {
    key: '',
    path: '/portal/service/cloud-vps/payment',
    shortenUrl: 'cloud-vps/payment',
  },
  detail: {
    key: '',
    path: '/portal/service/cloud-vps/detail',
    shortenUrl: 'cloud-vps/detail/:id',
  },
  upgrade: {
    key: '',
    path: '/portal/service/cloud-vps/upgrade',
    shortenUrl: 'cloud-vps/upgrade/:id',
  },
  upgradePayment: {
    key: '',
    path: '/portal/service/cloud-vps/upgrade-payment',
    shortenUrl: 'cloud-vps/upgrade-payment/:id',
  },
  reinstallOS: {
    key: '',
    path: '/portal/service/cloud-vps/reinstall-os',
    shortenUrl: 'cloud-vps/reinstall-os/:id',
  },
};
