import IconProxy1 from 'styles/images/Icons/IconProxy1.svg';
import IconProxy2 from 'styles/images/Icons/IconProxy2.svg';
import IconProxy3 from 'styles/images/Icons/IconProxy3.svg';
import IconProxy4 from 'styles/images/Icons/IconProxy4.svg';
import IconProxy5 from 'styles/images/Icons/IconProxy5.svg';
import IconProxy6 from 'styles/images/Icons/IconProxy6.svg';
import { Button, ChevronDoubleRightSolid, formatCurrency } from 'tera-dls';

const proxyList = [
  {
    id: 1,
    icon: IconProxy1,
    name: 'Proxy Datacenter IPv4 Private',
    description:
      'Proxy IPv4 dùng riêng tại Việt Nam, Mỹ, Canada, Châu Âu, Châu Á với hơn 190 Quốc gia khác',
    price: 60000,
  },
  {
    id: 2,
    icon: IconProxy2,
    name: 'Proxy Dân cư IPv4 xoay IP',
    description:
      'Proxy dân cư xoay IPv4 tại hơn 190 Quốc gia và vùng lãnh thổ khác nhau, địa chỉ IP cư dân sạch, liên tục bổ sung mới',
    price: 60000,
  },
  {
    id: 3,
    icon: IconProxy3,
    name: 'Proxy Dân cư IPv4 tĩnh',
    description:
      'Proxy dân cư IPv4 tĩnh tại các quốc gia có nền thương mại điện tử phát triển nhất, IP cư dân sử dụng có độ tin cậy cao',
    price: 60000,
  },
  {
    id: 4,
    icon: IconProxy4,
    name: 'Proxy Dân cư Việt Nam',
    description:
      'Proxy dân cư Việt Nam (Backconnect Proxy), giữ IP cố định theo yêu cầu, đổi IP khi cần, không giới hạn băng thông, tốc độ vượt trội, IPv4 tại hơn 30 tỉnh thành phố',
    price: 60000,
  },
  {
    id: 5,
    icon: IconProxy5,
    name: 'Proxy IPv6 Datacenter',
    description:
      'Proxy IPv6 đa dạng, sử dụng cho các trang web hiện đại, có hỗ trợ IPv6 như Facebook, Youtube, Yandex,...',
    price: 27000,
  },
  {
    id: 6,
    icon: IconProxy6,
    name: 'Proxy Gaming',
    description:
      'Proxy IPv4 dùng riêng tại Việt Nam, Mỹ, Canada, Châu Âu, Châu Á với hơn 190 Quốc gia khác',
    price: 60000,
  },
];

const HighQualityProxy = (): JSX.Element => {
  return (
    <div className="mt-[2rem] flex flex-col">
      <span className="font-bold">DỊCH VỤ PROXY CHẤT LƯỢNG CAO</span>
      <div className="h-[364px] mt-[2rem] flex flex-col gap-3 overflow-y-scroll">
        {proxyList.map((proxy) => (
          <div
            key={proxy.id}
            className="bg-white p-4 pr-[8rem] rounded-[4px] drop-shadow flex justify-between items-center"
          >
            <img src={proxy.icon} className="w-[80px] h-[72px] object-cover" />
            <div className="w-[568px] flex flex-col items-start">
              <span className="text-[20px]">{proxy.name}</span>
              <span className="mt-[1rem]">{proxy.description}</span>
              <Button className="text-[#3F83F8] bg-transparent hover:bg-transparent p-0 mt-2 flex items-center">
                Xem chi tiết
                <ChevronDoubleRightSolid className="w-4 h-4" />
              </Button>
            </div>
            <div className="flex flex-col gap-2">
              <span className="text-[24px] text-[#E05252]">
                {formatCurrency(proxy.price)}
              </span>
              <span>VNĐ/Tháng</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HighQualityProxy;
