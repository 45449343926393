import { useMutation, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { useStores } from '_common/hooks';
import { observer } from 'mobx-react-lite';
import { forwardRef, useImperativeHandle } from 'react';
import { formatCurrency, notification, Spin } from 'tera-dls';
import WalletApi from '../../api';

function Transfer(prop, ref) {
  const {
    transactionStore: {
      account,
      amount,
      type,
      setInvoice,
      sessionId,
      setOpenTransaction,
    },
  } = useStores();
  const queryClient = useQueryClient();

  const rechargeWithdrawal = useMutation(
    (params: any) => {
      return WalletApi.withdrawal({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          setInvoice(res?.data);
          queryClient.invalidateQueries(['get-transaction-list']);
          queryClient.invalidateQueries(['get-balance']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => {
        setOpenTransaction(true, 1);
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleNextStep = () => {
    if (type === 'recharge') return;
    const params = {
      card_id: account?.id,
      amount: amount,
      transaction_type: account?.object_type,
      methods: account?.object_type,
      session_id: sessionId,
    };
    rechargeWithdrawal.mutate(params);
  };

  useImperativeHandle(ref, () => {
    return { nextStep: handleNextStep };
  });

  return (
    <Spin spinning={rechargeWithdrawal.isLoading}>
      <div className="flex flex-col gap-y-4">
        <div className="flex justify-between items-center">
          <span className="text-gray-500">Loại giao dịch:</span>
          <span className="text-gray-800">Rút tiền</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-500">Phương thức thanh toán:</span>
          <span className="text-gray-800">Chuyển khoản</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-500">Số tiền:</span>
          <span className="text-gray-800">{formatCurrency(amount)}</span>
        </div>
        <p className="text-center text-blue-700">
          Vui lòng xác nhận thông tin tài khoản nhận tiền
        </p>
        <div className="p-4 border rounded-[10px] grid grid-cols-12 gap-x-6">
          <div className="col-span-5 flex flex-col items-end gap-y-2.5 text-gray-500">
            <span>Số tài khoản</span>
            <span>Người thụ hưởng</span>
            <span>Ngân hàng</span>
            <span>Số tiền</span>
            <span>Nội dung</span>
          </div>
          <div className="col-span-7 flex flex-col items-start gap-y-2.5 text-gray-700">
            <span>{account?.number || '__'}</span>
            <span>{account?.cardholder || '__'}</span>
            <span>{account?.cardName || '__'}</span>
            <span className="font-bold text-blue-500">
              {formatCurrency(amount) || '__'}
            </span>
            <span>
              {type === 'recharge'
                ? 'Chuyển khoản cho Tera Solutions'
                : `Chuyển khoản cho ${account?.cardholder}`}
            </span>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default observer(forwardRef(Transfer));
